import { useState, forwardRef, memo } from "react";
import { Check, ChevronRight, Clear, Search } from "@certa/icons";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { classNames } from "../../utils/common";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "../Typography";

import styles from "./Dropdown.module.css";
import { CATALYST_Z_INDEXES } from "../../constants/styles";
import { DimmerVariants, Dimmer } from "../Dimmer";

export enum DropdownMenuItemTypes {
  DEFAULT = "Default",
  DESTRUCTIVE = "Destructive",
  BUTTON = "Button"
}

export enum DropdownMenuItemSizes {
  DEFAULT = "Default",
  BIG = "Big"
}

type DropdownDefaultProps = {
  children: React.ReactElement | React.ReactElement[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

type DropdownProps = DropdownDefaultProps;

const DropdownMenu = (props: DropdownProps) => {
  const { children, open: shouldOpen, onOpenChange } = props;
  const [isOpen, setIsOpen] = useState(shouldOpen);

  const handleOpenChange = (open: boolean) => {
    /* Needed dimmer, which prevents the UI from flickering when the scrollbar appears or disappears due to toggling the dropdown opening state.    */
    onOpenChange?.(open);
    setIsOpen(open);
  };

  return (
    <DropdownMenuPrimitive.Root
      open={shouldOpen}
      onOpenChange={handleOpenChange}
    >
      {children}
      {isOpen && (
        <Dimmer
          scrollLock
          variant={DimmerVariants.TRANSPARENT}
          onClick={() => handleOpenChange(false)}
        />
      )}
    </DropdownMenuPrimitive.Root>
  );
};

type InlineSearchProps = {
  size?: DropdownMenuItemSizes;
  onSearch: (searchText: string) => void;
  onClearSearch: () => void;
  placeholder?: string;
};

const InlineSearch = forwardRef<HTMLInputElement, InlineSearchProps>(
  (props, ref) => {
    const {
      size = DropdownMenuItemSizes.DEFAULT,
      onSearch,
      onClearSearch,
      placeholder = "Search...",
      ...restProps
    } = props;

    const [searchText, setSearchText] = useState("");

    const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(evt.target.value);
      onSearch(evt.target.value);
    };

    const handleClearSearch = () => {
      setSearchText("");
      onClearSearch();
    };

    const keyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key !== " " && evt.key !== "Escape") {
        evt.stopPropagation();
      }
      if (evt.key === "ArrowDown") {
        (restProps as any).onKeyDown?.(evt);
      }
    };

    const keyUpHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key !== " " && evt.key !== "Escape") {
        evt.stopPropagation();
      }
      if (evt.key === "ArrowDown") {
        (restProps as any).onKeyUp?.(evt);
      }
    };

    return (
      <div className={styles.catalystDropdownMenuInlineSearchWrapper}>
        <div
          className={classNames({
            [styles.catalystDropdownMenuInlineSearch]: true,
            [styles["catalystDropdownMenuInlineSearch" + size]]: true
          })}
        >
          <div
            className={classNames({
              [styles.catalystDropdownMenuInlineSearchIcon]: true,
              [styles.catalystDropdownMenuInlineSearchIconActive]:
                searchText.length > 0
            })}
          >
            <Search size={12} />
          </div>
          <input
            {...restProps}
            type="text"
            ref={ref}
            placeholder={placeholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: "100%" }}
            onKeyDown={keyDownHandler}
            onKeyUp={keyUpHandler}
            autoFocus
          />
          {searchText.length > 0 && (
            <div className={styles.catalystDropdownMenuInlineSearchClearIcon}>
              <Clear size={12} onClick={handleClearSearch} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

type DropdownTriggerProps = {
  children: React.ReactElement;
  disabled?: boolean;
};

const DropdownMenuTrigger = (props: DropdownTriggerProps) => {
  const { children, disabled: isDisabled } = props;

  return (
    <DropdownMenuPrimitive.Trigger asChild disabled={isDisabled}>
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
};

type DropdownSearchProps =
  | {
      showInlineSearch: boolean;
      onInlineSearch: (searchText: string) => void;
      onClearInlineSearch: () => void;
      searchPlaceholder?: string;
    }
  | {
      showInlineSearch?: undefined;
      onInlineSearch?: undefined;
      onClearInlineSearch?: undefined;
      searchPlaceholder?: string;
    };

export type DropdownContentProps = {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
  height?: string;
  align?: "start" | "end" | "center";
  matchTriggerWidth?: boolean;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  alignOffset?: number;
} & DropdownSearchProps;

const DropdownMenuContent = (props: DropdownContentProps) => {
  const {
    children,
    width,
    height,
    showInlineSearch: shouldShowInlineSearch = false,
    onInlineSearch,
    onClearInlineSearch,
    align = "start",
    matchTriggerWidth: shouldMatchTriggerWidth = false,
    side = "bottom",
    sideOffset = 4,
    alignOffset
  } = props;

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        side={side}
        sideOffset={sideOffset}
        alignOffset={alignOffset}
        // loop
        align={align}
        className={styles.catalystDropdownMenu}
        style={{
          width: shouldMatchTriggerWidth
            ? "var(--radix-dropdown-menu-trigger-width)"
            : width,
          maxHeight: height,
          zIndex: CATALYST_Z_INDEXES.DROPDOWN
        }}
      >
        {shouldShowInlineSearch && onInlineSearch && (
          <>
            <DropdownMenuPrimitive.Item
              asChild
              onSelect={evt => {
                evt.preventDefault();
              }}
              onPointerMove={evt => {
                evt.preventDefault();
              }}
              onPointerLeave={evt => {
                evt.preventDefault();
              }}
            >
              <InlineSearch
                onSearch={onInlineSearch}
                onClearSearch={onClearInlineSearch}
              />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Separator
              className={styles.catalystDropdownMenuItemSeparator}
            />
          </>
        )}
        <div className={styles.catalystDropdownMenuContent}>{children}</div>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
};

const DropdownSubMenu = DropdownMenuPrimitive.Sub;

type DropdownSubMenuTriggerProps = {
  children: React.ReactNode;
  size?: DropdownMenuItemSizes;
  style?: React.CSSProperties;
};

const DropdownSubMenuTrigger = (props: DropdownSubMenuTriggerProps) => {
  const { children, size = DropdownMenuItemSizes.DEFAULT, style } = props;

  return (
    <div className={styles.catalystDropdownMenuItemWrapper} style={style}>
      <DropdownMenuPrimitive.SubTrigger
        className={classNames({
          [styles.catalystDropdownMenuItem]: true,
          [styles["catalystDropdownMenuItem" + size]]: true,
          [styles.catalystDropdownMenuSubTrigger]: true
        })}
      >
        {children}
        <ChevronRight
          className={styles.catalystDropdownMenuTriggerIcon}
          size={12}
        />
      </DropdownMenuPrimitive.SubTrigger>
    </div>
  );
};

type DropdownSubMenuContentProps = {
  children: React.ReactNode;
  width?: string;
  minWidth?: string;
  height?: string;
  "aria-label"?: string;
} & DropdownSearchProps;

const DropdownSubMenuContent = forwardRef<
  HTMLDivElement,
  DropdownSubMenuContentProps
>((props, ref) => {
  const {
    children,
    width,
    minWidth,
    height,
    showInlineSearch: shouldShowInlineSearch,
    onInlineSearch,
    onClearInlineSearch,
    searchPlaceholder,
    "aria-label": ariaLabel
  } = props;

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.SubContent
        className={styles.catalystDropdownMenu}
        sideOffset={8}
        style={{
          width: width,
          minWidth: minWidth,
          maxHeight: height,
          zIndex: CATALYST_Z_INDEXES.DROPDOWN
        }}
        ref={ref}
        // loop
      >
        {shouldShowInlineSearch && onInlineSearch && (
          <>
            <DropdownMenuPrimitive.Item
              asChild
              onSelect={evt => {
                evt.preventDefault();
              }}
              onPointerMove={evt => {
                evt.preventDefault();
              }}
              onPointerLeave={evt => {
                evt.preventDefault();
              }}
            >
              <InlineSearch
                onSearch={onInlineSearch}
                onClearSearch={onClearInlineSearch}
                placeholder={searchPlaceholder}
              />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Separator
              className={styles.catalystDropdownMenuItemSeparator}
            />
          </>
        )}
        <div
          className={styles.catalystDropdownMenuContent}
          aria-label={ariaLabel}
        >
          {children}
        </div>
      </DropdownMenuPrimitive.SubContent>
    </DropdownMenuPrimitive.Portal>
  );
});

type DropdownMenuGroupProps = {
  children: React.ReactNode;
  label?: string;
  hasSeparator?: boolean;
};

const DropdownMenuGroup = (props: DropdownMenuGroupProps) => {
  const { children, label, hasSeparator = false } = props;

  return (
    <>
      {!!label && (
        <DropdownMenuPrimitive.Label
          className={styles.catalystDropdownMenuItemGroupLabel}
        >
          <Typography
            variant={TypographyVariants.OVERLINE_SMALL}
            color={TypographyColors.NEUTRAL_700}
          >
            {label}
          </Typography>
        </DropdownMenuPrimitive.Label>
      )}
      <DropdownMenuPrimitive.Group
        className={styles.catalystDropdownMenuItemGroup}
      >
        {children}
      </DropdownMenuPrimitive.Group>
      {hasSeparator && (
        <DropdownMenuPrimitive.Separator
          className={styles.catalystDropdownMenuItemSeparator}
        />
      )}
    </>
  );
};

type DropdownMenuItemProps = {
  children: React.ReactNode;
  value: string;
  onSelect: (value: string) => void;
  disabled?: boolean;
  type?: DropdownMenuItemTypes;
  size?: DropdownMenuItemSizes;
  preventDropdownClose?: boolean;
  style?: React.CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
  /**
   * When 'isAsChildEnabled' is `true`, the default wrapper DOM element will not be rendered,
   * instead it will clone the child and passed it's props and behavior required
   * to make it functional.
   *
   * Added temporarily as a workaround to an issue caused when using
   * DropdownMenuItemComponent with Popover component which causes the
   * popover to be dismissed as soon as the cursor leaves the children of
   * DropdownMenuItemComponent. This is due to the 'onPointerLeave'
   * implementation in <DropdownMenuPrimitive.Item> conflicting with Popover.
   *
   * [Detailed explanation on Slack thread](https://vetted.slack.com/archives/C0120UHB3DX/p1722490471144719?thread_ts=1721205260.833769&cid=C0120UHB3DX)
   */
  isAsChildEnabled?: boolean;
};

const DropdownMenuItemComponent = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>((props, ref) => {
  const {
    children,
    value,
    onSelect,
    disabled: isDisabled = false,
    type = DropdownMenuItemTypes.DEFAULT,
    size = DropdownMenuItemSizes.DEFAULT,
    preventDropdownClose: shouldPreventDropdownClose = false,
    style,
    isAsChildEnabled
  } = props;

  return (
    <div
      className={styles.catalystDropdownMenuItemWrapper}
      style={style}
      ref={ref}
    >
      <DropdownMenuPrimitive.Item
        className={classNames({
          [styles.catalystDropdownMenuItem]: true,
          [styles["catalystDropdownMenuItem" + type]]: true,
          [styles["catalystDropdownMenuItem" + size]]: true
        })}
        textValue={value}
        disabled={isDisabled}
        onSelect={evt => {
          if (shouldPreventDropdownClose) {
            evt.preventDefault();
          }
          onSelect(value);
        }}
        asChild={isAsChildEnabled}
      >
        {children}
      </DropdownMenuPrimitive.Item>
    </div>
  );
});

const DropdownMenuItem = memo(DropdownMenuItemComponent);

type DropdownMenuItemNoResultsProps = {
  children: React.ReactNode;
};

const DropdownMenuItemNoResults = (props: DropdownMenuItemNoResultsProps) => {
  const { children } = props;
  return (
    <DropdownMenuPrimitive.Item
      textValue="noResults"
      onSelect={() => {}}
      disabled={true}
      className={styles.catalystDropdownMenuItemNoResults}
      aria-live="polite"
    >
      {children}
    </DropdownMenuPrimitive.Item>
  );
};

type DropdownMenuCheckboxItemProps = {
  children: React.ReactNode;
  value: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean, value: string) => void;
  preventDropdownClose?: boolean;
  size?: DropdownMenuItemSizes;
  style?: React.CSSProperties;
  checkboxRef?: React.Ref<HTMLDivElement>;
};

const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  DropdownMenuCheckboxItemProps
>((props, ref) => {
  const {
    children,
    value,
    onChange,
    checked: isChecked = false,
    disabled: isDisabled = false,
    preventDropdownClose: shouldPreventDropdownClose = false,
    size = DropdownMenuItemSizes.DEFAULT,
    style,
    checkboxRef
  } = props;

  return (
    <div
      className={styles.catalystDropdownMenuItemWrapper}
      style={style}
      ref={ref}
    >
      <DropdownMenuPrimitive.CheckboxItem
        className={classNames({
          [styles.catalystDropdownMenuItem]: true,
          [styles["catalystDropdownMenuItem" + size]]: true,
          [styles.catalystDropdownMenuCheckboxItem]: true
        })}
        checked={isChecked}
        onCheckedChange={checked => onChange(checked, value)}
        disabled={isDisabled}
        onSelect={evt => {
          if (shouldPreventDropdownClose) {
            evt.preventDefault();
          }
        }}
        ref={checkboxRef}
      >
        {children}
        <DropdownMenuPrimitive.ItemIndicator
          className={styles.catalystDropdownMenuCheckboxItemIndicator}
        >
          <Check size={12} />
        </DropdownMenuPrimitive.ItemIndicator>
      </DropdownMenuPrimitive.CheckboxItem>
    </div>
  );
});

const DropdownMenuDivider = DropdownMenuPrimitive.Separator;

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuItemNoResults,
  DropdownSubMenu,
  DropdownSubMenuTrigger,
  DropdownSubMenuContent,
  DropdownMenuGroup,
  DropdownMenuCheckboxItem
};
