import { css } from "emotion";
import type { FC } from "react";
import { Stack, Text } from "@certa/blocks/thanos";

import { useIntl } from "react-intl";

export const NoSubscriptions: FC = () => {
  const intl = useIntl();
  return (
    <Stack
      direction="vertical"
      align="center"
      style={{ height: "100%" }}
      justify="center"
    >
      <Stack
        direction="vertical"
        gap="s1"
        align="center"
        justify="center"
        className={css`
          width: 350px;
        `}
      >
        <Stack>
          <Text variant="h3-bold" align="center">
            {intl.formatMessage({
              id: "mentions.noSubscriptionHeading",
              defaultMessage: "You have not subscribed to any threads yet"
            })}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
