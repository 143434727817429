/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { Fragment } from "react";
import { Stack, Text } from "@certa/blocks/thanos";
import type { WorkflowFamily } from "@certa/types/src/comments";

type Hierarchy = {
  processName: string;
  taskName: string | null;
  fieldName: string | null;
  workflowFamily: WorkflowFamily;
  taskGroupName?: string | null;
  threadName?: string | null;
};
// TODO: @umer to create a common hierarcy component and use it here and in @certa/comments
const MentionHierarchy: FC<Hierarchy> = ({
  processName,
  taskName,
  fieldName,
  workflowFamily,
  taskGroupName,
  threadName
}) => {
  const renderFamily = workflowFamily?.map((item, index) => {
    const shouldRenderDivider = index !== workflowFamily.length - 1;
    return (
      <Fragment key={index}>
        <Text
          variant={shouldRenderDivider ? "p1-regular" : "p1-bold"}
          color={shouldRenderDivider ? "neutral-70" : "neutral-100"}
          className="hierarchy-item"
        >
          {item.name}
        </Text>
        {shouldRenderDivider && (
          <Fragment>
            <Text variant="p1-regular" color="brand-15" className="separator">
              /
            </Text>
          </Fragment>
        )}
      </Fragment>
    );
  });

  return (
    <Stack
      align="center"
      gap="s2"
      css={css`
        width: 100%;
        .hierarchy-item {
          max-width: min-content;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .separator {
          max-width: min-content;
        }
      `}
    >
      {!workflowFamily.length ? (
        <Fragment>
          <Text
            variant="p1-regular"
            color="neutral-70"
            className="hierarchy-item"
          >
            {processName}
          </Text>
          {taskGroupName && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant={fieldName || threadName ? "p1-regular" : "p1-bold"}
                color={fieldName || threadName ? "neutral-70" : "neutral-100"}
                className="hierarchy-item"
              >
                {taskGroupName}
              </Text>
            </Fragment>
          )}
          {taskName && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant={fieldName || threadName ? "p1-regular" : "p1-bold"}
                color={fieldName || threadName ? "neutral-70" : "neutral-100"}
                className="hierarchy-item"
              >
                {taskName}
              </Text>
            </Fragment>
          )}
          {fieldName && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant={threadName ? "p1-regular" : "p1-bold"}
                color={threadName ? "neutral-70" : "neutral-100"}
                className="hierarchy-item"
              >
                {fieldName}
              </Text>
            </Fragment>
          )}
          {threadName && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
              <Text
                variant="p1-bold"
                color="neutral-100"
                className="hierarchy-item"
              >
                {threadName}
              </Text>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>{renderFamily}</Fragment>
      )}
    </Stack>
  );
};

export { MentionHierarchy };
