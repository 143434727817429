import { cx, css } from "emotion";
import type { FC } from "react";
import { useCallback, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Stack, Text } from "@certa/blocks/thanos";
import { FullHeightSpinner, TIMEOUTS } from "@certa/common";
import type { CommentChannel, WorkflowFamily } from "@certa/types";
import { CommentsContext } from "../../comments.context";
import type { CommentsProps } from "../Comments";
import { ChannelViewIdentifier } from "./ChannelViewIdentifier";

const renderSomethingWentWrong = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Text variant="h2-regular" color="red">
      <FormattedMessage id="notificationInstances.somethingWentWrong" />
    </Text>
  </Stack>
);

/**
 * Comments hierarchy:
 * - No Comments
 * - Multi-channel => list of channels => go to a SingleChannel
 * - Single Channel => Threads => Separate message for ever thread
 */

export const CommentsNew: FC<
  CommentsProps & {
    activeChannel: null | CommentChannel;
    setActiveChannel: (channel: null | CommentChannel) => void;
    workflowFamily?: WorkflowFamily;
    multiChannelWithTabs?: boolean;
  }
> = props => {
  const {
    onStepClick,
    onClose,
    multiChannel: isMultiChannel,
    readOnly: isReadOnly,
    channels,
    defaultThreadId,
    header,
    status,
    activeChannel,
    setActiveChannel,
    messageId,
    defaultStatus,
    defaultAdjudicationCode,
    adjudicationRiskCodes,
    workflowFamily,
    multiChannelWithTabs: isMultiChannelWithTabs
  } = props;

  /**
   * To handle step click event, that gets triggered from the CommentSubHeader
   */
  const handleStepClick = useCallback(
    (workflowId, { groupId, stepId }) => {
      if (stepId && groupId && onStepClick) {
        onClose?.();
        onStepClick?.(groupId, stepId, workflowId);
      }
    },
    [onClose, onStepClick]
  );

  useEffect(() => {
    if (messageId && status === "success") {
      const messageDOMElement = document.getElementById(messageId);
      messageDOMElement?.scrollIntoView({
        behavior: "auto",
        block: "center"
      });
      messageDOMElement?.classList.add("hightlight-message");
      setTimeout(() => {
        messageDOMElement?.classList.remove("hightlight-message");
      }, TIMEOUTS.MESSAGE_HIGHLIGHT);
    }
  }, [messageId, status]);

  const contextValue = useMemo(
    () => ({
      onStepClick: handleStepClick,
      isReadOnly: !!isReadOnly,
      isMultiChannel: !!isMultiChannel,
      defaultThreadId: defaultThreadId,
      extra: props.extra,
      header,
      activeChannel,
      setActiveChannel,
      workflowFamily
    }),
    [
      handleStepClick,
      isReadOnly,
      isMultiChannel,
      defaultThreadId,
      props.extra,
      header,
      activeChannel,
      setActiveChannel,
      workflowFamily
    ]
  );

  if (status === "idle" || status === "loading") return <FullHeightSpinner />;

  if (status === "error") return renderSomethingWentWrong();

  return (
    <CommentsContext.Provider value={contextValue}>
      <Stack
        className={cx(
          "comments-new",
          css`
            height: 100%;
          `
        )}
        direction="vertical"
      >
        <ChannelViewIdentifier
          channels={channels}
          defaultStatus={defaultStatus}
          defaultAdjudicationCode={defaultAdjudicationCode}
          adjudicationRiskCodes={adjudicationRiskCodes}
          multiChannelWithTabs={isMultiChannelWithTabs}
        />
      </Stack>
    </CommentsContext.Provider>
  );
};
