import type { FC } from "react";
import { Text } from "@certa/blocks/thanos";
import { NoTasksIllustration } from "@certa/blocks";
import { useNotificationsNewUI, useScreenResolution } from "@certa/common";
import {
  Stack,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import { css } from "emotion";
import { ListGroupIllustration } from "@certa/common/src/components/LoadingAndEmptyStatesWrapper/illustrations/ListGroupIllustration";

export const NoComments: FC<{
  heading: string;
  subHeading?: string;
  isMultiChannel?: boolean;
}> = ({ heading, subHeading, isMultiChannel }) => {
  const { isMobileResolution } = useScreenResolution();
  const shouldShowNewCommentsUX = useNotificationsNewUI();
  if (shouldShowNewCommentsUX && !isMultiChannel) {
    return (
      <div
        className={css({
          borderTop: "1px solid var(--colors-neutral-400)"
        })}
      />
    );
  }

  if (shouldShowNewCommentsUX && isMultiChannel) {
    return (
      <Stack
        direction="vertical"
        align="center"
        justify="center"
        gap="8"
        dangerouslySetClassName={noCommentsWrapperStyles}
      >
        <ListGroupIllustration />
        <Stack>
          <Typography
            variant={TypographyVariants.BODY_BOLD}
            color={TypographyColors.NEUTRAL_800}
          >
            {heading}
          </Typography>
        </Stack>
        {subHeading ? (
          <Stack>
            <Typography
              variant={TypographyVariants.BODY}
              color={TypographyColors.NEUTRAL_600}
            >
              {subHeading}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    );
  }

  return (
    <Stack
      direction="vertical"
      align="center"
      justify="center"
      dangerouslySetClassName={noCommentsWrapperStyles}
    >
      <NoTasksIllustration />
      <Stack>
        <Text variant="h3-bold" align="center">
          {heading}
        </Text>
      </Stack>
      {subHeading ? (
        <Stack
          css={css`
            ${isMobileResolution && ` width: 80vw;`}
          `}
        >
          <Text variant="p1-regular" color="neutral-70" align="center">
            {subHeading}
          </Text>
        </Stack>
      ) : null}
    </Stack>
  );
};

const noCommentsWrapperStyles = css({
  width: "100%",
  height: "100%",
  backgroundColor: "var(--colors-neutral-200)"
});
