/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { Stack } from "@certa/blocks/thanos";
import type { UserMention } from "@certa/queries";
import { useGetMentionsList } from "@certa/queries";
import { Loader } from "@certa/blocks";
import { NoMentions } from "./NoMentions";
import InfiniteScroll from "react-infinite-scroller";
import { MentionItem } from "./MentionItem";
import { SomethingWentWrong } from "@certa/common";

const renderLoader = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Loader />
  </Stack>
);

const MentionsList: FC = () => {
  const { status, data, hasNextPage, fetchNextPage } = useGetMentionsList();

  if (status === "idle" || status === "loading") return renderLoader();

  if (status === "error") return <SomethingWentWrong />;

  if (status === "success" && !data?.pages?.[0]?.results?.length)
    return <NoMentions />;

  return (
    <Stack
      direction="vertical"
      css={css`
        height: 100%;
        overflow-y: auto;
        margin: 0 var(--n-s6);
      `}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage || false}
        useWindow={false}
        loader={
          <Stack
            align="center"
            justify="center"
            css={css`
              margin-top: var(--s5);
            `}
          >
            <Loader />
          </Stack>
        }
        threshold={250}
      >
        {data?.pages?.map(record =>
          record.results.map((mention: UserMention) => {
            return (
              <MentionItem mention={mention} key={`${mention.mentionId}`} />
            );
          })
        )}
      </InfiniteScroll>
    </Stack>
  );
};

export { MentionsList };
