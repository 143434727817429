import type { FC } from "react";
import React from "react";
import { Menu as AntMenu } from "antd";
import type { MenuItemGroupProps } from "antd/lib/menu/index";
import { Text } from "../Typography/Text";
import styled from "@emotion/styled";

export const CustomMenuItemGroup: FC<MenuItemGroupProps> = props => {
  const { title, ...otherprops } = props;
  return (
    <AntMenu.ItemGroup
      title={<Text variant="p2-bold-upper">{title}</Text>}
      {...otherprops}
    />
  );
};

export const MenuItemGroup = styled(CustomMenuItemGroup)`
  .ant-menu-item-group-title,
  .ant-dropdown-menu-item-group-title {
    padding: var(--s5) var(--s2) var(--s2) var(--s2);
    line-height: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  &:first-child .ant-menu-item-group-title,
  &:first-child .ant-dropdown-menu-item-group-title {
    padding: var(--s3) var(--s2) var(--s2) var(--s2);
  }
  --label: CustomMenuItemGroup;
`;
