import type { FC } from "react";
import { useState, forwardRef } from "react";
import { css } from "emotion";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart as ReBarChart,
  LabelList,
  ReferenceArea,
  CartesianGrid
} from "recharts";
import type { TooltipProps } from "recharts";
import { Paper, TYPOGRAPHY_VARIANTS } from "@certa/blocks/thanos";
import {
  tooltipFormatter,
  getValueLabelTextAnchor,
  commonChartMargin,
  CustomLegendWrapper,
  useGetLegendDataCommon,
  Y_AXIS_WIDTH,
  groupDataForChart,
  generateUniqueKeys,
  axisFormatter,
  CustomYAxesLabel,
  CustomXAxesLabel
} from "@certa/chartreport";
import type { ChartClickEvent, ChartComponentProps } from "@certa/common";
import { INDEX_ZERO } from "@certa/common";
import { CustomAxesTick } from "../../customAxesTick/CustomAxesTick";
import { activeXLabel, lineStyle, tooltipCSS } from "./Barchart.styles";
import {
  Typography,
  TypographyVariants as TYPOGRAPHY_VARIANTS_CATALYST
} from "@certa/catalyst";

export const GroupedBarChart: FC<ChartComponentProps> = forwardRef(
  (props, ref) => {
    const {
      // The default value of isAnimationActive is set to false, therefore the
      // isAnimationActive parameter is not required in all test cases.
      // Only in test scenarios, if isAnimationActive is true, the value label will not be shown.
      xAxisDataLabels,
      chartData,
      groupByORM,
      height = 500,
      onClick,
      xActiveIdentifier,
      otherConfig,
      sorting,
      handleSorterChange
    } = props;
    const { data, probableDataType } = chartData;
    const [showValueFor, setShowValueFor] = useState<string | null>(null);
    const {
      colors,
      shouldShowValueLabels,
      showValueOn,
      showLegend,
      yAxisLabel,
      xAxisLabel,
      showXAxisLabel,
      showYAxisLabel,
      secondXAxisLabels,
      valueLabelAngle
    } = otherConfig;

    const secondaryXAxis = groupByORM?.find(
      group => group.extraJSON.isForSecondaryXAxis
    );
    const secondaryXAxisLabel = secondaryXAxis?.extraJSON?.label;

    const chartConfigForGroupedChart = {
      xAxisFields: groupByORM
        ? xAxisDataLabels
        : xAxisDataLabels.length > 1
          ? xAxisDataLabels.filter(
              label => label !== secondXAxisLabels?.[INDEX_ZERO]
            )
          : xAxisDataLabels,
      yAxisField: yAxisLabel ?? "",
      groupField: groupByORM
        ? secondaryXAxisLabel
        : secondXAxisLabels
          ? secondXAxisLabels?.[INDEX_ZERO]
          : ""
    };

    const chartDataForGroupedChart = groupDataForChart(
      data,
      chartConfigForGroupedChart
    );

    const xAxisLabelsForGroupedChart = generateUniqueKeys(
      data,
      chartConfigForGroupedChart
    );

    const X_AXIS_FIELD_KEY = "xAxisField";

    const legendData = useGetLegendDataCommon({
      colors: colors,
      labels: xAxisLabelsForGroupedChart,
      xAxisKey: X_AXIS_FIELD_KEY,
      fillOpacity: 0.6
    });

    return (
      <CustomLegendWrapper
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        data={legendData}
        showLegend={showLegend}
      >
        <ResponsiveContainer
          width="100%"
          height={height}
          className={showValueOn === "hover" ? lineStyle : activeXLabel}
        >
          <ReBarChart
            data={chartDataForGroupedChart}
            ref={ref}
            onClick={(data: Omit<ChartClickEvent, "activeXIdentifier">) => {
              if (onClick && data) {
                onClick({
                  ...data,
                  activeXIdentifier: data.activeTooltipIndex ?? data.activeLabel
                });
              }
            }}
            margin={commonChartMargin}
          >
            <CartesianGrid
              strokeDasharray="4"
              stroke="#DFE2E7"
              vertical={false}
            />
            <XAxis
              key={X_AXIS_FIELD_KEY}
              dataKey={X_AXIS_FIELD_KEY}
              fontSize="12"
              tickSize={15}
              tickLine={false}
              tick={CustomAxesTick}
              fontWeight={400}
              interval="preserveStartEnd"
              stroke="#DFE2E7"
              label={
                <CustomXAxesLabel
                  label={xAxisLabel}
                  showAxisLabel={showXAxisLabel}
                />
              }
            />
            <YAxis
              fontSize="12"
              tickLine={false}
              tickSize={15}
              tickFormatter={axisFormatter(probableDataType)}
              fontWeight={400}
              width={Y_AXIS_WIDTH}
              interval="preserveStartEnd"
              tick={{ fill: "var(--neutral-70)" }}
              stroke="#DFE2E7"
              label={
                <CustomYAxesLabel
                  label={yAxisLabel}
                  showAxisLabel={showYAxisLabel}
                />
              }
            />
            <Tooltip
              formatter={tooltipFormatter("BAR_CHART", probableDataType)}
              content={
                <CustomTooltipForGroupedCharts
                  shouldShowPercentage={
                    otherConfig?.shouldShowPercentagesInValueLabels
                  }
                  height={height}
                />
              }
              cursor={{
                fill: "var(--neutral-10)",
                style: onClick
                  ? { cursor: "pointer", pointerEvents: "all" }
                  : {}
              }}
            />
            <ReferenceArea
              x1={xActiveIdentifier}
              x2={xActiveIdentifier}
              fill="var(--neutral-50)"
              fillOpacity={1}
              label={{
                value: "",
                position: "center",
                angle: -90,
                offset: 12,
                style: TYPOGRAPHY_VARIANTS["p2-medium-upper"] as {}
              }}
            />
            {/* 
              Maps over the xAxisLabels to render individual Bar components for each label. 
              Also, calculates and displays percentage values at the center of
              each bar stack and in the tooltip. 
          */}
            {legendData.map((legend, index) => {
              // #KEYS_FOR_GROUPED_BAR_CHART
              // Search for the above key to find references for the below key
              const stackId = chartConfigForGroupedChart.groupField
                ? legend.label.split("-")[0]
                : "stack"; // Adjust the stackId logic

              const currentObjInChartData = chartDataForGroupedChart.find(obj =>
                obj.hasOwnProperty(legend.label)
              );

              const percentage =
                (currentObjInChartData[legend.label] /
                  currentObjInChartData["sum"]) *
                100;

              return (
                <Bar
                  isAnimationActive={false}
                  key={legend.label}
                  dataKey={legend.label}
                  stackId={stackId}
                  fill={legend.background}
                  fillOpacity={legend.fillOpacity}
                  style={showValueOn === "click" ? { cursor: "pointer" } : {}}
                  onClick={() => setShowValueFor(legend.label)}
                >
                  {shouldShowValueLabels &&
                  (showValueOn !== "click" || showValueFor === legend.label) ? (
                    <LabelList
                      dataKey={legend.label}
                      angle={valueLabelAngle}
                      fill=""
                      formatter={(value: any) =>
                        otherConfig.shouldShowPercentagesInValueLabels
                          ? `${tooltipFormatter(
                              "BAR_CHART",
                              probableDataType
                            )(value)} (${percentage.toFixed(2)}%)`
                          : tooltipFormatter(
                              "BAR_CHART",
                              probableDataType
                            )(value)
                      }
                      style={{
                        // 0deg angle need not have textAnchor
                        textAnchor: getValueLabelTextAnchor(valueLabelAngle)
                      }}
                    />
                  ) : null}
                </Bar>
              );
            })}
          </ReBarChart>
        </ResponsiveContainer>
      </CustomLegendWrapper>
    );
  }
);

const CustomTooltipForGroupedCharts = (
  props: TooltipProps<any, any> & {
    shouldShowPercentage: boolean | undefined;
    height: number;
  }
) => {
  const {
    active: isActive,
    payload: tooltipPayload,
    label,
    shouldShowPercentage,
    height,
    formatter = (value: any) => value // since formatter can be undefined
  } = props;
  if (isActive && tooltipPayload && tooltipPayload.length) {
    return (
      <Paper
        className={tooltipCSS(height)}
        gutter="s2"
        gap="s1"
        direction="vertical"
      >
        <Typography
          className={listTitleCSS}
          variant={TYPOGRAPHY_VARIANTS_CATALYST.LABEL_SM_BOLD}
        >
          {label}
        </Typography>
        <ul className={listCSS}>
          {tooltipPayload.map((payloadItem, index) => {
            const {
              name,
              value,
              color,
              dataKey,
              payload: { sum }
            } = payloadItem;
            const percentage = (value / sum) * 100;
            return (
              <li key={dataKey} className={listItemCSS}>
                <Typography variant={TYPOGRAPHY_VARIANTS_CATALYST.LABEL_SM}>
                  <span style={{ color }}>
                    {shouldShowPercentage
                      ? // @ts-expect-error - formatter() expects 5 arguments, but only 1 passed
                        `${name} : ${formatter(value)} (${percentage.toFixed(
                          2
                        )}%)`
                      : // @ts-expect-error - formatter() expects 5 arguments, but only 1 passed
                        `${name} : ${formatter(value)}`}
                  </span>
                </Typography>
              </li>
            );
          })}
        </ul>
      </Paper>
    );
  }
  return <></>;
};

const listTitleCSS = css({ flexShrink: 0 });
const listCSS = css({
  padding: 0,
  margin: 0
});

const listItemCSS = css({
  listStyleType: "none",
  padding: "var(--s1) 0",
  display: "block"
});
