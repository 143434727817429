/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";

const NoTasksIllustration: FC<React.SVGProps<SVGSVGElement>> = ({
  children,
  ...props
}) => {
  return (
    <svg
      width="224"
      height="145"
      viewBox="0 0 224 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <rect
          x="47"
          y="31"
          width="155.386"
          height="46.5102"
          rx="5"
          fill="#EAEAEA"
        />
      </g>
      <rect
        x="87.5103"
        y="56.3692"
        width="31.7115"
        height="6.3423"
        rx="3.17115"
        fill="#DBDBDB"
      />
      <rect
        opacity="0.37"
        x="88"
        y="46"
        width="36"
        height="6"
        rx="3"
        fill="#8F8F91"
      />
      <rect
        opacity="0.37"
        x="128"
        y="46"
        width="36"
        height="6"
        rx="3"
        fill="#8F8F91"
      />
      <g opacity="0.4">
        <path
          d="M79 54C79 60.6274 73.6274 66 67 66C60.3726 66 55 60.6274 55 54C55 47.3726 60.3726 42 67 42C73.6274 42 79 47.3726 79 54ZM59.8 54C59.8 57.9764 63.0236 61.2 67 61.2C70.9764 61.2 74.2 57.9764 74.2 54C74.2 50.0236 70.9764 46.8 67 46.8C63.0236 46.8 59.8 50.0236 59.8 54Z"
          fill="#DBDBDB"
        />
        <path
          d="M67 42C69.1737 42 71.3065 42.5904 73.1707 43.7082C75.035 44.8259 76.5606 46.429 77.5847 48.3463C78.6088 50.2636 79.0929 52.4231 78.9853 54.5941C78.8777 56.7651 78.1824 58.8661 76.9737 60.6727C75.765 62.4793 74.0884 63.9237 72.1227 64.8516C70.1571 65.7795 67.9763 66.1561 65.8133 65.9412C63.6503 65.7262 61.5863 64.9278 59.8417 63.6311C58.0972 62.3345 56.7375 60.5884 55.9081 58.5792L60.3448 56.7475C60.8425 57.953 61.6583 59.0007 62.705 59.7787C63.7518 60.5567 64.9902 61.0357 66.288 61.1647C67.5858 61.2937 68.8942 61.0677 70.0736 60.511C71.253 59.9542 72.259 59.0876 72.9842 58.0036C73.7094 56.9196 74.1266 55.659 74.1912 54.3565C74.2557 53.0539 73.9653 51.7582 73.3508 50.6078C72.7364 49.4574 71.821 48.4956 70.7024 47.8249C69.5839 47.1542 68.3042 46.8 67 46.8V42Z"
          fill="#C8C8C9"
        />
      </g>
      <g filter="url(#filter1_d)">
        <rect
          x="23"
          y="68.4898"
          width="155.386"
          height="46.5102"
          rx="5"
          fill="white"
        />
      </g>
      <rect
        x="63.5103"
        y="93.859"
        width="31.7115"
        height="6.3423"
        rx="3.17115"
        fill="#F6F6F6"
      />
      <rect
        x="98.3931"
        y="93.859"
        width="13.7416"
        height="6.3423"
        rx="3.17115"
        fill="#F6F6F6"
      />
      <rect
        x="63.5103"
        y="83.2885"
        width="68.7082"
        height="6.3423"
        rx="3.17115"
        fill="#EAEAEA"
      />
      <path
        d="M55 92C55 98.6274 49.6274 104 43 104C36.3726 104 31 98.6274 31 92C31 85.3726 36.3726 80 43 80C49.6274 80 55 85.3726 55 92ZM35.8 92C35.8 95.9764 39.0236 99.2 43 99.2C46.9764 99.2 50.2 95.9764 50.2 92C50.2 88.0236 46.9764 84.8 43 84.8C39.0236 84.8 35.8 88.0236 35.8 92Z"
        fill="#F6F6F6"
      />
      <path
        d="M43 80C45.2873 80 47.527 80.6537 49.4552 81.8841C51.3834 83.1146 52.9197 84.8704 53.8833 86.9449L49.53 88.967C48.9518 87.7223 48.03 86.6687 46.8731 85.9305C45.7162 85.1922 44.3724 84.8 43 84.8V80Z"
        fill="#EAEAEA"
      />
      <circle cx="210" cy="98" r="4" fill="#C8C8C9" />
      <circle cx="4" cy="50" r="4" fill="#C8C8C9" />
      <circle cx="36" cy="21" r="4" stroke="#C8C8C9" stroke-width="2" />
      <circle cx="163" cy="5" r="4" stroke="#C8C8C9" stroke-width="2" />
      <path
        d="M102.5 8.125C102.07 8.125 101.721 8.47386 101.721 8.90385V10.7212H99.9038C99.4739 10.7212 99.125 11.07 99.125 11.5C99.125 11.93 99.4739 12.2788 99.9038 12.2788H101.721V14.0962C101.721 14.5261 102.07 14.875 102.5 14.875C102.93 14.875 103.279 14.5261 103.279 14.0962V12.2788H105.096C105.526 12.2788 105.875 11.93 105.875 11.5C105.875 11.07 105.526 10.7212 105.096 10.7212H103.279V8.90385C103.279 8.47386 102.93 8.125 102.5 8.125Z"
        fill="#C8C8C9"
      />
      <path
        d="M221 39.75C220.713 39.75 220.481 39.9826 220.481 40.2692V41.4808H219.269C218.983 41.4808 218.75 41.7133 218.75 42C218.75 42.2867 218.983 42.5192 219.269 42.5192H220.481V43.7308C220.481 44.0174 220.713 44.25 221 44.25C221.287 44.25 221.519 44.0174 221.519 43.7308V42.5192H222.731C223.017 42.5192 223.25 42.2867 223.25 42C223.25 41.7133 223.017 41.4808 222.731 41.4808H221.519V40.2692C221.519 39.9826 221.287 39.75 221 39.75Z"
        fill="#C8C8C9"
      />
      <path
        d="M7 80.75C6.71334 80.75 6.48077 80.9826 6.48077 81.2692V82.4808H5.26923C4.98257 82.4808 4.75 82.7133 4.75 83C4.75 83.2867 4.98257 83.5192 5.26923 83.5192H6.48077V84.7308C6.48077 85.0174 6.71334 85.25 7 85.25C7.28666 85.25 7.51923 85.0174 7.51923 84.7308V83.5192H8.73077C9.01743 83.5192 9.25 83.2867 9.25 83C9.25 82.7133 9.01743 82.4808 8.73077 82.4808H7.51923V81.2692C7.51923 80.9826 7.28666 80.75 7 80.75Z"
        fill="#C8C8C9"
      />
      <defs>
        <filter
          id="filter0_d"
          x="37"
          y="23"
          width="175.386"
          height="66.5102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="3"
          y="58.4898"
          width="195.386"
          height="86.5102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export { NoTasksIllustration };
