/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { useState, useEffect } from "react";
import { UserGroup } from "@certa/icons";
import { Stack, Text } from "@certa/blocks/thanos";
import { Button, ButtonVariants, ButtonSizes } from "@certa/catalyst";
import { useThreadContext } from "../../comments.context";
import { useIntl } from "react-intl";
import { useNotificationsNewUI } from "@certa/common";

/**
 * Since the drawer width is fixed so we according to
 * the view we can use a char limit
 */
const MAX_CHAR_LIMIT = 45;

const ThreadUserGroups: FC = () => {
  const intl = useIntl();
  const shouldShowNewCommentsUX = useNotificationsNewUI();

  const [shouldShowAll, setShouldShowAll] = useState(false);

  const { threadUserGroups, threadId } = useThreadContext();

  useEffect(() => setShouldShowAll(false), [threadId]);

  if (!threadUserGroups.length) return null;

  const charLimit = threadUserGroups.map(group => group.name).join("").length;

  /**
   * Here we are calculating exactly how much tags can be
   * rendered in a single row
   */
  const tagLimit = threadUserGroups.reduce(
    ({ limit, name }, group) =>
      name.length + group.name.length < MAX_CHAR_LIMIT
        ? { limit: limit + 1, name: `${name}${group.name}` }
        : { limit, name },

    { limit: 0, name: "" }
  );

  const groups =
    charLimit > MAX_CHAR_LIMIT && !shouldShowAll
      ? threadUserGroups.slice(0, tagLimit.limit)
      : threadUserGroups;

  const toggleShowAll = () => setShouldShowAll(!shouldShowAll);
  return (
    <Stack
      justify="space-between"
      gutter={shouldShowNewCommentsUX ? "s2 s6" : "s0 s1"}
      align={shouldShowAll ? "flex-start" : "center"}
    >
      <Stack gap="s3" align={shouldShowAll ? "flex-start" : "center"}>
        <Stack align="center">
          <Text variant="p1-regular" color="neutral-70">
            {intl.formatMessage({
              id: "comments.threadMembers",
              defaultMessage: "Members"
            })}
          </Text>
        </Stack>
        <Stack
          css={css`
            display: inline-block;
          `}
        >
          {groups.map(group => (
            <Stack
              gap="s1"
              align="center"
              css={css`
                width: max-content;
                padding: 4px 6px;
                background-color: var(--neutral-20);
                border-radius: var(--small-border-radius);
                display: inline-flex;
                margin-right: var(--s2);
                margin-bottom: ${shouldShowAll ? "var(--s2)" : ""};
              `}
              key={`${group.id}`}
            >
              <UserGroup size={12} />
              <Text variant="p1-bold" color="neutral-100">
                {group.name}
              </Text>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {charLimit > MAX_CHAR_LIMIT && (
        <Button
          onClick={toggleShowAll}
          variant={ButtonVariants.LINK}
          size={ButtonSizes.SMALL}
          minWidth="104px"
        >
          {intl.formatMessage(
            shouldShowAll
              ? { defaultMessage: "View Less", id: "comments.viewLessGroups" }
              : {
                  id: "comments.viewMoreGroups",
                  defaultMessage: "View More"
                }
          )}
          ...
        </Button>
      )}
    </Stack>
  );
};

export { ThreadUserGroups };
