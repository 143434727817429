/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import { css as CssStringify } from "emotion";
import type { FC } from "react";
import { Divider, Stack, Text, getAvatarLetters } from "@certa/blocks/thanos";
import { Loader } from "@certa/blocks";
import type { UserMention } from "@certa/queries";
import { useMarkMentionRead, useGetUnReadMentionCount } from "@certa/queries";
import { MentionHierarchy } from "./MentionHierarchy";
import {
  ABSOLUTE_DATE_TIME_FORMAT,
  getUserMentionTag,
  userMentionRegEx,
  UserDetailsTooltip,
  sanitizeHTML,
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common";
import moment from "moment";
import { get as lodashGet } from "lodash-es";
import { useSelector } from "react-redux";
import type { CommentData } from "../components/MentionsContext";
import { useMentionsContext } from "../components/MentionsContext";
import { AvatarColors, Avatar, Tooltip } from "@certa/catalyst";
import { useHistory } from "react-router-dom";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import {
  DASHBOARD_KIND_NAME,
  REPORT_KIND_NAME
} from "@certa/dashboards/src/components/constants";

const MentionItem: FC<{ mention: UserMention }> = ({ mention }) => {
  const {
    mentionId,
    process,
    task,
    field,
    isRead,
    postedBy,
    createdAt,
    message,
    mentionData
  } = mention;

  const { refetch } = useGetUnReadMentionCount();

  const { mutate: markAsRead } = useMarkMentionRead({
    onSuccess: () => refetch()
  });
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );

  const userDisplay = postedBy.fullName || postedBy.email;

  const { updateMode, updateCommentData } = useMentionsContext();

  const history = useHistory();

  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(process?.processId);

  const processName = processDetails?.definition.name;
  const isDashboardMention = processName === DASHBOARD_KIND_NAME;
  const isReportMention = processName === REPORT_KIND_NAME;

  const handleOnMentionClick = () => {
    !isRead && markAsRead(mentionId);

    if (processDetailsStatus === "loading") return;

    const {
      object_id: objectId,
      type: objectType,
      thread_id: threadId,
      uid
    } = mentionData;

    const commentData: CommentData = {
      objectId,
      objectType,
      threadId,
      uid,
      processId: process.processId,
      processName: process.processName,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };

    const location = history.location;
    const [, type, id] = location?.pathname?.split("/") || [];

    // If the user is already on the same page as that of the mention
    // then we don't need to redirect the user
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        process?.processId &&
        process.processId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        history.push(
          `${getDashboardsRoute(process?.processId)}?redirectFromComments=true`
        );
        return;
      }

      if (isReportMention) {
        history.push(
          `${getFullScreenReportRoute(
            process?.processId
          )}?redirectFromComments=true`
        );
        return;
      }

      const redirectCommentData = encodeURIComponent(
        JSON.stringify(commentData)
      );

      history.push(
        `${getProcessDetailRoute(
          process?.processId
        )}?group=${mentionData?.group}&step=${mentionData?.step}&redirectFromComments=true&redirectCommentData=${redirectCommentData}`
      );
    }

    // changing drawer mode to comments drawer
    updateMode("comments");
    // setting comment data for drawer
    updateCommentData(commentData);
  };

  /**
   * TODO: Add support to get the list of all mentions here and then
   * use useCommentMessageFormat hook to highlight the mentions
   *
   * NOTE: For now the mention of the logged in user will not be rendered
   * with different CSS. We need to get the data of all the mentions here first.
   */
  const transformedMessage = message
    .replace(userMentionRegEx, getUserMentionTag(false, "$1"))
    .replace(/\n/g, "<br />");

  const user = {
    id: postedBy.userId,
    fullName: postedBy.fullName,
    email: postedBy.email,
    groups: postedBy.groups
  };

  const avatarLetters = getAvatarLetters(user.fullName || user.email);

  return (
    <Stack
      data-testid="mention-list-item"
      key={`${mentionId}`}
      direction="vertical"
      gutter="s5 s6 s00 s6"
      css={css`
        background-color: var(--${!isRead ? "orange-faded" : "neutral-0"});
        cursor: pointer;
        &:hover {
          background-color: var(--teal-60);
        }
      `}
      onClick={handleOnMentionClick}
    >
      <Stack direction="vertical" gap="s4">
        {processDetailsStatus === "loading" ? (
          <Loader size="extra-small" />
        ) : (
          <MentionHierarchy
            workflowFamily={
              isDashboardMention || isReportMention
                ? processDetails?.workflowFamily || []
                : []
            }
            processName={process.processName}
            taskName={task?.taskName || null}
            fieldName={field?.name || null}
          />
        )}

        <Stack gap="s2">
          <UserDetailsTooltip placement="top" user={user}>
            <div
              className={avatarWrapperClassName}
              tabIndex={0}
              aria-label={avatarLetters}
            >
              <Avatar
                aria-label={userDisplay}
                color={
                  userId === mention.postedBy.userId
                    ? AvatarColors.BRAND
                    : AvatarColors.NEUTRAL
                }
              >
                {avatarLetters}
              </Avatar>
            </div>
          </UserDetailsTooltip>
          <Stack
            direction="vertical"
            gap="s2"
            css={css`
              margin-top: var(--s1);
            `}
          >
            <Stack gap="s2">
              <Text variant="p1-bold" color="black">
                {userDisplay}
              </Text>
              <Tooltip
                content={moment(createdAt).format(ABSOLUTE_DATE_TIME_FORMAT)}
              >
                <Text variant="p1-regular" color="neutral-70">
                  {moment(createdAt).startOf("minutes").fromNow()}
                </Text>
              </Tooltip>
            </Stack>
            <Text
              color="neutral-70"
              variant="p1-regular"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(transformedMessage)
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Divider
        css={css`
          &.ant-divider-horizontal {
            margin: var(--s4) 0 0 0;
          }
        `}
      />
    </Stack>
  );
};

export { MentionItem };

const avatarWrapperClassName = CssStringify`
height: min-content;
  &:focus {
    outline: 2px solid var(--colors-brand-400);
    outline-offset: 2px;
    border-radius: 50%;
  }
`;
