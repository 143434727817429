import type { ErrorBoundaryProps } from "./ErrorBoundary";
import { ErrorBoundary } from "./ErrorBoundary";

export function withErrorBoundary<T>(
  Component: React.ComponentType<T>,
  errorBoundaryProps: ErrorBoundaryProps = {}
) {
  // Typecast to any to avoid TS error for IntrinsicAttributes
  return (props: T) => (
    <ErrorBoundary {...errorBoundaryProps}>
      <Component {...(props as any)} />
    </ErrorBoundary>
  );
}
