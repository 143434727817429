import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import React, {
  useState,
  forwardRef,
  useMemo,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect
} from "react";
import type { IconProps } from "@certa/icons";
import { Dots } from "@certa/icons";
import { Text } from "./Typography/Text";
import { Dropdown } from "./Dropdown";
import { Menu } from "./Menu/Menu";
import { MenuItem } from "./Menu/MenuItem";
import { MenuDivider } from "./Menu/MenuDivider";
import type { StackProps } from "./Stack";
import { Stack } from "./Stack";
import type { ForwardRefComponent } from "framer-motion";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Button } from "../componentsTh/Button";
import { getLayoutContainer } from "../utils";

const MotionStack = motion(Stack);

type OptionValue = string;

type Option = {
  label: string | React.ReactNode;
  icon?: React.ComponentType<IconProps>;
  value: OptionValue;
};

const DEFAULT_RESPONSIVE_TAB_COUNT = 4;
const TAB_CHANGE_ALLOWANCE = 200; // max tab width is 200px
type TabCount = number | false | "responsive";
export const ToggleTabs: FC<
  {
    options: Option[];
    // NOTE: Change reference only if options are updated,
    // else it will move selected to first option
    onChange?: (value: OptionValue) => void;
    max?: TabCount;
    value?: OptionValue;
    actionItem?: {
      label: string | React.ReactNode;
      onClick: () => void;
      icon?: FC<IconProps>;
    };
    growWithEqualWidths?: boolean;
    dropdownCmp?: React.ReactNode;
    stackProps?: StackProps;
    getDropdownContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
  } & Omit<StackProps, "onChange">
> = props => {
  const {
    options: defaultOptions,
    onChange,
    max = false,
    value,
    actionItem,
    growWithEqualWidths = false,
    dropdownCmp,
    getDropdownContainer,
    ...stackProps
  } = props;

  const [maxTabsCount, setMaxTabsCount] = useState(
    DEFAULT_RESPONSIVE_TAB_COUNT
  );
  const [options, setOptions] = useState(defaultOptions);
  const [selection, setSelection] = useState<OptionValue>("");
  const sliceEnd =
    max === false ? options.length : max === "responsive" ? maxTabsCount : max;
  const tabContainerRef = useRef<HTMLDivElement | null>(null);

  const firstClassOptions = useMemo(() => {
    return options.slice(0, sliceEnd);
  }, [options, sliceEnd]);

  const extraOptions = options.slice(sliceEnd);

  const makeActiveOptionVisible = useCallback(
    value => {
      let selectedOption: Option | undefined;

      let selectedOptionIndex = 0;

      const newOptions = defaultOptions.filter((option, index) => {
        if (option.value === value) {
          selectedOption = option;
          selectedOptionIndex = index;
          return false;
        }
        return true;
      });

      if (selectedOption) {
        const selectOptionNewIndex =
          selectedOptionIndex > firstClassOptions.length - 1
            ? firstClassOptions.length - 1
            : selectedOptionIndex;

        newOptions.splice(selectOptionNewIndex, 0, selectedOption);
      }

      setOptions(newOptions);
    },
    [defaultOptions, firstClassOptions]
  );
  const handleChange = useCallback(
    (value: OptionValue, shouldSortTabs: boolean = false) => {
      setSelection(value);
      onChange?.(value);
      if (shouldSortTabs) {
        makeActiveOptionVisible(value);
      }
    },
    [makeActiveOptionVisible, onChange]
  );

  // Update the internal value when the value from props changes
  useEffect(() => {
    if (value && selection !== value) {
      setSelection(value);
      makeActiveOptionVisible(value);
    }
  }, [defaultOptions.length, makeActiveOptionVisible, max, selection, value]);

  /**
   * For the case when options from outside change.
   * let's say for 2 different views then it should
   * again re arrange the new list to show the selected value
   */
  useEffect(() => {
    setSelection(value => {
      makeActiveOptionVisible(value);
      return value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions, firstClassOptions.length]);

  const calcResponsiveTabCount = useCallback(() => {
    if (max !== "responsive") {
      return;
    }
    const containerWidth = (tabContainerRef.current?.parentNode as HTMLElement)
      ?.offsetWidth;
    const tabsWidth = tabContainerRef.current?.offsetWidth;
    if (!containerWidth || !tabsWidth) {
      return;
    }
    if (containerWidth > tabsWidth + TAB_CHANGE_ALLOWANCE) {
      // underflow condition with 200px gap
      setMaxTabsCount(prevCount => {
        if (prevCount < options.length) {
          return prevCount + 1;
        }
        return prevCount;
      });
    } else if (containerWidth < tabsWidth) {
      // overflow condition
      setMaxTabsCount(prevCount => {
        if (prevCount > 1) {
          return prevCount - 1;
        }
        return prevCount;
      });
    }
  }, [max, options.length]);

  useLayoutEffect(() => {
    calcResponsiveTabCount();
  }, [calcResponsiveTabCount, maxTabsCount]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      calcResponsiveTabCount();
    });
    return () => window.removeEventListener("resize", () => {});
  }, [calcResponsiveTabCount]);

  useLayoutEffect(() => {
    calcResponsiveTabCount();
  }, [calcResponsiveTabCount, selection]);

  return (
    <ClassNames>
      {({ css }) => {
        const dropdownClass = css`
          cursor: pointer;
          padding: var(--s1) var(--s3);
          color: var(--neutral-70);
          border-radius: var(--small-border-radius);
          font-size: var(--p1);
          &:hover {
            color: var(--brand);
            background-color: var(--brand-35);
          }
        `;
        const dotsButtonClass = css`
          color: var(--neutral-70) !important;
          &:hover {
            color: var(--brand) !important;
          }
          &.button-is-active {
            color: var(--neutral-70) !important;
          }
          height: auto;
        `;
        return (
          <Stack
            {...stackProps}
            className={css`
              overflow: ${max === "responsive" ? "hidden" : "static"};
              max-width: 100%;
              flex: 1;
            `}
          >
            <Stack
              gap="0"
              className={css`
                background: var(--neutral-20);
                border-radius: 4px;
                padding: 2px;
                width: ${growWithEqualWidths ? "100%" : "auto"};
              `}
              direction="horizontal"
              align="stretch"
              ref={tabContainerRef}
            >
              <AnimateSharedLayout>
                {firstClassOptions.map(option => (
                  <OptionCmp
                    isSelected={option.value === selection}
                    option={option}
                    onClick={handleChange}
                    key={option.value}
                    max={max}
                    style={
                      growWithEqualWidths
                        ? { flexGrow: 1, justifyContent: "center" }
                        : undefined
                    }
                  />
                ))}
              </AnimateSharedLayout>
              {(max !== false && extraOptions.length !== 0) || actionItem ? (
                <Dropdown
                  getPopupContainer={
                    getDropdownContainer &&
                    (triggerNode =>
                      getDropdownContainer(triggerNode) || getLayoutContainer())
                  }
                  overlay={
                    <Menu style={{ maxHeight: 400, overflow: "auto" }}>
                      {actionItem ? (
                        <MenuItem
                          title={actionItem.label}
                          icon={actionItem.icon}
                          onClick={actionItem.onClick}
                        />
                      ) : null}
                      {extraOptions.length && actionItem ? (
                        <MenuDivider />
                      ) : null}
                      {extraOptions.map(option => (
                        <MenuItem
                          key={option.value}
                          title={option.label}
                          icon={option.icon}
                          onClick={() => handleChange(option.value, true)}
                        />
                      ))}
                    </Menu>
                  }
                >
                  {isOpen =>
                    dropdownCmp ? (
                      <MotionStack align="center" className={dropdownClass}>
                        {dropdownCmp}
                      </MotionStack>
                    ) : (
                      <Button
                        icon={<Dots />}
                        isActive={isOpen}
                        style={{
                          lineHeight: 1,
                          height: "auto",
                          fontSize: 16
                        }}
                        className={dotsButtonClass}
                      />
                    )
                  }
                </Dropdown>
              ) : null}
            </Stack>
          </Stack>
        );
      }}
    </ClassNames>
  );
};

const OptionCmp: ForwardRefComponent<
  HTMLDivElement,
  {
    option: Option;
    isSelected?: boolean;
    onClick: (value: OptionValue) => void;
    style?: React.CSSProperties;
    max: TabCount;
  }
> = forwardRef((props, ref) => {
  const { option, isSelected, onClick, style, max } = props;
  const Icon = option.icon;
  return (
    <ClassNames>
      {({ css }) => (
        <MotionStack
          gap="s2"
          direction="horizontal"
          align="center"
          ref={ref}
          onClick={() => onClick(option.value)}
          className={css`
            padding: var(--s2) var(--s3);
            cursor: pointer;
            max-width: ${max === "responsive" && TAB_CHANGE_ALLOWANCE + "px"};
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            > svg {
              width: 1em;
              height: 1em;
              font-size: 12px;
            }
          `}
          initial={{
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
            backgroundColor: "rgba(234,234,234,0)",
            borderRadius: 0,
            color: "var(--neutral-70)"
          }}
          animate={
            isSelected
              ? {
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
                  backgroundColor: "var(--neutral-0)",
                  borderRadius: "var(--s1)",
                  color: "var(--neutral-100)"
                }
              : {
                  boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(234,234,234,0)",
                  borderRadius: 0,
                  color: "var(--neutral-70)"
                }
          }
          whileHover={{
            backgroundColor: isSelected
              ? "var(--neutral-0)"
              : "var(--brand-35)",
            borderRadius: "var(--s1)",
            color: isSelected ? "var(--neutral-100)" : "var(--brand) !important"
          }}
          transition={{
            duration: 0
          }}
          style={style}
          title={typeof option.label === "string" ? option.label : ""}
        >
          {Icon && <Icon />}
          {React.isValidElement(option.label) ? (
            option.label
          ) : (
            <Text
              as="span"
              variant="p1-semibold"
              style={{
                userSelect: "none",
                whiteSpace: "nowrap"
              }}
            >
              {option.label}
            </Text>
          )}
        </MotionStack>
      )}
    </ClassNames>
  );
});
