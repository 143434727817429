import type { ComponentType } from "react";
import React, { useRef, useEffect, useLayoutEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { cx } from "emotion";
import { Helmet } from "react-helmet-async";
import "antd/dist/antd.css";

import { configActions } from "../../js/actions";
import { Routes } from "./Routes";
import { showNotification } from "../common/notification";
import {
  getLoginFailure,
  getLoginSuccess,
  updateLanguage
} from "../LoginPage/loginSlice";
import Godaam from "../../js/utils/storage";
import { LoadStaticData } from "../common/components/LoadStaticData";
import {
  normalizeCSSClass,
  globalCSSClass,
  LAYOUT_ID,
  OverlayManager,
  DesignThemeContextProvider,
  BRAND_COLORS,
  getBrandColors as getBlockBrandColors,
  CSSVariablesClassName
} from "@certa/blocks";
import { AppLoader } from "./AppLoader";
import type { ReduxState } from "../common/interfaces";
import {
  useCheckAuth,
  useSetTimezoneInSession,
  setCommsAuthToken
} from "@certa/queries";
import { withRouter } from "./routerHoc";
import {
  getStudioGenericRoute,
  isLoadingInsideIframe,
  RecentlyVisitedWorkflows,
  RELOAD_REQUIRED_ROUTE,
  SERVICE_UNAVAILABLE_PATH,
  useBrowserAuthSession,
  useExternalScripts,
  DrawerProvider,
  useNavigateToNewUI,
  useCommsNewEndpoints,
  USER_INTERACTION_REQUIRED_PATH,
  useNewVerticalSideNav,
  useAppSelector
} from "@certa/common";
import { get as lodashGet } from "lodash-es";
import queryString from "query-string";
import { history } from "../../js/_helpers";

import { AutoLogin } from "./AutoLogin";
import { CatalystProvider, getBrandColors } from "@certa/catalyst";
import {
  MentionsContextProvider,
  MentionsCommentsDrawer
} from "@certa/mentions";
import { useConfigBrandColor } from "../common/hooks/useConfigBrandColor";
import { CommentsDrawerContextProvider } from "@certa/comments";
import { useThirdPartyAnalytics } from "../../js/components/common/ThirdPartyAnalytics";
import { HelmetMSClarity } from "../../js/components/Analytics/HelmetMSClarity";
import { permissionActions } from "../common/permissions/actions";
import { saveUserDataToGodaam } from "../LoginPage/utils/login.utils";

declare global {
  // Can't convert this to a type since it's inside a global declare block
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Document {
    documentMode?: any;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // @todo: Fix Types later or completely remove
    grecaptcha?: any;
  }
}

function isBrowserIE() {
  return !!window.document.documentMode;
}

const EMBEDDED_VIEW_QUERY_PARAM = "embedded_view";
const HIDE_COMMENTS_ICON = "hide_comments_icon";
const EXIT_IFRAME_BUTTON = "exit_iframe_button";

function AppRouterWithOutAutoLoginEnabled() {
  useThirdPartyAnalytics();
  const location = useLocation();
  const browserMessage = useRef("");
  const ieDetected = useRef(isBrowserIE());
  const dispatch = useDispatch();
  const config = useSelector((state: ReduxState) => state.config);
  const companyName = config?.name;
  const permissions = useSelector((state: ReduxState) => state.permissions);
  const authentication = useAppSelector(state => state.authentication);
  const { clearAuthSession, redirectToLogin } = useBrowserAuthSession();
  const isLoginRoute = useRouteMatch("/login");

  const { isTimezoneInSession, setTimezoneInUserSession } =
    useSetTimezoneInSession();
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  const authQuery = useCheckAuth(!!isLoginRoute, {
    onSuccess: async data => {
      // User is authenticated
      if (data) saveUserDataToGodaam(data);

      if (!isLoginRoute) {
        if (!isTimezoneInSession) setTimezoneInUserSession();
        if (isCommsNewEndpointEnabled) setCommsAuthToken();
      }

      // Updates the user's language in backend if changed
      const userWithUpdatedLanguage = await updateLanguage(data);

      // Updates user data in redux
      dispatch(getLoginSuccess({ user: userWithUpdatedLanguage }));

      if (isLoginRoute) {
        // After login the permissions API needs to be called because is was not called on Login route on mount
        dispatch(permissionActions.getPermissions());

        // Adds the last visited route to the URL for redirecting to after login
        const to = queryString.parse(lodashGet(window.location, "search", ""));
        const pathname: any = to.next ? to.next : "/";
        history.push(pathname);
      }
    },
    onError: () => {
      // User is NOT authenticated
      dispatch(getLoginFailure(null));
    }
  });

  const isServiceUnavailableScreen =
    window?.location?.pathname === SERVICE_UNAVAILABLE_PATH;
  const isUserInteractionRequiredPath =
    window?.location?.pathname === USER_INTERACTION_REQUIRED_PATH;
  const isReloadRequiredRoute =
    window?.location?.pathname === RELOAD_REQUIRED_ROUTE;

  const isServiceHideUnavailableLoaderVisisble =
    isServiceUnavailableScreen ||
    isUserInteractionRequiredPath ||
    isReloadRequiredRoute;

  if (ieDetected.current) {
    browserMessage.current = `Our website is not supported by Internet Explorer. If you are recurrently experiencing an "expired link" error, please try logging into the portal again using Google Chrome or Safari.`;
  }

  const isAppLoading =
    (!isLoginRoute && !permissions.permissions) || !config.id;

  const hasSessionExpired =
    authQuery.status === "error" && authQuery.error.status === 401;

  const userId = authentication?.user?.id;

  useEffect(() => {
    // Update user data in redux only if the userId changes.
    // This happens during impersonation.
    if (authQuery?.data && userId !== authQuery?.data?.id) {
      dispatch(getLoginSuccess({ user: authQuery?.data }));
    }
  }, [dispatch, userId, authQuery]);

  useEffect(() => {
    dispatch(configActions.getConfig());
    if (!isLoginRoute) dispatch(permissionActions.getPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const name = config.name || "Certa";
    document.title = name.charAt(0).toUpperCase() + name.slice(1);
    if (
      permissions.permissions &&
      permissions.loading &&
      !Object.keys(permissions?.permissions || {}).length
    ) {
      showNotification({
        type: "error",
        message: "notificationInstances.noPermissionFound"
      });
    }
  }, [config.name, permissions]);

  useEffect(() => {
    if (
      hasSessionExpired &&
      !isLoginRoute &&
      !isUserInteractionRequiredPath &&
      !isReloadRequiredRoute
    ) {
      clearAuthSession();
      /**
       * Reason for adding the condition below:
       * In iframes, when maintenanceMode is turned on,
       * our /claim-token/ API throws "503" and takes us to service-unavailable
       * page. This in turn redirects us back to "login" and creates an endless loop
       * of the same.
       **/

      if (!isLoadingInsideIframe() || !isServiceUnavailableScreen) {
        redirectToLogin();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSessionExpired]);

  useEffect(() => {
    const parsedSearchParams = new URLSearchParams(location.search);
    if (parsedSearchParams.get(EMBEDDED_VIEW_QUERY_PARAM) === "1") {
      Godaam.isEmbeddedView = true;
    }
    if (parsedSearchParams.get(HIDE_COMMENTS_ICON) === "1") {
      Godaam.hideCommentsIcon = true;
    }
    if (parsedSearchParams.get(EXIT_IFRAME_BUTTON) === "1") {
      Godaam.exitIframeButton = true;
    }
  }, [location.search]);

  useEffect(() => {
    const materialFonts = "1rem 'Material Icons'";
    if ("fonts" in document) {
      if (!(document as any).fonts.check(materialFonts)) {
        (document as any).fonts.load(materialFonts).catch(() => {
          console.error("Failed to load Material Fonts");
        });
      }
    }
  }, []);

  useNavigateToNewUI();

  const configBrandColor = useConfigBrandColor();
  const isStudio = useRouteMatch(getStudioGenericRoute());

  if (ieDetected.current) {
    return (
      <div className="main-container">
        <div className="text-center mr-top-lg t-22">
          {browserMessage.current}
        </div>
      </div>
    );
  }

  if (!isServiceHideUnavailableLoaderVisisble) {
    if (isAppLoading) {
      return <AppLoader />;
    }
  }

  const theme = {
    colors: isStudio ? null : getBrandColors(configBrandColor)
  };

  // Disable recent activity for studio
  const isRecentActivityDisabled = !!isStudio;

  // Why wait for permissions and config to load
  // As soon as it's detected just show the message
  // Ideally we should not even load the permissions and config
  // or any other data/code bundle if the browser is not supported!

  return (
    <>
      {/* Default Title when a route don't contain one. */}
      {companyName && <Helmet title={companyName} />}
      {isStudio && <HelmetMSClarity />}
      <RecentlyVisitedWorkflows disabled={isRecentActivityDisabled} />
      <DndProvider backend={HTML5Backend}>
        <CatalystProvider theme={theme}>
          <DesignThemeContextProvider
            // @ts-expect-error - isStudio is not a boolean value
            value={{ disableConfigBrandColors: isStudio }}
          >
            <MentionsContextProvider>
              <CommentsDrawerContextProvider>
                <DrawerProvider>
                  <AppRouterAppRedesignUILayoutSwitcher
                    canDisableConfigBrandColors={!!isStudio}
                  >
                    {Godaam.user ? <LoadStaticData /> : null}
                    <Routes fallbackLoader={fallbackLoader} />
                  </AppRouterAppRedesignUILayoutSwitcher>
                </DrawerProvider>
              </CommentsDrawerContextProvider>
            </MentionsContextProvider>
          </DesignThemeContextProvider>
        </CatalystProvider>
      </DndProvider>
    </>
  );
}

export const AppRouter = withRouter(() => (
  <AutoLogin>
    <AppRouterWithOutAutoLoginEnabled />
  </AutoLogin>
));

/**
 * 1. This loader is rendered as a fallback UI for loadable/lazy components, config and auth APIs.
 * 2. Since we are using prefetching of loadable/lazy components, so we are using same loader.
 * 3. For loadable/lazy components this loader is only rendered when any route is accessed directly or
 * when user does a hard reload.
 */
const fallbackLoader = <AppLoader />;

type AppRouterAppRedesignUILayoutSwitcherProps = {
  children: React.ReactNode;
  canDisableConfigBrandColors?: boolean;
};
/**
 * This component is used to switch between the new and old layout of the app router.
 *
 * TODO: Remove this component once the migration to new UX designs is completed
 * and enabled on production by default.
 */
export const AppRouterAppRedesignUILayoutSwitcher = (
  props: AppRouterAppRedesignUILayoutSwitcherProps
) => {
  const { children, canDisableConfigBrandColors = false } = props;
  const configBrandColor = useConfigBrandColor();

  const ScriptComponents = useExternalScripts();

  // There are few components from blocks (DS 2.0) package used inside the catalyst drawer, Dialog.
  // Since catalyst drawer, Dialog are rendered outside of this DesignTheme component,
  // we had to set the DS 2.0 CSS color variables on the root element.
  useLayoutEffect(() => {
    const brandColors = canDisableConfigBrandColors
      ? BRAND_COLORS
      : getBlockBrandColors(configBrandColor);

    const rootElement = document.querySelector(":root") as HTMLElement;
    Object.entries(brandColors).forEach(([colorName, hexcode]) => {
      rootElement?.style.setProperty(`--${colorName}`, hexcode);
    });

    document.body.classList.add(CSSVariablesClassName);
  }, [configBrandColor, canDisableConfigBrandColors]);

  const isNewAppRedesignUI = useNewVerticalSideNav();

  useLayoutEffect(() => {
    if (isNewAppRedesignUI) {
      document.getElementById("root")?.style.setProperty("height", "100%");
    }
  }, [isNewAppRedesignUI]);

  return isNewAppRedesignUI ? (
    <div
      id={LAYOUT_ID}
      className={cx(normalizeCSSClass, globalCSSClass)}
      style={{
        width: "100%",
        height: "100%"
      }}
    >
      <OverlayManager>
        {ScriptComponents.map(
          (ScriptComponent: ComponentType, index: number) => (
            <ScriptComponent key={index} />
          )
        )}

        {children}
      </OverlayManager>
      {/* TODO: Only loads the Mentions Comments Drawer for Platform routes, not on Studio routes. */}
      <MentionsCommentsDrawer />
    </div>
  ) : (
    <div id={LAYOUT_ID} className={cx(normalizeCSSClass, globalCSSClass)}>
      <OverlayManager>
        {ScriptComponents.map(
          (ScriptComponent: ComponentType, index: number) => (
            <ScriptComponent key={index} />
          )
        )}
        <div className="main-container" data-testid="router-container">
          {children}
        </div>
      </OverlayManager>
      <MentionsCommentsDrawer />
    </div>
  );
};
