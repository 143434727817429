import { css } from "emotion";
import type { FC } from "react";
import { Divider, Stack } from "@certa/blocks/thanos";
import { MentionsList } from "./MentionsList";
import type { MentionOverlayModes } from "./MentionsContext";
import { useMentionsContext } from "./MentionsContext";
import { SubscriptionsList } from "./SubscriptionsList";
import { HorizontalTab, HorizontalTabs } from "@certa/catalyst";
import { Envelope, Globe } from "@certa/icons";
import { useIntl } from "react-intl";
import { useSubscribeThreadToggle } from "@certa/common";

const MentionsAndSubscriptionsContainer: FC = () => {
  const intl = useIntl();
  const { mode, updateMode } = useMentionsContext();

  const canRenderSubscriptionTab = useSubscribeThreadToggle();

  const onChangeTab = (value: string) => {
    updateMode(value as MentionOverlayModes);
  };

  const getTabs = () => {
    const mentionsTab = (
      <HorizontalTab aria-label="Mentions" value="mentions" icon={<Globe />}>
        {intl.formatMessage({
          id: "mentions.allTab",
          defaultMessage: "All"
        })}
      </HorizontalTab>
    );
    const subscriptionsTab = (
      <HorizontalTab
        aria-label="Subscriptions"
        value="subscriptions"
        icon={<Envelope />}
      >
        {intl.formatMessage({
          id: "mentions.subscriptionTab",
          defaultMessage: "Subscriptions"
        })}
      </HorizontalTab>
    );
    if (canRenderSubscriptionTab) {
      return [mentionsTab, subscriptionsTab];
    }
    return [mentionsTab];
  };

  return (
    <Stack
      direction="vertical"
      className={css`
        height: 100%;
      `}
    >
      <Divider style={{ margin: "1px  0 var(--s2)" }} />
      <Stack
        direction="vertical"
        className={css`
          height: 28px;
          margin-top: 5px !important;
        `}
      >
        <HorizontalTabs
          aria-label="Mentions and Subscriptions"
          activeTab={mode}
          onChange={onChangeTab}
        >
          {getTabs()}
        </HorizontalTabs>
      </Stack>
      {mode === "mentions" ? <MentionsList /> : <SubscriptionsList />}
    </Stack>
  );
};

export { MentionsAndSubscriptionsContainer };
