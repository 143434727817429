import { useMentionsContext } from "./MentionsContext";
import { CommentsNew, type TUseComments } from "@certa/comments";
import { useCommentsQuery } from "@certa/queries";
import { Loader } from "@certa/blocks";
import { Stack } from "@certa/blocks/thanos";
import type { CommentObjectTypes } from "@certa/types";
import { useHistory } from "react-router-dom";
import { getProcessDetailRoute } from "@certa/common";
import { Drawer, DrawerHeader } from "@certa/catalyst";
import { DrawerTitle } from "@certa/comments/src/components/DrawerTitle/DrawerTitle";

type CommentsContainerRedesignedProps = {
  show: boolean;
  handleOnClose: () => void;
};

export const CommentsContainerRedesigned = ({
  show,
  handleOnClose
}: CommentsContainerRedesignedProps) => {
  const { commentData, mode } = useMentionsContext();
  const { objectId, objectType, threadId, uid, processName, workflowFamily } =
    commentData;

  const history = useHistory();

  const { data, status } = useCommentsQuery({
    objectId: Number(objectId),
    objectType: objectType as CommentObjectTypes,
    uid: uid,
    config: { enabled: mode === "comments" }
  });

  const handleStepClick = (
    groupId: number,
    stepId: number,
    workflowId: number
  ) => {
    history.push(
      `${getProcessDetailRoute(workflowId)}?group=${groupId}&step=${stepId}`
    );
  };
  const shouldShowLoader = status === "idle" || status === "loading";

  const activeFieldName = data?.results?.find(
    (channel: TUseComments) => channel?.objectId === objectId
  )?.fieldName;

  if (shouldShowLoader)
    return (
      <Stack justify="center">
        <Loader />
      </Stack>
    );

  return (
    <Drawer
      width="720px"
      title=""
      aria-label="Comments drawer"
      show={show}
      padding="0"
      onClose={handleOnClose}
      showCloseIcon={false}
    >
      {!shouldShowLoader && (
        <DrawerHeader>
          <DrawerTitle
            isMultiChannel={false}
            channels={data?.results || []}
            fieldName={activeFieldName}
            workflowFamily={workflowFamily}
            activeChannel={null}
            setActiveChannel={() => {}}
            onClose={handleOnClose}
            showBackButton={false}
          />
        </DrawerHeader>
      )}
      <CommentsNew
        channels={data?.results || []}
        status={status}
        // doing nothing here, as the step would be already opened
        onStepClick={handleStepClick}
        readOnly={false}
        visible={true}
        multiChannel={false}
        defaultThreadId={threadId}
        header={processName || ""}
        onClose={() => {}}
        activeChannel={null}
        setActiveChannel={() => {}}
        workflowFamily={workflowFamily}
      />
    </Drawer>
  );
};
