import type { FC } from "react";
import React from "react";
import type { IconProps as OldIconProps } from "../types";
import type { IconProps } from "@certa/icons";
import type { ButtonProps } from "../componentsTh/Button";
import { Button } from "../componentsTh/Button";

// TODO:  Add support to highlight it on tab focus [VET-33117]
export const HoverAbleSVG: FC<
  {
    active?: boolean;
    icon: React.VFC<IconProps> | React.VFC<OldIconProps>;
    padding?: string;
    defaultSVGColor?: string; // @todo needs to be removed
    hoverBackground?: string; // hoverBackground - change this to "type" primary | danger - default -> primary
    hoverSVGColor?: string; // @todo needs to be removed
    defaultBackground?: string; // @todo needs to be removed
    svgColorProps?: string; // @todo needs to be removed
    circular?: boolean;
    corners?: "square" | "circular";
    success?: boolean; // For the success action
  } & ButtonProps
> = props => {
  const {
    active = false,
    icon: Icon,
    padding,
    hoverBackground = "brand-35",
    circular,
    corners,
    success,
    className,
    ...buttonProps
  } = props;
  const isDestructBtn = hoverBackground.includes("red");
  return (
    <Button
      icon={<Icon />}
      iconOnly={corners || true}
      destruct={isDestructBtn}
      success={success}
      isActive={active}
      className={className}
      {...buttonProps}
    />
  );
};
