import React, {
  useState,
  useMemo,
  useEffect,
  memo,
  useRef,
  useCallback
} from "react";
import type {
  PropsValue,
  ActionMeta,
  StylesConfig,
  SelectInstance,
  SingleValue,
  CSSObjectWithLabel,
  MultiValue,
  GroupBase
} from "react-select";
import ReactSelect, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";

import { Spinner } from "@certa/icons";

import { TypographyColors } from "../Typography";
import {
  MenuListComponent,
  OptionComponent,
  ControlComponent,
  DropdownIndicatorComponent,
  ClearIndicatorComponent,
  ValueContainerComponent,
  GroupComponent,
  MenuComponent
} from "./Components";

import { FieldStatus } from "../types";

import type { SelectOption, SelectProps, SelectRef } from "./types";

import styles from "./Select.module.css";
import { mergeRefs } from "../../utils";

const SelectComponent = <IsMulti extends boolean = false>(
  props: SelectProps<IsMulti>,
  forwardRef: React.Ref<SelectRef<IsMulti>>
) => {
  const {
    name,
    id,
    label,
    "aria-describedby": ariaDescribedBy,
    value,
    defaultValue,
    noOptionsMessage,
    options = [],
    filterOption,
    isMulti = false,
    isClearable = true,
    isSearchable = true,
    closeMenuOnSelect: shouldCloseMenuOnSelect = true,
    blurInputOnSelect: shouldBlurInputOnSelect = false,
    disabled: isDisabled = false,
    required: isRequired,
    openMenuOnFocus: shouldOpenMenuOnFocus = false,
    error,
    placeholder = "Select...",
    onChange,
    onBlur,
    onSearch,
    maxMenuHeight = 320,
    leftIcon,
    width = "20rem",
    menuWidth,
    minMenuWidth,
    menuHeight,
    menuPortalTarget,
    status,
    placement = "auto",
    autoFocus: shouldAutoFocus = false,
    isLoading = false,
    isVirtualized = false,
    styleConfig: styleConfigProp = {},
    onCreateOption,
    handleScrolledToBottom,
    menuIsOpen: isMenuOpen,
    menuShouldBlockScroll: shouldMenuBlockScroll = true
  } = props;

  const isCreatable = Boolean(onCreateOption);

  const selectRef =
    useRef<SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>>();

  const hasError = !!error || status === FieldStatus.ERROR;

  const [selectedOption, setSelectedOption] =
    useState<PropsValue<SelectOption>>();

  const hasGroupedOptions = useMemo(
    () =>
      !!options.find(option => (option as GroupBase<SelectOption>)?.options),
    [options]
  );

  const InternalSelect = useMemo(() => {
    return isCreatable ? CreatableSelect : ReactSelect;
  }, [isCreatable]);

  const currentOptions = useMemo(
    () =>
      hasGroupedOptions
        ? (options as GroupBase<SelectOption>[]).reduce(
            (acc: Array<SelectOption>, group: any) => {
              if (!group.options) return [...acc, { ...group }];
              return [...acc, ...group.options];
            },
            []
          )
        : (options as SelectOption[]),
    [hasGroupedOptions, options]
  );
  const [filteredOptions, setFilteredOptions] =
    useState<SelectOption[]>(currentOptions);

  useEffect(() => {
    // Only update selected option when loading is complete
    if (!isLoading) {
      // react-select (when controlled) does not remove or reset selected
      // value when it is passed as undefined. Do not check for undefined
      // here, as it will defiy the purpose of uncontrolled component.
      if (value === null || value?.length === 0) {
        setSelectedOption(null);
      } else if (value !== undefined) {
        // Handle multi-select
        if (isMulti && Array.isArray(value) && value.length > 0) {
          // Create a map to lookup option labels by their values
          const optionLabelMap = new Map();
          currentOptions.forEach(option => {
            optionLabelMap.set(option.value, option.label);
          });
          // Map selected values to corresponding options
          const selectedOptions = value.map(_value => ({
            label: optionLabelMap.get(_value) ?? _value,
            value: _value
          }));
          setSelectedOption(selectedOptions as PropsValue<SelectOption>);
        } else {
          const selectedOption = currentOptions.find(option => {
            return option.value && value === option.value;
          }) || { label: value, value }; // Use raw value if option not found
          setSelectedOption(selectedOption as PropsValue<SelectOption>);
        }
      }
      setFilteredOptions(currentOptions);
    }
  }, [currentOptions, isLoading, isMulti, value]);

  useEffect(() => {
    // Only run this effect when selectedOption is undefined
    // This is to prevent the effect from running when the component is re-rendered
    // and to set the defaultValue only once
    if (!selectedOption && !value && defaultValue) {
      if (isMulti && defaultValue.length > 0) {
        const defaultSelectedOptions = currentOptions.filter(option => {
          return option.value && defaultValue.includes(option.value);
        });
        setSelectedOption(defaultSelectedOptions as PropsValue<SelectOption>);
      } else {
        const defaultSelectedOption = currentOptions.find(option => {
          return option.value && defaultValue === option.value;
        });
        setSelectedOption(defaultSelectedOption as PropsValue<SelectOption>);
      }
    }
  }, [defaultValue, isMulti, currentOptions, selectedOption, value]);

  const stylesConfig: StylesConfig<SelectOption> = useMemo(
    () => ({
      container: (base, props) => ({
        ...base,
        width
      }),
      control: (base, props) => {
        let backgroundColor = "var(--colors-neutral-100)";
        let color = "var(--colors-neutral-700)";
        let border = "0.0625rem solid var(--colors-neutral-500)";
        let focusHoverBorder = "0.0625rem solid var(--colors-brand-400)";
        let focusBoxShadow = "0 0 0 0.1875rem var(--colors-brand-200)";
        let hoverFocusBackgroundColor = "var(--colors-neutral-100)";

        if (props.hasValue || props.selectProps.inputValue) {
          color = "var(--colors-neutral-800)";
        }
        if (props.isDisabled) {
          backgroundColor = "var(--colors-neutral-400)";
        } else if (hasError) {
          border = "0.0625rem solid var(--colors-red-600)";
          focusHoverBorder = "0.0625rem solid var(--colors-red-500)";
          focusBoxShadow = "0 0 0 0.1875rem var(--colors-red-300)";
        }
        let borderRadius = "0.25rem";

        if (styleConfigProp?.control) {
          backgroundColor =
            styleConfigProp.control.backgroundColor || backgroundColor;
          border = styleConfigProp.control.border || border;
          focusHoverBorder =
            styleConfigProp.control.focusedBorder || focusHoverBorder;
          hoverFocusBackgroundColor =
            styleConfigProp.control.backgroundColor ||
            hoverFocusBackgroundColor;
          borderRadius = styleConfigProp.control.borderRadius || borderRadius;
          focusBoxShadow = styleConfigProp.control.boxShadow || focusBoxShadow;
        }

        return {
          ...base,
          position: "relative",
          display: "flex",
          alignItems: "center",
          cursor: props.isDisabled ? "not-allowed" : "default",
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          border,
          borderRadius,
          minHeight: "2.25rem",
          // maxHeight: "2.25rem",
          padding: "0.25rem 0.5rem",
          paddingRight: "0.75rem",
          color,
          backgroundColor,
          ":focus-within": {
            boxShadow: focusBoxShadow,
            border: focusHoverBorder,
            backgroundColor: hoverFocusBackgroundColor
          },
          ":hover": {
            cursor: "pointer",
            border: focusHoverBorder,
            backgroundColor: hoverFocusBackgroundColor
          }
        };
      },
      indicatorSeparator: (base, props) => ({
        ...base,
        display: "none"
      }),
      input: (base, props) => {
        const isFocused = selectRef.current?.state.isFocused;

        const topPosition: CSSObjectWithLabel = {
          position: "absolute",
          top: "0"
        };
        return {
          ...base,
          padding: "0 0.5rem",
          ...(isFocused ? {} : topPosition)
        };
      },
      valueContainer: (base, props) => ({
        ...base,
        display: props.hasValue && props.isMulti ? "flex" : base.display,
        ...(props.hasValue && props.isMulti
          ? { columnGap: "0.25rem", rowGap: "0.25rem", flexWrap: "wrap" }
          : {})
      }),
      menu: (base, props) => ({
        ...base,
        background: "var(--colors-neutral-100)",
        marginTop: "0.25rem",
        boxShadow: "0rem 0.5rem 1.5rem rgba(0, 22, 78, 0.15)",
        borderRadius: "0.25rem",
        display: "flex",
        minWidth: minMenuWidth,
        width: isVirtualized
          ? menuWidth ||
            selectRef.current?.controlRef?.getBoundingClientRect().width
          : selectRef.current?.controlRef?.getBoundingClientRect().width
      }),
      menuList: (base, props) => ({
        ...base,
        width: "100%"
      }),
      menuPortal: (base, props) => ({
        ...base,
        zIndex: 9999
      }),
      placeholder: (base, props) => ({
        ...base,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: "var(--colors-neutral-700)",
        padding: "0 0.5rem"
      }),
      singleValue: (base, props) => {
        return {
          ...base,
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: "var(--colors-neutral-800)",
          padding: "0 0.5rem"
        };
      },
      multiValue: (base, props) => {
        let backgroundColor = "var(--colors-brand-100)";
        let color = "var(--colors-neutral-800)";

        if (props.isDisabled) {
          backgroundColor = "var(--colors-neutral-700)";
          color = "var(--colors-neutral-100)";
        } else if (hasError) {
          backgroundColor = "var(--colors-red-200)";
          color = "var(--colors-red-600)";
        }

        return {
          ...base,
          display: "flex",
          alignItems: "center",
          columnGap: "0.25rem",
          backgroundColor,
          color,
          padding: "0 0.5rem",
          minHeight: "1.5rem",
          borderRadius: "0.125rem"
        };
      },
      multiValueLabel: (base, props) => {
        return {
          ...base,
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1rem"
        };
      },
      multiValueRemove: (base, props) => ({
        ...base,
        color: hasError ? "var(--colors-red-600)" : "var(--colors-brand-400)",
        display: props.isDisabled ? "none" : base.display
      }),
      option: (base, props) => {
        let backgroundColor = "var(--colors-neutral-100) ";
        let color = "var(--colors-neutral-800)";
        if (props.isFocused && !props.isDisabled) {
          backgroundColor = "var(--colors-hover-background)";
        }

        if (props.isSelected && !props.isDisabled) {
          backgroundColor = "var(--colors-selected-background)";
          color = "var(--colors-selected-color)";
        }

        if (props.isDisabled) {
          color = "var(--colors-neutral-600)";
        }
        return {
          ...base,
          // minHeight: "2rem",
          padding: "0.5rem",
          display: "flex",
          alignItems: "center",
          fontWeight: "500",
          fontSize: "0.75rem",
          lineHeight: "1rem",
          borderRadius: "0.25rem",
          flexWrap: "wrap",
          overflowWrap: "anywhere",
          color,
          backgroundColor,
          border:
            props.isFocused && !props.isDisabled
              ? "0.125rem solid var(--colors-outline-color)"
              : "0.125rem solid transparent",
          outlineOffset: 0,
          ":hover": props.isDisabled
            ? {
                cursor: "not-allowed"
              }
            : {
                cursor: "pointer",
                color: "var(--colors-hover-color) !important",
                backgroundColor: "var(--colors-hover-background)",
                border: "0.125rem solid var(--colors-hover-background)"
              }
        };
      },
      indicatorsContainer: base => ({
        ...base,
        display: "flex",
        columnGap: "0.5rem",
        paddingLeft: "0.5rem"
      }),
      dropdownIndicator: (base, props) => {
        let color = "var(--colors-brand-400)";
        if (props.isDisabled) {
          color = "var(--colors-neutral-700)";
        } else if (hasError) {
          color = "var(--colors-red-600)";
        }
        return { ...base, color };
      },
      clearIndicator: base => ({
        ...base,
        color: "var(--colors-neutral-700)"
      }),
      noOptionsMessage: base => ({
        ...base,
        padding: noOptionsMessage === null ? 0 : "0.5rem",
        fontSize: "0.75rem",
        lineHeight: "1rem"
      }),
      loadingMessage: base => ({
        ...base,
        padding: "0.5rem",
        fontSize: "0.75rem",
        lineHeight: "1rem"
      }),
      group: base => ({
        ...base,
        width: "100%"
      }),
      groupHeading: base => ({
        ...base,
        padding: "0.5rem",
        fontWeight: 600,
        fontSize: "0.625rem",
        lineHeight: "0.75rem",
        backgroundColor: "var(--colors-neutral-200)",
        textTransform: "uppercase",
        color: "var(--colors-neutral-700)",
        letterSpacing: "0.0938rem",
        textDecoration: "capitalize"
      })
    }),
    [
      width,
      hasError,
      styleConfigProp.control,
      minMenuWidth,
      isVirtualized,
      menuWidth,
      noOptionsMessage
    ]
  );

  const handleOnChange = (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    let tranformedValue: string | string[] = isMulti ? [] : "";
    if (isMulti) {
      tranformedValue = newValue?.map((option: SelectOption) => option.value);
    } else if (newValue) {
      tranformedValue = (newValue as SelectOption).value;
    }

    // Only call onChange if the value has changed for single select
    if (
      (!isMulti &&
        (newValue as SingleValue<SelectOption>)?.value !==
          (selectedOption as SingleValue<SelectOption>)?.value) ||
      isMulti
    ) {
      onChange?.(
        tranformedValue as any,
        actionMeta,
        newValue as IsMulti extends true
          ? MultiValue<SelectOption>
          : SingleValue<SelectOption>
      );
    }

    setSelectedOption(prevState => {
      if (
        !isMulti &&
        prevState &&
        (prevState as SingleValue<SelectOption>)?.value ===
          (newValue as SelectOption)?.value
      ) {
        return prevState;
      }
      return newValue;
    });
  };

  const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(evt);
  };

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      // To prevent keyboard navigation from looping (selecting) the
      // list when first and last options are focused as it is not
      // scrolling to the focused option
      if (
        (evt.key === "ArrowUp" &&
          selectRef.current?.state.focusedOption?.value ===
            filteredOptions[0]?.value) ||
        (evt.key === "ArrowDown" &&
          selectRef.current?.state.focusedOption?.value ===
            filteredOptions[filteredOptions.length - 1]?.value)
      ) {
        evt.preventDefault();
      }
    },
    [filteredOptions]
  );

  const handleInputChange = useCallback(
    (inputValue: string) => {
      if (!onSearch) {
        const currentInput = inputValue.toLowerCase();
        const filteredItems = currentOptions.filter(option =>
          filterOption
            ? filterOption(option, inputValue)
            : (option.value &&
                option.value?.toString().toLowerCase().search(currentInput) !==
                  -1) ||
              (typeof option.label === "string" &&
                option.label?.toString().toLowerCase().search(currentInput) !==
                  -1)
        );
        setFilteredOptions(filteredItems);
      } else {
        onSearch(inputValue);
      }
    },
    [filterOption, onSearch, currentOptions]
  );

  const handleFilterOption = (option: SelectOption, inputValue: string) => {
    if (isCreatable) {
      const currentInput = inputValue;
      if (!inputValue) return true;
      return option.value === currentInput;
    }
    createFilter({ ignoreAccents: false });

    if (!onSearch) {
      const currentInput = inputValue.toLowerCase();
      //if value coming as number we converted it into a string
      return (
        (option.value &&
          option.value?.toString().toLowerCase().search(currentInput) !== -1) ||
        (typeof option.label === "string" &&
          option.label?.toString().toLowerCase().search(currentInput) !== -1)
      );
    }
    return true;
  };

  const MemoizedMenuList = useCallback(
    props => (
      <MenuListComponent
        {...props}
        isVirtualized={isVirtualized}
        menuHeight={menuHeight}
        filteredOptions={filteredOptions}
        menuWidth={
          (menuWidth ||
            selectRef.current?.controlRef?.getBoundingClientRect()
              .width) as number
        }
      />
    ),
    [isVirtualized, menuHeight, menuWidth, filteredOptions]
  );

  const MemoizedOptionComponent = useCallback(
    props => (
      <OptionComponent
        {...props}
        isVirtualized={isVirtualized}
        handleScrolledToBottom={handleScrolledToBottom}
      />
    ),
    [isVirtualized, handleScrolledToBottom]
  );

  const MemoizedControlComponent = useCallback(
    props => <ControlComponent {...props} leftIcon={leftIcon} />,
    [leftIcon]
  );

  const creatableProps = {
    onCreateOption
  };

  return (
    <InternalSelect
      {...(isCreatable ? creatableProps : {})}
      name={name}
      inputId={id}
      aria-label={label}
      aria-labelledby={!!id ? id + "-label" : undefined}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      openMenuOnFocus={shouldOpenMenuOnFocus}
      value={selectedOption}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      options={options}
      className={styles.catalystSelect}
      hideSelectedOptions={false}
      unstyled
      classNamePrefix="catalyst-select"
      closeMenuOnSelect={shouldCloseMenuOnSelect}
      noOptionsMessage={() => noOptionsMessage}
      aria-invalid={hasError}
      aria-errormessage={hasError ? id + "-error" : undefined}
      aria-describedby={hasError ? id + "-error" : ariaDescribedBy}
      required={isRequired}
      blurInputOnSelect={shouldBlurInputOnSelect}
      components={{
        DropdownIndicator: DropdownIndicatorComponent,
        Option: MemoizedOptionComponent,
        ClearIndicator: ClearIndicatorComponent,
        ValueContainer: ValueContainerComponent,
        Group: GroupComponent,
        Menu: MenuComponent,
        MenuList: MemoizedMenuList,
        Control: MemoizedControlComponent,
        LoadingIndicator: () => (
          <Spinner color={TypographyColors.BRAND_400} size={14} />
        )
      }}
      styles={stylesConfig}
      placeholder={placeholder}
      isDisabled={isDisabled}
      maxMenuHeight={menuHeight || maxMenuHeight}
      filterOption={filterOption ? filterOption : handleFilterOption}
      menuPlacement={placement}
      menuPortalTarget={menuPortalTarget}
      menuPosition="fixed"
      autoFocus={shouldAutoFocus}
      isLoading={isLoading}
      menuShouldScrollIntoView
      ref={mergeRefs([
        forwardRef,
        ref => {
          if (ref) {
            // @ts-expect-error: suppressed type error. TODO: fix this
            selectRef.current = ref;
          }
        }
      ])}
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
      menuShouldBlockScroll={shouldMenuBlockScroll}
      onMenuOpen={handleScrolledToBottom}
      menuIsOpen={isMenuOpen}
    />
  );
};

const SelectComponentWithRef = React.forwardRef(SelectComponent) as <
  T extends boolean = false
>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<SelectRef<T>> }
) => ReturnType<typeof SelectComponent>;

// Exports for the Select component
export const Select = memo(SelectComponentWithRef) as typeof SelectComponent;
export * from "./types";
