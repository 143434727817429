import type { Dict } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";

const defaultMixPanelHost = "api.mixpanel.com";
export const initializeMixPanel = () => {
  try {
    if (import.meta.env.REACT_APP_MIXPANEL_PACKAGE_TOKEN) {
      mixpanel.init(import.meta.env.REACT_APP_MIXPANEL_PACKAGE_TOKEN, {
        api_host:
          import.meta.env.REACT_APP_MIXPANEL_HOST || defaultMixPanelHost,
        // Track any URL changes in the path, query string, or hash
        track_pageview: "full-url"
      });
      console.log("MIXPANEL INITIALIZED");
    } else {
      throw new Error("Mixpanel project token not found");
    }
  } catch (e) {
    console.error(e);
  }
};

const envCheck = import.meta.env.REACT_APP_MIXPANEL_PACKAGE_TOKEN;

const logMixPanelInitFailure = () =>
  console.log("Unable to initialize Mixpanel.");

export const MixPanelActions = {
  track: (name: string, props?: Dict) => {
    if (envCheck) {
      mixpanel.track(name, props);
    } else {
      logMixPanelInitFailure();
    }
  },
  identify: (email: string) => {
    if (envCheck) {
      mixpanel.identify(email);
    } else {
      logMixPanelInitFailure();
    }
  },
  register: (props: Dict) => {
    if (envCheck) {
      mixpanel.register(props);
    } else {
      logMixPanelInitFailure();
    }
  },
  reset: () => {
    if (envCheck) {
      mixpanel.reset();
    } else {
      logMixPanelInitFailure();
    }
  }
};

export const MixPanelEvents = {
  workflowDetailEvents: {
    HEADER_CLICK_STATUS: "wfd_header_click_status",
    HEADER_CLICK_OPEN_WF_COMMENTS: "wfd_header_click_openWFComments",
    HEADER_BREADCRUMB_CLICK_OPEN_WORKFLOW:
      "wfd_header_breadcrumb_click_openWorkflow",
    HEADER_CLICK_UNFOLLOW_WORKFLOW: "wfd_header_click_unfollowWorkflow",
    HEADER_CLICK_FOLLOW_WORKFLOW: "wfd_header_click_followWorkflow",
    HEADER_CLICK_COPY_LINK: "wfd_header_click_copyLink",
    HEADER_CLICK_VIEW_ACTIVITY_LOG: "wfd_header_click_viewActivityLog",
    HEADER_CLICK_PRINT_WORKFLOW: "wfd_header_click_printWorkflow",
    HEADER_CLICK_ARCHIVE_WORKFLOW: "wfd_header_click_archiveWorkflow",
    HEADER_CLICK_LINK_TO_PARENT: "wfd_header_click_linkToParent",
    HEADER_CLICK_UNLINK_FROM_PARENT: "wfd_header_click_unlinkFromParent",
    LEFTPANE_CLICK_OPEN_SWIMLANE: "wfd_leftPane_click_openSwimlane",
    LEFTPANE_CLICK_OPEN_STEP: "wfd_leftPane_click_openStep",
    LEFTPANE_CLICK_TOGGLE_SHOW_ALL_ON: "wfd_leftPane_click_toggleShowAllOn",
    LEFTPANE_CLICK_TOGGLE_SHOW_ALL_OFF: "wfd_leftPane_click_toggleShowAllOff",
    FOOTER_CLICK_SUBMIT_STEP: "wfd_footer_click_submitStep",
    FOOTER_CLICK_EDIT_STEP: "wfd_footer_click_editStep",
    FOOTER_CLICK_PREVIOUS_STEP: "wfd_footer_click_previousStep",
    FOOTER_CLICK_NEXT_STEP: "wfd_footer_click_nextStep",
    WF_SIDEPANE_FOOTER_CLICK_SUBMIT_STEP:
      "wfd_wfSidePane_footer_click_submitStep",
    WF_SIDEPANE_FOOTER_CLICK_EDIT_STEP: "wfd_wfSidePane_footer_click_editStep",
    WF_SIDEPANE_FOOTER_CLICK_NEXT_WORKFLOW:
      "wfd_wfSidePane_footer_click_nextWorkflow",
    WF_SIDEPANE_FOOTER_CLICK_PREVIOUS_WORKFLOW:
      "wfd_wfSidePane_footer_click_previousWorkflow",
    WF_SIDEPANE_FOOTER_CLICK_ADD_WORKFLOW:
      "wfd_wfSidePane_footer_click_addWorkflow",
    MAIN_STEP_CLICK_VIEW_COMMENTS: "wfd_main_step_click_viewComments",
    MAIN_FIELD_CLICK_VIEW_COMMENTS: "wfd_main_field_click_viewComments",
    MAIN_STEP_CLICK_VIEW_VERSION_HISTORY:
      "wfd_main_step_click_viewVersionHistory",
    MAIN_STEP_CLICK_ASSIGN_STEP: "wfd_main_step_click_assignStep",
    MAIN_STEP_CLICK_UNASSIGN_STEP: "wfd_main_step_click_unassignStep",
    COMMENT_OVERVIEW_PANE_CLICK_COMMENT:
      "wfd_commentOverviewPane_click_Comment",
    COMMENT_DETAIL_PANE_CLICK_SUBMIT_COMMENT:
      "wfd_commentDetailPane_click_submitComment",
    COMMENT_DETAIL_PANE_CLICK_SUBSCRIBE_TO_THREAD:
      "wfd_commentDetailPane_click_subscribeToThread",
    COMMENT_DETAIL_PANE_CLICK_UNSUBSCRIBE_FROM_THREAD:
      "wfd_commentDetailPane_click_unsubscribeFromThread",
    COMMENT_DETAIL_PANE_CLICK_ATTACH_FILE:
      "wfd_commentDetailPane_click_attachFile",
    COMMENT_DETAIL_PANE_CLICK_OPEN_ADD_GROUP_MODAL:
      "wfd_commentDetailPane_click_openAddGroupModal",
    COMMENT_DETAIL_PANE_CLICK_ADD_GROUP: "wfd_commentDetailPane_click_addGroup",
    ACTIVITY_LOGS_CLICK_VIEW_SIGNIFICANT_EVENTS:
      "wfd_workflowActivityLogs_click_viewSignificantEvents",
    ACTIVITY_LOGS_CLICK_VIEW_EDITS: "wfd_workflowActivityLogs_click_viewEdits",
    ACTIVITY_LOGS_CLICK_VIEW_VIEWS: "wfd_workflowActivityLogs_click_viewViews",
    ACTIVITY_LOGS_CLICK_VIEW_EMAILS:
      "wfd_workflowActivityLogs_click_viewEmails",
    ACTIVITY_LOGS_CLICK_VIEW_NOTIFICATIONS:
      "wfd_workflowActivityLogs_click_viewNotifications",
    ACTIVITY_LOGS_CLICK_DOWNLOAD_ACTIVITY_LOGS:
      "wfd_workflowActivityLogs_click_downloadActivityLogs"
  },

  tasksPageEvents: {
    TASKS_CLICK_HEADERTAB: "tasks_click_headerTab",
    TASKS_CLICK_PROCESSFILTERS: "tasks_click_processFilters",
    TASKS_CLICK_SWIMLANE_VIEW: "tasks_click_swimlaneView",
    TASKS_CLICK_DEFAULT_LIST_VIEW: "tasks_click_defaultListView",
    TASKS_CLICK_WFFILTER_DROPDOWN: "tasks_click_wfFilterDropdown",
    TASKS_CLICK_SHOW_COMPLETED: "tasks_click_showCompletedProcesses_toggle",
    TASKS_CLICK_GROUPBY_TASKLANE: "tasks_click_groupByTasklaneDropdown",
    TASKS_CLICK_ADD_SAVED_FILTER: "tasks_click_addSavedFilter",

    PROCESS_DETAILS_CLICK_PROGRESS_MAP: "processDetails_click_progressMap",
    PROGRESS_MAP_CLICK_EXPAND_ALL_TOGGLE: "progressMap_click_expandAllToggle",
    PROGRESS_MAP_CLICK_SHOW_INACTIVE_STEPS_TOGGLE:
      "progressMap_click_showInactiveStepsToggle",
    PROGRESS_MAP_CLICK_TAB: "progressMap_click_tab",
    PROGRESS_MAP_CLICK_SWIMLANE: "progressMap_click_swimlane",
    PROGRESS_MAP_CLICK_STEP: "progressMap_click_step",
    PROGRESS_MAP_CLICK_ZOOM: "progressMap_click_zoom",
    TASKS_LEFT_PANE_CLICK_SELECT_WORKFLOW_TYPE:
      "tasks_leftPane_click_selectWorkflowType",
    TASKS_MAIN_CLICK_VIEW_MY_TASKS: "tasks_main_click_viewMyTasks",
    TASKS_MAIN_CLICK_VIEW_MY_GROUP_TASKS: "tasks_main_click_viewMyGroupTasks",
    TASKS_MAIN_CLICK_VIEW_ALL_TASKS: "tasks_main_click_viewAllTasks",
    TASKS_MY_TASKS_CLICK_OPEN_QUICK_FILTER_ACTIONS:
      "tasks_myTasks_click_openQuickFilterActions",
    TASKS_MY_GROUP_TASKS_CLICK_OPEN_QUICK_FILTER_ACTIONS:
      "tasks_myGroupTasks_click_openQuickFilterActions",
    TASKS_ALL_TASKS_CLICK_OPEN_QUICK_FILTER_ACTIONS:
      "tasks_allTasks_click_openQuickFilterActions",
    TASKS_ALL_TASKS_CLICK_GROUP_BY_SWIMLANE:
      "tasks_allTasks_click_groupBySwimlane",
    TASKS_ALL_TASKS_CLICK_GROUP_BY_STATUS: "tasks_allTasks_click_groupByStatus",
    TASKS_MY_TASKS_WORKFLOW_TABLE_CLICK_OPEN_WORKFLOW:
      "tasks_myTasks_wfTable_click_openWorkflow",
    TASKS_MY_GROUPS_TASKS_WORKFLOW_TABLE_CLICK_OPEN_WORKFLOW:
      "tasks_myGroupTasks_wfTable_click_openWorkflow",
    TASKS_ALL_TASKS_WORKFLOW_TABLE_CLICK_OPEN_WORKFLOW:
      "tasks_allTasks_wfTable_click_openWorkflow",
    TASKS_MY_TASKS_WF_TASKS_TABLE_CLICK_EXPAND_WORKFLOW:
      "tasks_myTasks_wfTasksTable_click_expandWorkflow",
    TASKS_MY_GROUPS_WF_TASKS_TABLE_CLICK_EXPAND_WORKFLOW:
      "tasks_myGroupTasks_wfTasksTable_click_expandWorkflow",
    TASKS_ALL_TASKS_WF_TASKS_TABLE_CLICK_EXPAND_WORKFLOW:
      "tasks_allTasks_wfTasksTable_click_expandWorkflow",
    TASKS_MY_TASKS_WF_TASKS_TABLE_CLICK_OPEN_STEP:
      "tasks_myTasks_wfTasksTable_click_openStep",
    TASKS_MY_GROUPS_WF_TASKS_TABLE_CLICK_OPEN_STEP:
      "tasks_myGroupTasks_wfTasksTable_click_openStep",
    TASKS_ALL_TASKS_WF_TASKS_TABLE_CLICK_OPEN_STEP:
      "tasks_allTasks_wfTasksTable_click_openStep"
  },

  dashboardEvernts: {
    DASHBOARDS_LEFTPANE_CLICK_OPEN_DASHBOARD:
      "dashboards_leftPane_click_openDashboard",
    DASHBOARDS_LEFTPANE_CLICK_CREATE_DASHBOARD:
      "dashboards_leftPane_click_createDashboard",
    DASHBOARD_CLICK_SELECT_DASHBOARD: "dashboard_click_selectDashboard",
    DASHBOARD_CLICK_MOVE_DASHBOARD: "dashboard_click_moveDashboard",
    DASHBOARD_CLICK_DASHBOARD_JUMP: "dashboard__click_dashboardJump",
    DASHBOARD_CLICK_ADD_DASHBOARD: "dashboard_click_addDashboard",
    DASHBOARD_CLICK_REPORT_JUMP: "dashboard_click_reportJump",
    DASHBOARD_CLICK_ADD_REPORT: "dashboard_click_addReport",
    DASHBOARD_CLICK_DASHBOARD_ACTIONS: "dashboard_click_dashboardActions",
    DASHBOARD_REPORT_CLICK_WF: "dashboard_report_click_wf",
    DASHBOARD_REPORT_CLICK_WFACTIONS: "dashboard_report_click_wfActions",
    DASHBOARD_REPORT_CLICK_REPORT_ACTIONS:
      "dashboard_report_click_reportActions",
    DASHBOARD_REPORT_CLICK_FULLVIEW: "dashboard_report_click_fullView",
    DASHBOARD_REPORT_CLICK_FILTER: "dashboard_report_click_filter",
    DASHBOARD_REPORT_CLICK_SORT: "dashboard_report_click_sort",
    DASHBOARD_REPORT_CLICK_REFRESH: "dashboard_report_click_refresh",
    DASHBOARD_REPORT_CLICK_COMMENT: "dashboard_report_click_comment",

    DASHBOARD_ACTIONS_CLICK_EDIT_DASHBOARD:
      "dashboard_dashboardActionsDropdown_click_editDashboard",
    DASHBOARD_ACTIONS_CLICK_ACTIVITY_LOGS:
      "dashboard_dashboardActionsDropdown_activityLog",
    DASHBOARD_ACTIONS_CLICK_CHANGE_ARRAGEMENTS:
      "dashboard_dashboardActionsDropdown_click_changeArrangements",
    DASHBOARD_ACTIONS_CLICK_DELETE_DASHBOARD:
      "dashboard_dashboardActionsDropdown_click_deleteDashboard",
    DASHBOARD_ACTIONS_CLICK_EXPORT_AS:
      "dashboard_dashboardActionsDropdown_click_exportAs",
    DASHBOARD_ACTIONS_CLICK_DOWNLOAD_EXPORT:
      "dashboard_exportDashboardModal_click_download",

    DASHBOARD_REPORT_ACTION_CLICK_EDIT_REPORT:
      "dashboard_reportActionsDropdown_click_editReport",
    DASHBOARD_REPORT_ACTION_CLICK_MANAGE_SUBSCRIPTION:
      "dashboard_reportActionsDropdown_click_manageSubscriptions",
    DASHBOARD_REPORT_ACTION_CLICK_ACTIVITY_LOG:
      "dashboard_reportActionsDropdown_click_activityLog",
    DASHBOARD_REPORT_ACTION_CLICK_DELETE_REPORT:
      "dashboard_reportActionsDropdown_click_deleteReport",
    DASHBOARD_REPORT_ACTION_CLICK_EXPORT_TO_CSV:
      "dashboard_reportActionsDropdown_click_exportToCSV",
    DASHBOARD_REPORT_ACTION_CLICK_EXPORT_AS:
      "dashboard_reportActionsDropdown_click_exportAs",

    DASHBOARD_MANAGE_SUBSCRIPTIONS_CLICK_CREATE_SUBSCRIPTIONS:
      "dashboard_manageSubscriptionsModal_click_createSubscriptions",
    DASHBOARD_SUBSCRIBE_VIA_EMAIL_CLICK_SUBSCRIBE:
      "dashboard_subscribeViaEmailModal_click_Subscribe",
    DASHBOARD_SUBSCRIBE_VIA_SFTP_CLICK_SUBSCRIBE:
      "dashboard_subscribeViaSFTPModal_click_Subscribe"
  },

  reportEvents: {
    REPORTS_GEOMAP_PRECISION_CHANGE: "reports_geomap_precision_change",
    REPORTS_GEOMAP_SCALE_CHANGE: "reports_geomap_scale_change",

    ADD_REPORT_CLICK_VISUALISATION: "addReport_click_visulisationTab",
    ADD_REPORT_CLICK_DATA_TAB: "addReport_click_dataTab",
    ADD_REPORT_CLICK_VISUALISATION_TYPE: "addReport_click_visualisatoinType",
    ADD_REPORT_CLICK_CHART_TYPE: "addReport_click_chartType",
    ADD_REPORT_ONSAVE_CHECK_CONTAINS: "addReport_contains",
    ADD_REPORT_CLICK_SAVE_REPORT: "addReport_click_saveReport"
  },

  headerEvents: {
    TAB_CLICK: "header_tab_click",
    HEADER_CLICK_CREATE_NEW_DROPDOWN: "header_click_createNewDropdown",
    HEADER_CLICK_MENTIONS: "header_click_mentions",
    HEADER_CLICK_HELP_DROPDOWN: "header_click_helpDropdown",
    HEADER_CLICK_LANGUAGE_DROPDOWN: "header_click_languageDropdown",
    HEADER_CLICK_USERPROFILE_DROPDOWN: "header_click_userProfileDropdown"
  },

  navbar: {
    NAV_CLICK_SEARCH: "nav_click_search",
    NAV_CLICK_HOME: "nav_click_home",
    NAV_CLICK_DASHBOARDS: "nav_click_dashboards",
    NAV_CLICK_TASKS: "nav_click_tasks",
    NAV_CLICK_NOTIFICATIONS: "nav_click_notifications",
    NAV_CLICK_STUDIO: "nav_click_studio",
    NAV_CLICK_TABLEAU: "nav_click_tableau",
    NAV_CLICK_PROFILE: "nav_profile_click",
    NAV_PROFILE_CLICK_MYPROFILE: "nav_profile_click_viewMyProfile",
    NAV_PROFILE_CLICK_SETTINGS: "nav_profile_click_settings",
    NAV_PROFILE_CLICK_SCHEDULED_REPORTS:
      "nav_profile_click_viewMyScheduledReports",
    NAV_PROFILE_CLICK_INSTANCE_SETTINGS:
      "nav_profile_click_viewInstanceSettings",
    NAV_PROFILE_LANGUAGE_CLICK_SWITCH_LANGUAGE:
      "nav_profile_language_click_switchLanguage",
    NAV_PROFILE_VIEW_AS_CLICK_EXTERNAL_USER:
      "nav_profile_viewAs_click_viewAsExternalUser",
    NAV_PROFILE_VIEW_AS_CLICK_CERTA_USER:
      "nav_profile_viewAs_click_viewAsCertaUser",
    NAV_PROFILE_HELP_CLICK_SUPPORT_WORKFLOW:
      "nav_profile_help_click_startCertaSupportWorkflow",
    NAV_PROFILE_HELP_CLICK_OPEN_USER_HELP_LINK:
      "nav_profile_help_click_openUserHelpLink"
  },

  notificationEvents: {
    NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_COMMENT_NOTIFICATION:
      "notifications_leftPane_allNotifications_click_openCommentNotification",
    NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_SUBSCRIBED_THREAD:
      "notifications_leftPane_allNotifications_click_openSubscribedThread",
    NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_TOGGLE_UNREAD_OFF:
      "notifications_leftPane_allNotifications_click_toggleUnreadOff",
    NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_TOGGLE_UNREAD_ON:
      "notifications_leftPane_allNotifications_click_toggleUnreadOn",
    NOTIFICATIONS_HEADER_CLICK_MY_SUBSCRIPTIONS:
      "notifications_header_click_mySubscriptions",
    NOTIFICATIONS_HEADER_CLICK_ALL_NOTIFICATIONS:
      "notifications_header_click_allNotifications"
  },

  homepageEvents: {
    HOME_CLICK_STARTWORKFLOW: "home_click_startWorkflow",
    HOME_CLICK_BROWSE_ALL_WORKFLOWS: "home_click_browseAllWorkflows",
    HOME_BROWSE_ALL_CLICK_START_WORKFLOW: "home_browseAll_click_startWorkflow",
    HOME_CLICK_SEARCH: "home_click_search",
    HOME_CREATE_WORKFLOW_CLICK: "home_createWorkflow_click_<button>",
    HOME_RECENTLY_VIEWED_CLICK: "home_recentlyViewed_click_<workflow><button>",
    HOME_CLICK_FILTER: "home_click_:filterKey",
    HOME_FILTER_CLICK_WORKFLOW: "home_:filterKey_click_workflow",
    HOME_FILTER_CLICK_WORKFLOW_ACTION:
      "home_:filterKey_workflow_click_:actionName",
    HOME_CLICK_FOLLOWING: "home_following_click_<workflow><workflow><button>",
    HOME_CLICK_RECENTLY_VIEWED:
      "home_click_waitingOnYouhome_click_requestedByYou",
    HOME_CLICK_WAITINGONYOU: "home_click_waitingOnYouhome_click_requestedByYou",
    HOME_CLICK_REQUESTEDBYYOU: "home_click_requestedByYou",
    HOME_CLICK_COMMENT: "home_click_comment",
    HOME_CLICK_WFACTIONS: "home_click_WFActions",
    HOME_CLICK_MORE_ACTIONS: "search_click_moreResults",
    HOME_CLICK_RECENT_SEARCHES: "search_click_recentSearches",
    HOME_CLICK_SEARCH_RESULTS: "search_click_searchResults",
    HOME_CLICK_GLOBAL_SEARCH: "home_click_globalSearch",
    HOME_SEARCH_KEYWORD: "search_keyword",
    HOME_SEARCH_RETURN_KEY: "search_keypress_returnKey",
    HOME_SEARCH_CATEGORY_CLICK: "search_click_category",
    HOME_SEARCH_TOOLTIP_SHOWN: "search_tooltipShown"
  },

  loginPageEvents: {
    CERTA_TEAM_LOGIN: "login_click_LoginWithCertaTeamButton",
    LOGIN_WITH_EMAIL_AND_PASSWORD:
      "login_click_loginUsingEmailAndPasswordButton",
    LOGIN_EMAIL_PASSWORD_SUBMIT: "login_click_loginButton",
    REQUEST_OTP: "login_click_requestOTPButton",
    OTP_SENT: "login_otpsent_requestOTPButton",
    RESEND_OTP: "login_click_resendOTPButton",
    OTP_SUBMITTED: "login_OTPsubmitted_loginButton",
    OTP_VERIFIED: "login_OTPverified_loginButton",
    LOGIN_SUCCESSFUL: "login_successful_loginButton",
    LOGOUT: "logOut_logout_logoutButton",
    LOGIN_CLICK_CHANGE_LANGUAGE_DROPDOWN: "login_click_changeLanguageDropdown",
    LOGIN_CHANGE_LANGUAGE_DROPDOWN:
      "login_changeLanguageDropdown_click_language"
  },

  heyCertaEvents: {
    HEY_CERTA_CLICKED: "studio_click_heyCerta",
    PROMPT_SUCCESS: "studio_promptSuccessful_heyCerta",
    PROMPT_SKIPPED: "studio_promptSkipped_heyCerta",
    PROMPT_FAILED: "studio_promptFailed_heyCerta",
    PROMPT_FOLLOW_UP: "studio_promptFollowUp_heyCerta",
    PROMPT_ACTION_REQUIRED: "studio_promptActionRequired_heyCerta",
    CHOSEN_FOLLOW_UP: "studio_chosenFollowUp_heyCerta",
    USER_PROMPT_FEEDBACK: "studio_promptFeedback_heyCerta"
  },

  designAIEvents: {
    // For tracking Design AI impressions
    /**
     * - is_opened: boolean
     */
    DESIGNAI_OPEN_CLOSE: "designai_openClose",

    // For tracking slash commands usage
    /**
     * - slash_command_id: string
     * - selected_component_type?: "Swimlane" | "Step" | "Field"
     */
    DESIGNAI_SLASH_COMMAND_RUN: "designai_slashCommand_run",
    /**
     * - opened_from: "landing_page" | "keyboard_slash"
     */
    DESIGNAI_SLASH_COMMAND_DROPDOWN_OPEN: "designai_slashCommand_dropdown_open",
    /**
     * - slash_command_id: string
     */
    DESIGNAI_SLASH_COMMAND_VIEW_EXAMPLE_CLICK:
      "designai_slashCommand_viewExample_click",

    // For tracking landing page suggestion usage
    /**
     * - suggestion_id: string
     * - selected_component_type?: "Swimlane" | "Step" | "Field"
     */
    DESIGNAI_LANDING_PAGE_SUGGESTION_RUN: "designai_landingPage_suggestion_run",

    // For tracking Design AI user guide navigations
    DESIGNAI_USER_GUIDE_CLICK: "designai_userGuide_click",

    // For tracking how many times incontext mode is being disabled
    DESIGNAI_INCONTEXT_DISABLE: "designai_inContext_disable",

    // For tracking general feedback impressions and what flow led them their
    /**
     * - is_opened: boolean
     */
    DESIGNAI_FEEDBACK_GENERAL_CLICK: "designai_feedbackGeneral_click",
    /**
     * - rating: number (1-5)
     * - usability?: "easy" | "moderate" | "difficult"
     * - velocity?: "improved" | "no_impact" | "reduced"
     * - is_useful?: boolean
     * - feedback?: string
     */
    DESIGNAI_FEEDBACK_GENERAL_FORM_SUBMIT:
      "designai_feedbackGeneral_form_submit",

    // For tracking interaction feedback usage
    /**
     * - like_or_dislike: "like" | "dislike"
     */
    DESIGNAI_FEEDBACK_INTERACTION_CLICK: "designai_feedbackInteraction_click",
    /**
     * - feedback: string
     */
    DESIGNAI_FEEDBACK_INTERACTION_FORM_SUBMIT:
      "designai_feedbackInteraction_form_submit",

    // For tracking task execution results
    /**
     * - user_prompt: string
     * - selected_component_type: null | "Swimlane" | "Step" | "Field"
     * - is_junior_ca: boolean
     */
    DESIGNAI_USER_PROMPT_SEND: "designai_userPrompt_send",
    /**
     * - has_changes: boolean
     * - is_task_breakdown_ui_shown: boolean
     */
    DESIGNAI_TASK_SUCCESS: "designai_task_success",
    /**
     * - failed_sub_prompt: string
     * - failed_intent: string
     * - failure_reason: string
     * - is_failure_user_triggered: boolean
     * - is_task_breakdown_ui_shown: boolean
     */
    DESIGNAI_TASK_FAIL: "designai_task_fail",
    /**
     * - sub_prompt: string
     * - intent: string
     * - success_message: string
     * - has_changes: boolean
     */
    DESIGNAI_SUBTASK_SUCCESS: "designai_subtask_success",
    /**
     * - sub_prompt: string
     * - intent: string
     * - skip_reason: string
     */
    DESIGNAI_SUBTASK_SKIP: "designai_subtask_skip",

    // For tracking followups usage
    /**
     * - target_type: string
     * - question: string
     * - intent: string
     * - is_task_breakdown_ui_shown: boolean
     */
    DESIGNAI_FOLLOWUP_RAISE: "designai_followup_raise",
    /**
     * - target_type: string
     * - question: string
     * - followup_response: string
     * - intent: string
     * - is_task_breakdown_ui_shown: boolean
     */
    DESIGNAI_FOLLOWUP_RESPONSE: "designai_followup_response",

    // For tracking file followup usage
    /**
     * - sample_file_label: string
     */
    DESIGNAI_FOLLOWUP_FILE_SAMPLE_FILE_DOWNLOAD:
      "designai_followupFile_sampleFile_download",
    /**
     * - file_mime_type: string
     * - file_size_in_bytes: number
     */
    DESIGNAI_FOLLOWUP_FILE_UPLOAD: "designai_followupFile_upload",

    // For tracking action buttons usage
    DESIGNAI_SKIP_FOLLOWUP_CLICK: "designai_actionButton_skipFollowup_click",
    DESIGNAI_CANCEL_EXECUTION_CLICK:
      "designai_actionButton_cancelExecution_click",
    DESIGNAI_UNDO_CHANGES_CLICK: "designai_actionButton_undoChanges_click",

    // For tracking Junior CA process indexing usage
    DESIGNAI_JUNIOR_CA_PROCESS_INDEXING_CLICK:
      "designai_juniorCA_processIndexing_click"
  },

  studioEvents: {
    STUDIO_CLICK: "studio_click_general",
    STUDIO_CLICK_DRAWER: "studio_click_drawer",

    // Left Toolbar Events
    STUDIO_LEFT_MENU_CLICK: "studio_click_leftMenu",
    STUDIO_CLICK_LEFT_TOOLBAR: "studio_click_leftToolbar",
    STUIO_CLICK_TEMPLATE_TAB: "studio_click_templateType",
    STUDIO_TEMPLATE_ITEM_CLICK: "studio_click_templateItem",
    STUDIO_ADDFIELD_INPUT_SEARCH: "studio_click_addFieldInputSearch",
    // Process Editor Header events
    STUDIO_HEADER_APP_ICON_CLICK: "studio_click_processEditorSubheaderAppIcon",
    STUDIO_HEADER_SEARCH_ICON_CLICK:
      "studio_click_processEditorSubheaderSearchIcon",
    STUDIO_HEADER_PROCESS_SETTINGS_DROPDOWN_CLICK:
      "studio_click_processEditorSubheaderProcessSettingsDropdown",
    STUDIO_HEADER_PROCESS_SETTINGS_DROPDOWN_ITEM_CLICK:
      "studio_click_processEditorSubheaderProcessSettingsDropdownItem"
  },

  studioWebGLEvents: {
    STUDIO_WEBGL_CLICK_NAVBAR_STUDIO_LOGO: "studiowebgl_click_studiowebglIcon",
    STUDIO_WEBGL_CLICK_NAVBAR_SEARCH: "studiowebgl_click_navbarSearch",
    STUDIO_WEBGL_SEARCH_NAVBAR_SEARCH: "studiowebgl_search_navbarSearch",
    STUDIO_WEBGL_CLICK_SEARCH_MENU_LIST: "studiowebgl_click_searchMenuList",
    STUDIO_WEBGL_CLICK_NAVBAR_SUBMIT:
      "studiowebgl_navbarClick_submitChangesButton",
    STUDIO_WEBGL_CLICK_NAVBAR_EDIT: "studiowebgl_navbarClick_editButton",
    STUDIO_WEBGL_CLICK_NAVBAR_DROPDOWN: "studiowebgl_click_navbarDropdown"
  },

  studioAppDetailsEvents: {
    STUDIO_CLICK_DEPLOY_BUTTON_ON_APP_DETAILS:
      "Studio_click_deployButtonOnAppDetails",
    STUDIO_CLICK_DEPLOY_BUTTON_IN_MODAL: "Studio_click_deployButtonInModal",
    STUDIO_CLICK_FORCE_UNLOCK: "Studio_click_forceUnlock",
    STUDIO_CLICK_EDIT_BUTTON: "Studio_click_editButton",
    STUDIO_CLICK_SUBMIT_BUTTON: "Studio_click_submitChangesButton"
  },

  studioAppListEvents: {
    STUDIO_APP_LIST_CREATE_APP_SAVE_CLICK: "studio_click_createAppModal",
    STUDIO_APP_LIST_EDIT_APP_SAVE_CLICK: "studio_click_editApp",
    STUDIO_APP_LIST_CANCEL_CLICK: "studio_click_cancelButton",
    STUDIO_APP_LIST_APP_CLICK: "studio_click_app",
    STUDIO_APP_LIST_CREATE_APP_BUTTON_CLICK: "studio_click_createAppButton",
    STUDIO_APP_LIST_EDIT_APP_BUTTON_CLICK: "studio_click_createAppButton",
    STUDIO_APP_LIST_DROPDOWN_DELETE_APP_CLICK: "studio_click_dropdownDeleteApp",
    STUDIO_APP_LIST_DROPDOWN_EDIT_APP_CLICK: "studio_click_dropdownEditApp"
  },

  studioInstanceSettingsEvents: {
    STUDIO_INSTANCE_SETTINGS_HEADER_USER_PROFILE_CLICK:
      "header_userProfile_click_instanceSettings",
    STUDIO_INSTANCE_SETTINGS_CLICK_BRANDING: "instanceSettings_click_branding",
    STUDIO_INSTANCE_SETTINGS_CLICK_INTEGRATION_CONFIG:
      "instanceSettings_click_integrationConfig",
    STUDIO_INSTANCE_SETTINGS_CLICK_INTEGRATION_TOKENS:
      "instanceSettings_click_integrationTokens",
    STUDIO_INSTANCE_SETTINGS_CLICK_USERS: "instanceSettings_click_users",
    STUDIO_INSTANCE_SETTINGS_CLICK_GLOBAL_SETTINGS:
      "instanceSettings_click_globalSettings",
    STUDIO_INSTANCE_SETTINGS_CLICK_ACCESS: "instanceSettings_click_access",
    STUDIO_INSTANCE_SETTINGS_BRANDING_CLICK_APPLY:
      "instanceSettings_branding_click_apply",
    STUDIO__INSTANCE_SETTINGS_INTEGRATION_CONFIG_CLICK_ADD_CONFIG:
      "instanceSettings_integrationConfig_click_addconfig_add",
    STUDIO_INSTANCE_SETTINGS_INTEGRATION_CONFIG_CLICK_EDIT_ICON:
      "instanceSettingsintegrationConfig_click_editIcon_save",
    STUDIO_INSTANCE_SETTINGS_INTEGRATION_TOKEN_CLICK_ADD_TOKEN:
      "instanceSettings_integrationToken_click_addtoken_add",
    STUDIO_INSTANCE_SETTINGS_INTEGRATION_TOKEN_CLICK_EDIT_ICON:
      "instanceSettingsintegrationToken_click_editIcon_save",
    STUDIO_INSTANCE_SETTINGS_USERS_CLICK_BULK_DOWNLOAD:
      "instanceSettings_users_click_bulkDownload",
    STUDIO_INSTANCE_SETTINGS_USERS_CLICK_ADD_USERS:
      "instanceSettings_users_click_addUsers_add",
    STUDIO_INSTANCE_SETTINGS_USERS_CLICK_EDIT_ICON:
      "instanceSettings_users_click_editIcon_save",
    STUDIO_INSTANCE_SETTINGS_USERS_CLICK_BULK_IMPORT:
      "instanceSettings_users_click_bulkImport",
    STUDIO_INSTANCE_SETTINGS_USERS_BULK_IMPORT_CLICK_IMPORT:
      "instanceSettings_users_bulkImport_click_import",
    STUDIO_INSTANCE_SETTINGS_GLOBAL_SETTINGS_CLICK_SAVE:
      "instanceSettings_globlSettings_click_Save"
  },

  studioSafeguardPromptEvents: {
    STUDIO_SAFEGUARD_PROMPT_CLICK_CONFIRM:
      "studio_safeguardPrompt_click_confirm",
    STUDIO_SAFEGUARD_PROMPT_CLICK_CANCEL: "studio_safeguardPrompt_click_cancel"
  },

  searchPageEvents: {
    ADVANCE_SEARCH_TABLE_COLUMN_SELECTOR:
      "advance_search_table_column_selector",
    ADVANCE_SEARCH_KIND_FILTER: "advance_search_kind_filter",
    ADVANCE_SEARCH_CLICK_FILTER: "advanceSearch_click_filter",
    ADVANCE_SEARCH_CLICK_CTA: "advanceSearch_click_cta",
    GLOBAL_SEARCH_CLICK_ADVANCE_SEARCH: "search_click_advanceSearch",
    ADVANCE_SEARCH_SEARCH_BUTTON: "advanceSearch_search"
  }
} as const;

export const mixpanelStudioTriggerSources = {
  appDetails: "AppDetailsPage",
  appList: "AppList",
  infoBanner: "InfoBanner"
};
