import { css } from "emotion";
import type { FC } from "react";
import { Stack } from "@certa/blocks/thanos";
import { MentionHierarchy } from "./MentionHierarchy";
import {
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common";
import type { CommentData } from "../components/MentionsContext";
import { useMentionsContext } from "../components/MentionsContext";
import { useHistory } from "react-router-dom";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import {
  DASHBOARD_KIND_NAME,
  REPORT_KIND_NAME
} from "@certa/dashboards/src/components/constants";
import type { Subscription } from "@certa/types";
import { ButtonSizes, ButtonTypes, Button } from "@certa/catalyst";
import { BellCrossedLine } from "@certa/icons";

const getObjectIdAsPerType = (subscription: Subscription) => {
  const { type } = subscription;
  switch (type) {
    case "workflow":
      return subscription?.workflow?.id;
    case "integrationresult":
      return subscription?.integrationResult?.uid;
    case "step":
      return subscription?.step?.id;
    case "field":
      return subscription?.field?.id;
    default:
      return null;
  }
};

const SubscriptionItem: FC<{
  subscription: Subscription;
  onUnsubscribe: (item: Subscription) => void;
}> = ({ subscription, onUnsubscribe }) => {
  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(subscription?.workflow?.id);

  const { updateMode, updateCommentData } = useMentionsContext();

  const history = useHistory();

  const processName = subscription?.workflow?.name;
  const isDashboardMention = processName === DASHBOARD_KIND_NAME;
  const isReportMention = processName === REPORT_KIND_NAME;

  const handleOnSubscriptionClick = () => {
    if (processDetailsStatus === "loading") return;

    const objectId = getObjectIdAsPerType(subscription) || 0;
    const { type: objectType } = subscription;

    const location = history.location;
    const [, type, id] = location?.pathname?.split("/") || [];
    const subscriptionProcessId = subscription?.workflow?.id;
    const subscriptionStepGroupId = subscription?.stepGroup?.id;
    const stepId = subscription?.step?.id;
    const commentData: CommentData = {
      objectId,
      objectType,
      threadId: subscription?.id,
      processId: subscription?.workflow?.id,
      processName: subscription?.workflow?.name,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        subscriptionProcessId &&
        subscriptionProcessId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        history.push(
          `${getDashboardsRoute(
            subscriptionProcessId
          )}?redirectFromComments=true`
        );
        return;
      }
      if (isReportMention) {
        if (isReportMention) {
          history.push(
            `${getFullScreenReportRoute(
              subscriptionProcessId
            )}?redirectFromComments=true`
          );
          return;
        }
      }

      history.push(
        `${getProcessDetailRoute(
          subscriptionProcessId
        )}?group=${subscriptionStepGroupId}&step=${stepId}&redirectFromComments=true&redirectCommentData=${JSON.stringify(
          commentData
        )}`
      );
    }

    updateCommentData(commentData);

    // changing drawer mode to comments drawer
    updateMode("comments");
  };

  const onBellIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onUnsubscribe(subscription);
  };

  return (
    <Stack
      data-testid="subscription-list-item"
      key={`${subscription?.id}`}
      direction="horizontal"
      align="center"
      justify="space-between"
      gutter="s3 s4 s3 s4"
      className={css`
        background-color: var(--neutral-0);
        border: 1px solid var(--neutral-20);
        cursor: pointer;
        margin-top: var(--s4);
        border-radius: var(--s2);
        &:hover {
          background-color: var(--colors-brand-100);
        }
      `}
      onClick={handleOnSubscriptionClick}
    >
      <Stack direction="vertical" gap="s4">
        <MentionHierarchy
          workflowFamily={[]}
          processName={subscription?.workflow?.name}
          taskName={subscription?.step?.name || null}
          fieldName={subscription?.field?.name || null}
          taskGroupName={subscription?.stepGroup?.name || null}
          threadName={subscription?.name || null}
        />
      </Stack>
      <Button
        size={ButtonSizes.SMALL}
        type={ButtonTypes.ICON}
        rightIcon={<BellCrossedLine />}
        onClick={onBellIconClick}
      />
    </Stack>
  );
};

export { SubscriptionItem };
