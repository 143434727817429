import type { FunctionComponent, Props } from "react";
import React from "react";
import { Header } from "./Header";

export const withHeader =
  (Component: FunctionComponent) => (props: Props<Object>) => (
    <>
      <Header />
      <Component {...props} />
    </>
  );
