import { ClassNames } from "@emotion/core";
import type { FC } from "react";
import React, {
  useState,
  forwardRef,
  useMemo,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect
} from "react";
import type { IconProps } from "@certa/icons";
import { Dots } from "@certa/icons";
import { Text } from "./Typography/Text";
import { Dropdown } from "./Dropdown";
import { Menu } from "./Menu/Menu";
import { MenuItem } from "./Menu/MenuItem";
import { MenuDivider } from "./Menu/MenuDivider";
import type { StackProps } from "./Stack";
import { Stack } from "./Stack";
import type { ForwardRefComponent } from "framer-motion";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Button } from "../componentsTh/Button";
import type { CountBadgeProps } from "./Badges/CountBadge";
import { CountBadge } from "./Badges/CountBadge";
import { VirtualizedSearchDropdown } from "@certa/common";

const MotionStack = motion(Stack);

type OptionValue = string;

export type Option = {
  label?: string | React.ReactNode;
  icon?: React.ComponentType<IconProps>;
  iconProps?: IconProps;
  countBadgeProps?: CountBadgeProps;
  value: OptionValue;
  disabled?: boolean;
  enabled?: boolean;
};

const DEFAULT_RESPONSIVE_TAB_COUNT = 4;
const TAB_CHANGE_ALLOWANCE = 200; // max tab width is 200px
type TabCount = number | false | "responsive";
export const ToggleTabsNew: FC<
  {
    options: Option[];
    // NOTE: Change reference only if options are updated,
    // else it will move selected to first option
    onChange?: (value: OptionValue) => void;
    max?: TabCount;
    value?: OptionValue;
    defaultValue?: OptionValue;
    disabled?: boolean;
    actionItem?: {
      label: string | React.ReactNode;
      onClick: () => void;
      icon?: FC<IconProps>;
    };
    growWithEqualWidths?: boolean;
    dropdownCmp?: React.ReactElement;
    stackProps?: StackProps;
    optionsClassName?: string;
    shouldRenderConditionSearchDropdown?: boolean;
    wrapperClassName?: string;
  } & Omit<StackProps, "onChange">
> = props => {
  const {
    options: defaultOptions,
    onChange,
    max = false,
    value,
    defaultValue,
    disabled,
    actionItem,
    growWithEqualWidths = false,
    optionsClassName,
    dropdownCmp,
    className,
    shouldRenderConditionSearchDropdown = false,
    wrapperClassName,
    ...stackProps
  } = props;

  const [maxTabsCount, setMaxTabsCount] = useState(
    DEFAULT_RESPONSIVE_TAB_COUNT
  );
  const [options, setOptions] = useState(defaultOptions);
  const [selection, setSelection] = useState<OptionValue>(defaultValue || "");

  const sliceEnd =
    max === false ? options.length : max === "responsive" ? maxTabsCount : max;
  const tabContainerRef = useRef<HTMLDivElement | null>(null);

  const firstClassOptions = useMemo(() => {
    return options.slice(0, sliceEnd);
  }, [options, sliceEnd]);

  const extraOptions = useMemo(
    () => options.slice(sliceEnd),
    [options, sliceEnd]
  );

  const makeActiveOptionVisible = useCallback(
    value => {
      let selectedOption: Option | undefined;

      let selectedOptionIndex = 0;

      const newOptions = defaultOptions.filter((option, index) => {
        if (option.value === value) {
          selectedOption = option;
          selectedOptionIndex = index;
          return false;
        }
        return true;
      });

      if (selectedOption) {
        const selectOptionNewIndex =
          selectedOptionIndex > firstClassOptions.length - 1
            ? firstClassOptions.length - 1
            : selectedOptionIndex;

        newOptions.splice(selectOptionNewIndex, 0, selectedOption);
      }

      setOptions(newOptions);
    },
    [defaultOptions, firstClassOptions]
  );
  const handleChange = useCallback(
    (value: OptionValue, shouldSortTabs: boolean = false) => {
      setSelection(value);
      onChange?.(value);
      if (shouldSortTabs) {
        makeActiveOptionVisible(value);
      }
    },
    [makeActiveOptionVisible, onChange]
  );

  // Update the internal value when the value from props changes
  useEffect(() => {
    // Using value !== undefined for the purpose of handling the case where the value is "" empty string
    // In such case none of the tabs will be marked as active.
    if (value !== undefined && selection !== value) {
      setSelection(value);
      makeActiveOptionVisible(value);
    }
  }, [defaultOptions.length, makeActiveOptionVisible, max, selection, value]);

  /**
   * For the case when options from outside change.
   * let's say for 2 different views then it should
   * again re arrange the new list to show the selected value
   */
  useEffect(() => {
    setSelection(value => {
      makeActiveOptionVisible(value);
      return value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions, firstClassOptions.length]);

  const calcResponsiveTabCount = useCallback(() => {
    if (max !== "responsive") {
      return;
    }
    const containerWidth = (tabContainerRef.current?.parentNode as HTMLElement)
      ?.offsetWidth;
    const tabsWidth = tabContainerRef.current?.offsetWidth;
    if (!containerWidth || !tabsWidth) {
      return;
    }
    if (containerWidth > tabsWidth + TAB_CHANGE_ALLOWANCE) {
      // underflow condition with 200px gap
      setMaxTabsCount(prevCount => {
        if (prevCount < options.length) {
          return prevCount + 1;
        }
        return prevCount;
      });
    } else if (containerWidth < tabsWidth) {
      // overflow condition
      setMaxTabsCount(prevCount => {
        if (prevCount > 1) {
          return prevCount - 1;
        }
        return prevCount;
      });
    }
  }, [max, options.length]);

  useLayoutEffect(() => {
    calcResponsiveTabCount();
  }, [calcResponsiveTabCount, maxTabsCount]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      calcResponsiveTabCount();
    });
    return () => window.removeEventListener("resize", () => {});
  }, [calcResponsiveTabCount]);

  useLayoutEffect(() => {
    calcResponsiveTabCount();
  }, [calcResponsiveTabCount, selection]);

  const renderDropdownForOtherOptions = (
    dropdownClass: string,
    dotsButtonClass: string
  ) => {
    if (dropdownCmp && shouldRenderConditionSearchDropdown) {
      return (
        <Stack align="center">
          <VirtualizedSearchDropdown
            dropdownCmp={dropdownCmp}
            items={extraOptions}
            onSelect={selectedValue => handleChange(selectedValue, true)}
          />
        </Stack>
      );
    } else if (max !== false) {
      return (
        <Dropdown
          overlay={
            <Menu style={{ maxHeight: 400, overflow: "auto" }}>
              {actionItem ? (
                <MenuItem
                  title={actionItem.label}
                  icon={actionItem.icon}
                  onClick={actionItem.onClick}
                />
              ) : null}
              {extraOptions.length && actionItem ? <MenuDivider /> : null}
              {extraOptions.map(option => (
                <MenuItem
                  key={option.value}
                  title={option.label}
                  disabled={option.disabled}
                  icon={option.icon}
                  onClick={() => handleChange(option.value, true)}
                />
              ))}
            </Menu>
          }
        >
          {isOpen =>
            dropdownCmp ? (
              <MotionStack align="center" className={dropdownClass}>
                {dropdownCmp}
              </MotionStack>
            ) : (
              <Button
                icon={<Dots />}
                isActive={isOpen}
                style={{
                  lineHeight: 1,
                  height: "auto",
                  fontSize: 16
                }}
                className={dotsButtonClass}
              />
            )
          }
        </Dropdown>
      );
    }
    return null;
  };

  return (
    <ClassNames>
      {({ css, cx }) => {
        const dropdownClass = css`
          cursor: pointer;
          padding: var(--s1) var(--s3);
          color: var(--neutral-70);
          border-radius: var(--small-border-radius);
          font-size: var(--p1);
          &:hover {
            color: var(--brand);
            background-color: var(--brand-35);
          }
        `;
        const dotsButtonClass = css`
          color: var(--neutral-70) !important;
          &:hover {
            color: var(--brand) !important;
          }
          &.button-is-active {
            color: var(--neutral-70) !important;
          }
          height: auto;
        `;
        return (
          <Stack
            {...stackProps}
            className={cx(
              css`
                overflow: ${max === "responsive" ? "hidden" : "static"};
                max-width: 100%;
                flex: 1;
              `,
              className
            )}
          >
            <Stack
              gap="s1"
              className={cx(
                css`
                  border-radius: 4px;
                  padding: 2px;
                  width: ${growWithEqualWidths ? "100%" : "auto"};
                `,
                wrapperClassName
              )}
              direction="horizontal"
              align="stretch"
              ref={tabContainerRef}
            >
              <AnimateSharedLayout>
                {firstClassOptions.map(option => {
                  const modifiedOption: Option = {
                    ...option,
                    disabled: disabled ? true : option.disabled
                  };
                  return (
                    <OptionCmp
                      isSelected={modifiedOption.value === selection}
                      option={modifiedOption}
                      onClick={handleChange}
                      key={modifiedOption.value}
                      max={max}
                      className={optionsClassName}
                      style={
                        growWithEqualWidths
                          ? { flexGrow: 1, justifyContent: "center" }
                          : undefined
                      }
                    />
                  );
                })}
              </AnimateSharedLayout>
              {extraOptions.length !== 0 &&
                renderDropdownForOtherOptions(dropdownClass, dotsButtonClass)}
            </Stack>
          </Stack>
        );
      }}
    </ClassNames>
  );
};

const OptionCmp: ForwardRefComponent<
  HTMLDivElement,
  {
    option: Option;
    isSelected?: boolean;
    onClick: (value: OptionValue) => void;
    style?: React.CSSProperties;
    max: TabCount;
    className?: string;
  }
> = forwardRef((props, ref) => {
  const { option, isSelected, onClick, style, max, className } = props;
  const {
    icon: Icon,
    countBadgeProps,
    disabled,
    iconProps,
    enabled = true
  } = option;

  if (!enabled) return null;
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Stack
          className={css`
            cursor: ${disabled ? "not-allowed" : "pointer"};
          `}
        >
          <MotionStack
            gap="s2"
            tabIndex={disabled ? -1 : 0}
            direction="horizontal"
            align="center"
            ref={ref}
            onClick={() => onClick(option.value)}
            data-testid="toggleBarOption" // NOTE: Ideally this shouldn't be used, when icons support aria-labels
            className={cx(
              css`
                padding: var(--s2);
                cursor: pointer;
                max-width: ${max === "responsive" &&
                TAB_CHANGE_ALLOWANCE + "px"};
                overflow: hidden;
                text-overflow: ellipsis;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                > svg {
                  width: 1em;
                  height: 1em;
                  font-size: 12px;
                }
                :focus-visible {
                  outline: 0;
                  box-shadow: 0 0 0 2px var(--brand-15) !important;
                }
                :hover {
                  .ant-badge-count {
                    background-color: ${isSelected
                      ? "var(--neutral-50)"
                      : "var(--brand)"};
                    color: ${isSelected
                      ? "var(--neutral-70)"
                      : "var(--neutral-0)"};
                    .ant-scroll-number-only > p.ant-scroll-number-only-unit {
                      color: ${isSelected
                        ? "var(--neutral-70)"
                        : "var(--neutral-0)"};
                    }
                  }
                }
                //styles when tab is disabled
                opacity: ${disabled ? 0.6 : 1};
                pointer-events: ${disabled ? "none" : "auto"};
              `,
              className
            )}
            initial={{
              color: "var(--neutral-70)",
              borderRadius: "6px"
            }}
            animate={
              isSelected
                ? {
                    backgroundColor: "var(--neutral-10)",
                    color: "var(--neutral-100)"
                  }
                : {
                    color: "var(--neutral-70)"
                  }
            }
            whileHover={{
              backgroundColor: isSelected
                ? "var(--neutral-10)"
                : "var(--brand-35)",
              color: isSelected
                ? "var(--neutral-100)"
                : "var(--brand) !important"
            }}
            transition={{
              duration: 0
            }}
            style={style}
            title={typeof option.label === "string" ? option.label : ""}
          >
            {Icon && <Icon {...iconProps} />}
            {option.label &&
              (React.isValidElement(option.label) ? (
                option.label
              ) : (
                <Text
                  as="span"
                  variant="p1-medium"
                  style={{
                    userSelect: "none",
                    whiteSpace: "nowrap"
                  }}
                >
                  {option.label}
                </Text>
              ))}
            {countBadgeProps && (
              <CountBadge
                {...countBadgeProps}
                className={css`
                  .ant-badge-count {
                    box-shadow: none;
                  }
                  * {
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  }
                `}
              />
            )}
          </MotionStack>
        </Stack>
      )}
    </ClassNames>
  );
});
