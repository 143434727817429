// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const BellCrossedLine = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="BellCrossedLine"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_14313_1042)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.2 1.45a.95.95 0 00-.95.939.653.653 0 01-.384.604A4.95 4.95 0 004.05 7.216V9.6a.65.65 0 01-.005.078 3.85 3.85 0 01-.618 1.672h.16l7.354-7.353a4.951 4.951 0 00-1.419-1.01.65.65 0 01-.372-.587.95.95 0 00-.95-.95zm3.66 1.628a6.253 6.253 0 00-1.449-1.096 2.25 2.25 0 00-4.422 0A6.25 6.25 0 002.75 7.2v2.359a2.55 2.55 0 01-1.27 1.875.65.65 0 00.32 1.216h.487l-.578.579a.646.646 0 10.913.913l1.492-1.492H5.15v.15a3.05 3.05 0 006.1 0v-.15h3.35a.65.65 0 00.32-1.216 2.55 2.55 0 01-1.27-1.875V7.2v-.03a6.25 6.25 0 00-.98-3.077l1.566-1.565a.646.646 0 00-.913-.913L11.86 3.078zm-.137 1.963l-6.31 6.309h7.56a3.85 3.85 0 01-.618-1.672.658.658 0 01-.005-.078V7.216a4.95 4.95 0 00-.627-2.175zM9.95 12.8v-.15h-3.5v.15a1.75 1.75 0 103.5 0z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_14313_1042">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default BellCrossedLine;
