import { css, cx } from "emotion";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { ChannelContext, useCommentsContext } from "../../../comments.context";
import type {
  DropdownStatusDefault,
  CommentChannel,
  AdjudicationStatus
} from "@certa/types";
import { CommentObjectTypes } from "@certa/types";
import { Divider, Text } from "@certa/blocks/thanos";
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Stack,
  Tooltip
} from "@certa/catalyst";
import { HierarchyBreadcrumbs } from "../HierarchyBreadcrumbs";
import { ThreadList } from "../ThreadList";
import { get as lodashGet } from "lodash-es";
import { ThreadContainer } from "../ThreadContainer";
import { StatusDropdownView } from "../StatusDropdownView";
import { Print, Plus } from "@certa/icons";
import { getEmotionCSSClasses } from "@certa/blocks";
import { useNotificationsNewUI } from "@certa/common";
import { threadContainerStyles } from "./SingleChannelView.styles";
import { ThreadListRedesigned } from "../ThreadListRedesigned";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

type ChannelViewProps = {
  channel: CommentChannel;
  defaultActiveThread: number;
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
};
/**
 * ChannelView is responsible for showing threads inside a particular
 * channel's context. This mainly houses the tab view for existing threads
 * as well as one to create a new thread.
 */

export const handleOnPrintComment = () => {
  setTimeout(() => {
    const container = document.querySelector(".single-channel-view");
    const printContents = container?.outerHTML;
    const docHead = document.querySelector("head")?.innerHTML;

    const emotionClasses = getEmotionCSSClasses();

    const body = `
        <!DOCTYPE html>
        <html>
          <head>
          ${docHead}
            <style>
              ${emotionClasses}
              [aria-label="ChevronLeft"],[aria-label="Close"] {
                display : none;
              }
              body {
                height: auto;
                overflow-y: auto !important;
              }
              .comments-new {
                height: auto !important;
                overflow-y: auto;
              }
            </style>
          </head>
          <body>${printContents}</body>
        </html>
      `;

    const printWindow = window.open();
    printWindow?.document.write(body);
    printWindow?.document.close();
    printWindow?.focus();

    printWindow?.document.addEventListener("DOMContentLoaded", () => {
      printWindow?.print();
      setTimeout(() => {
        printWindow.close();
      }, 500);
    });
  }, 500);
};

const SingleChannelView: React.FC<ChannelViewProps> = props => {
  const shouldShowNewCommentsUX = useNotificationsNewUI();
  const [shouldShowNewThread, setShouldShowNewThread] = useState(false); // Toggle to display the NewThreadTab
  const [activeThread, setActiveThread] = useState(props.defaultActiveThread); // To control the active thread

  useEffect(
    () => setActiveThread(props.defaultActiveThread),
    [props.defaultActiveThread]
  );

  const intl = useIntl();

  const { isReadOnly, header } = useCommentsContext();

  const { channel, adjudicationRiskCodes } = props;

  const { allThreads: threadsMeta, threads } = channel;

  const toggleNewThread = () => setShouldShowNewThread(!shouldShowNewThread);

  const handleThreadChange = (threadId: number) => {
    setActiveThread(threadId);
    setShouldShowNewThread(false);
  };

  /**
   * Kicks in when the thunk to create a new thread is finished and we need to
   * open the newly created thread tab.
   */
  const handleNewThread = (payload: any) => {
    // New thread is just created, handle it by making it
    // active tab and hiding the New Thread tab
    const activeKey = lodashGet(payload, "results.0.threads.0.id");

    if (activeKey) {
      setActiveThread(Number(activeKey));
      toggleNewThread();
    }
  };

  const handleOnPrint = () => {
    setTimeout(() => {
      const container = document.querySelector(".single-channel-view");
      const printContents = container?.outerHTML;
      const docHead = document.querySelector("head")?.innerHTML;

      const emotionClasses = getEmotionCSSClasses();

      const body = `
        <!DOCTYPE html>
        <html>
          <head>
          ${docHead}
            <style>
              ${emotionClasses}
              [aria-label="ChevronLeft"],[aria-label="Close"] {
                display : none;
              }
              body {
                height: auto;
                overflow-y: auto !important;
              }
              .comments-new {
                height: auto !important;
                overflow-y: auto;
              }
            </style>
          </head>
          <body>${printContents}</body>
        </html>
      `;

      const printWindow = window.open();
      printWindow?.document.write(body);
      printWindow?.document.close();
      printWindow?.focus();

      printWindow?.document.addEventListener("DOMContentLoaded", () => {
        printWindow?.print();
        setTimeout(() => {
          printWindow.close();
        }, 500);
      });
    }, 500);
  };

  /**
   * We only display breadcrumbs only when the object type of the current
   * channel is not a child-workflow, since they don't really  have a step path
   * associated with them.
   */
  const shouldRenderBreadcrumbs =
    channel.objectType !== CommentObjectTypes.WORKFLOW;

  /**
   * Since the useEffect takes time to update value so using the props directly e
   * meanwhile updated thread id is not saved
   */
  const activeThreadId = threads[activeThread]
    ? activeThread
    : props.defaultActiveThread;

  return (
    <ChannelContext.Provider value={channel}>
      {shouldShowNewCommentsUX ? (
        <Stack
          direction="vertical"
          dangerouslySetClassName={cx(
            "single-channel-view",
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
              overflow: auto;
              padding: 0;
            `
          )}
        >
          <StatusDropdownView
            threadId={activeThreadId}
            defaultStatus={props.defaultStatus}
            defaultAdjudicationCode={props.defaultAdjudicationCode}
            adjudicationRiskCodes={adjudicationRiskCodes}
          />
          {!shouldShowNewThread && (
            <div
              className={cx(
                "hide-print",
                css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "var(--space-8)",
                  padding: shouldShowNewCommentsUX
                    ? "var(--space-32) 0 var(--space-24) 0"
                    : "var(--space-8) 0",
                  margin: "0 var(--space-24)",
                  borderTop: shouldShowNewCommentsUX
                    ? "1px solid var(--colors-neutral-400)"
                    : "none"
                })
              )}
            >
              <ThreadListRedesigned
                channel={channel}
                selectedThreadId={activeThreadId}
                setActiveThread={handleThreadChange}
              />
              {!isReadOnly && (
                <Stack direction="vertical">
                  <Button
                    size={ButtonSizes.SMALL}
                    onClick={() => {
                      MixPanelActions.track(
                        MixPanelEvents.workflowDetailEvents
                          .COMMENT_DETAIL_PANE_CLICK_OPEN_ADD_GROUP_MODAL
                      );
                      setShouldShowNewThread(true);
                    }}
                    leftIcon={<Plus />}
                    minWidth="112px"
                  >
                    {intl.formatMessage({
                      id: "comments.addNewGroup",
                      defaultMessage: "Add Group"
                    })}
                  </Button>
                </Stack>
              )}
            </div>
          )}
          <div className={threadContainerStyles}>
            <ThreadContainer
              thread={threads[activeThreadId]}
              showNewThread={shouldShowNewThread}
              onThreadCancel={toggleNewThread}
              onThreadCreate={handleNewThread}
            />
          </div>
        </Stack>
      ) : (
        <Stack
          gap="12"
          direction="vertical"
          dangerouslySetClassName={cx(
            "single-channel-view",
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
              overflow: auto;
              padding: 0 var(--s1);
              padding-bottom: var(--s4);
            `
          )}
        >
          <Stack
            align="center"
            padding="4"
            direction="horizontal"
            justify={
              shouldRenderBreadcrumbs || header ? "space-between" : "flex-end"
            }
          >
            {shouldRenderBreadcrumbs ? (
              <HierarchyBreadcrumbs containerWidth="94%" />
            ) : header ? (
              <Text variant="h3-bold" color="black">
                {header}
              </Text>
            ) : null}
            <Tooltip
              content={intl.formatMessage({
                id: "stepBodyFormInstances.printText",
                defaultMessage: "Print"
              })}
              showArrow
            >
              <Button
                size={ButtonSizes.SMALL}
                type={ButtonTypes.ICON}
                rightIcon={<Print />}
                onClick={handleOnPrint}
                aria-label={intl.formatMessage({
                  id: "stepBodyFormInstances.printText",
                  defaultMessage: "Print"
                })}
              />
            </Tooltip>
          </Stack>
          <Divider />
          <StatusDropdownView
            threadId={activeThreadId}
            defaultStatus={props.defaultStatus}
            defaultAdjudicationCode={props.defaultAdjudicationCode}
            adjudicationRiskCodes={adjudicationRiskCodes}
          />
          <Stack
            gap="8"
            direction="horizontal"
            align="flex-start"
            dangerouslySetClassName="hide-print"
          >
            <ThreadList
              threadList={threadsMeta}
              selectedThreadId={activeThreadId}
              setActiveThread={handleThreadChange}
            />
            {!isReadOnly && (
              <Stack direction="vertical">
                <Button
                  size={ButtonSizes.SMALL}
                  onClick={() => setShouldShowNewThread(true)}
                  leftIcon={<Plus />}
                  minWidth="112px"
                >
                  {intl.formatMessage({
                    id: "comments.addNewGroup",
                    defaultMessage: "Add Group"
                  })}
                </Button>
              </Stack>
            )}
          </Stack>
          <ThreadContainer
            thread={threads[activeThreadId]}
            showNewThread={shouldShowNewThread}
            onThreadCancel={toggleNewThread}
            onThreadCreate={handleNewThread}
          />
        </Stack>
      )}
    </ChannelContext.Provider>
  );
};

export { SingleChannelView };
