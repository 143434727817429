import { useCallback, useRef } from "react";

import type { JsonEditor } from "jsoneditor-react";

/*
  When the resizable div changes in size, the contained JSONEditor component does not automatically resize itself.
  Instead, it relies on document resizing events. To ensure the JSONEditor adjusts promptly with the resizable div,
  we manually trigger the resize() method of the JSONEditor when the resizable div is resized. This keeps the JSONEditor
  dimensions in sync with the changes in the resizable div, providing a seamless user experience.
*/
export function useJSONEditorResizeObserver(
  ref: React.MutableRefObject<JsonEditor | null>
) {
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const handleParentResizeCallbackRef = useCallback(element => {
    if (element) {
      if (!resizeObserverRef.current) {
        resizeObserverRef.current = new ResizeObserver(() => {
          ref.current?.jsonEditor?.resize?.();
        });
        resizeObserverRef.current.observe(element);
      }
    } else {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleParentResizeCallbackRef };
}
