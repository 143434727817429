import { useEffect } from "react";
import { useSelector } from "react-redux";

import type { ReduxState } from "../../../modules/common/interfaces";
import { getTenant } from "../../../config";
import { MixPanelActions } from "../../_helpers/mixpanel";

export const useThirdPartyAnalytics = () => {
  const authentication = useSelector(
    (state: ReduxState) => state?.authentication
  );

  // Mixpanel Analytics
  useEffect(() => {
    if (
      authentication &&
      !authentication?.isLoading &&
      authentication?.user &&
      authentication?.user?.id &&
      authentication?.user?.email &&
      authentication.user?.csrf !== null
    ) {
      const user = authentication.user;
      const tenant = getTenant();
      const distinctId = `${tenant}@${user.id}`;

      MixPanelActions.identify(distinctId);
      MixPanelActions.register({
        id: user?.id,
        tenant
      });
    }
    if (
      authentication &&
      !authentication?.isLoading &&
      authentication.user?.csrf === null
    ) {
      MixPanelActions.reset();
    }
  }, [authentication]);
};
