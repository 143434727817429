/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { Stack, Text } from "@certa/blocks/thanos";
import { NoTasksIllustration } from "@certa/blocks";
import { useIntl } from "react-intl";

export const NoMentions: FC = () => {
  const intl = useIntl();
  return (
    <Stack
      css={css`
        border-radius: var(--big-border-radius);
        border: 1px solid var(--neutral-20);
        height: 100%;
        background-color: var(--neutral-5);
        margin-top: var(--s2);
      `}
      direction="vertical"
      align="center"
      justify="center"
    >
      <Stack
        direction="vertical"
        gap="s1"
        align="center"
        justify="center"
        css={css`
          width: 350px;
        `}
      >
        <NoTasksIllustration />
        <Stack>
          <Text variant="h3-bold" align="center">
            {intl.formatMessage({
              id: "mentions.noMentionsHeading",
              defaultMessage: "There are no mentions"
            })}
          </Text>
        </Stack>
        <Text variant="p1-regular" color="neutral-70" align="center">
          {intl.formatMessage({
            id: "mentions.noMentionsSubHeading",
            defaultMessage:
              "When people mention you in a message, you will see it here"
          })}
        </Text>
      </Stack>
    </Stack>
  );
};
