import { keyBy } from "lodash-es";
import {
  createCustomTag,
  WF_ID_DATA_PATH,
  createProcessValueTag,
  destructureCustomAttributeTag
} from "@certa/common";
import { queryClient } from "@certa/queries/src/utils/utils";
import type { KindStatus } from "../types/common.types";
import type {
  ReportWorkflowResultsORM,
  ReportWorkflowsExtraDataResponse,
  ReportWorkflowsORM
} from "../types/workflow.types";
import { getStatusesList } from "../services/common.services";
import type {
  FormattedLCData,
  WorkflowPlus,
  WorkflowReportORMDynamicAPIResponse
} from "../types/dynamicAPI.types";
import type { Alert, AttributesPayloadORM } from "@certa/types";

const DATA_POINT_INDEX = 1;

export const ormReportWorkflowsModelCreator = async (
  response: ReportWorkflowsORM | ReportWorkflowResultsORM[],
  ormColumns: AttributesPayloadORM[]
): Promise<ReportWorkflowsORM> => {
  const availableStatusesKeyedByTag = await getStatusesKeyedByTag();

  let dataPointsArray: ReportWorkflowResultsORM = [];
  let count = 0;
  let extraData: ReportWorkflowsExtraDataResponse = {
    data: {
      workflows: [],
      "+workflows": []
    }
  };
  if (Array.isArray(response)) {
    dataPointsArray = response.slice(DATA_POINT_INDEX);
  } else if (Array.isArray(response?.results)) {
    dataPointsArray = response.results.slice(DATA_POINT_INDEX);
    if (response?.extra_dynamic_data) {
      extraData = response.extra_dynamic_data;
    }
    count = response?.count;
  }

  const customFieldTags = ormColumns.map(column =>
    createCustomTag({
      kindId: column.kind_id,
      tag: column.tag,
      type: column.type,
      value: column.value
    })
  );

  const workflowFamily = workflowFamilyModelCreator(extraData?.data);
  return {
    count,
    results: dataPointsArray.map(dataPoints => {
      const rowRecord: ReportWorkflowResultsORM[number] = {};
      if (!Array.isArray(dataPoints)) {
        return rowRecord;
      }
      customFieldTags.forEach((customFieldTag: string, index: number) => {
        const { fieldTag } = destructureCustomAttributeTag(customFieldTag);
        if (
          fieldTag === createProcessValueTag("id") &&
          !ormColumns[index].join_relation
        ) {
          rowRecord.id = dataPoints?.[index];
          rowRecord.parents = rowRecord.id ? workflowFamily[rowRecord.id] : [];
          rowRecord[customFieldTag] = dataPoints?.[index];
        } else if (fieldTag === createProcessValueTag("status_tag")) {
          const { colorCode, id, displayName, tag } =
            availableStatusesKeyedByTag[dataPoints?.[index]] ?? {};
          rowRecord[customFieldTag] = {
            displayName,
            colorCode,
            id: id,
            tag: tag,
            [WF_ID_DATA_PATH]: customFieldTag.replace("status_tag", "id")
          };
        } else {
          rowRecord[customFieldTag] = dataPoints[index];
        }
      });
      return rowRecord;
    })
  };
};

const getStatusesKeyedByTag = async () => {
  let availableStatusesKeyedByTag: Record<string, KindStatus> | undefined =
    queryClient.getQueryData("availableStatusesKeyedByTag");
  if (!availableStatusesKeyedByTag) {
    const statusData = await getStatusesList();
    availableStatusesKeyedByTag = keyBy(statusData, "tag");
  }
  return availableStatusesKeyedByTag;
};

export const ormDynamicAPIDataModelCreator = (response: any) => {
  if (!response?.results) {
    return {};
  }
  const { results } = response as WorkflowReportORMDynamicAPIResponse;
  const {
    workflows = [],
    alert_mappings: alertMappings = [],
    alerts = [],
    alert_categories: alertCategories = []
  } = results ?? {};

  const alertMappingsMap = keyBy(alertMappings, "id");
  const alertsMap = keyBy(alerts, "id");
  const alertCategoriesMap = keyBy(alertCategories, "id");

  const rowRecord: Record<
    string,
    {
      alerts: Alert[];
      myTasksCount: number | undefined;
      progress: number | undefined;
      lcData: FormattedLCData[] | undefined;
      logo: string | undefined;
    }
  > = {};

  workflows.forEach(workflow => {
    rowRecord[workflow.id] = {
      alerts: (workflow.alert_mappings ?? []).map(alertId => {
        const alertMapping = alertMappingsMap[alertId];
        const alert = alertsMap[alertMapping.alert];
        const alertCategory = alertCategoriesMap[alert.category];
        return {
          id: alert.id,
          fieldId: alertMapping.field_id,
          stepId: alertMapping.step_id,
          stepGroupId: alertMapping.step_group_id,
          workflowId: alertMapping.workflow_id,
          catColorLabel: alertCategory.color_label,
          alertTag: alert.tag
        };
      }),
      lcData: workflow.formatted_lc_data,
      logo: workflow.logo,
      myTasksCount: workflow.my_tasks_count,
      progress: workflow.progress
    };
  });
  return rowRecord;
};

const PARENT_INDEX = 0;
const workflowFamilyModelCreator = (
  data: ReportWorkflowsExtraDataResponse["data"]
) => {
  const workflowFamilyMapping: Record<number, WorkflowPlus[]> = {};

  data.workflows.forEach(({ id, parents }) => {
    if (parents.length) {
      const parentsData = getParents(
        parents[PARENT_INDEX],
        data?.["+workflows"]
      );
      workflowFamilyMapping[id] = parentsData;
    } else {
      workflowFamilyMapping[id] = [];
    }
  });

  return workflowFamilyMapping;
};

const getParents = (
  workflowId: number,
  workflowPlus: WorkflowPlus[] = []
): WorkflowPlus[] => {
  const parent = workflowPlus.find(workflow => workflow.id === workflowId);
  if (parent?.parents?.length) {
    return [...getParents(parent.parents[PARENT_INDEX], workflowPlus), parent];
  } else if (parent?.id) return [parent];
  else {
    return [];
  }
};
