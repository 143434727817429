import React, { forwardRef } from "react";
import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst";
import { useHistory } from "react-router-dom";
import type { BreadcrumbLinkProps } from "../Breadcrumb.types";
import styles from "./BreadcrumbLink.module.css";

const BreadcrumbLink = forwardRef<HTMLLIElement, BreadcrumbLinkProps>(
  ({ link, maxWidth, onLinkClick }, ref) => {
    const history = useHistory();

    const handleClick = () => {
      history.push(link.url);
      onLinkClick?.();
    };

    return (
      <li
        ref={ref}
        className={styles.breadcrumbLinkWrapper}
        style={{ maxWidth }}
      >
        <button onClick={handleClick} className={styles.breadcrumbLink}>
          <Typography
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.LABEL_SM}
            as="span"
            className={styles.wrapper}
          >
            <Ellipsis>{link.label}</Ellipsis>
          </Typography>
        </button>
      </li>
    );
  }
);

export { BreadcrumbLink };
