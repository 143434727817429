import { css } from "emotion";
import type { FC } from "react";
import { Stack } from "@certa/blocks/thanos";
import { AnimatePresence, motion } from "framer-motion";
import { useSubscriptions } from "@certa/queries";
import InfiniteScroll from "react-infinite-scroller";
import type { Subscription } from "@certa/types";
import { SubscriptionItem } from "./SubscriptionItem";
import { Spinner } from "@certa/icons";
import { NoSubscriptions } from "./NoSubscriptions";
import {
  FullHeightSpinner,
  SomethingWentWrong,
  useRemoveSubscriptionOptUpdate
} from "@certa/common";
import { useIntl } from "react-intl";
import { showAlertDialog } from "@certa/catalyst";

const SubscriptionsList: FC = () => {
  const { status, data, hasNextPage, fetchNextPage } = useSubscriptions();

  const removeSubscription = useRemoveSubscriptionOptUpdate();

  const intl = useIntl();

  if (status === "idle" || status === "loading") return <FullHeightSpinner />;

  if (status === "error") return <SomethingWentWrong />;

  if (status === "success" && !data?.pages?.[0]?.results?.length)
    return <NoSubscriptions />;

  const onUnsubscribe = (subscription: Subscription) => {
    const onConfirmUnsubscribe = () => {
      const unsubscribingItemId = subscription?.id;
      if (unsubscribingItemId) {
        removeSubscription({
          enable: false,
          threadId: unsubscribingItemId
        });
      }
    };

    showAlertDialog({
      title: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.title",
        defaultMessage: "Unsubscribe from this thread?"
      }),
      children: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.content",
        defaultMessage:
          "You will not receive any notifications for future comments unless explicitly tagged"
      }),
      onPrimaryAction: onConfirmUnsubscribe,
      primaryActionText: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.okBtn",
        defaultMessage: "Unsubscribe"
      }),
      type: "error"
    });
  };

  return (
    <>
      <Stack
        direction="vertical"
        className={css`
          height: 100%;
          overflow-y: auto;
          margin-inline: var(--n-s6) !important;
        `}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage || false}
          useWindow={false}
          loader={
            <Stack
              align="center"
              justify="center"
              className={css`
                margin-top: var(--s5) !important;
              `}
            >
              <Spinner />
            </Stack>
          }
          threshold={250}
          className={css`
            margin: 0 var(--s6) !important;
          `}
        >
          <AnimatePresence>
            {data?.pages?.map(record =>
              record.results.map((subscription: Subscription) => (
                <motion.div
                  key={subscription?.id}
                  initial={{ x: 0 }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{
                    duration: 0.7,
                    ease: "easeInOut"
                  }}
                >
                  <SubscriptionItem
                    key={subscription?.id}
                    subscription={subscription}
                    onUnsubscribe={onUnsubscribe}
                  />
                </motion.div>
              ))
            )}
          </AnimatePresence>
        </InfiniteScroll>
      </Stack>
    </>
  );
};

export { SubscriptionsList };
