/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import React, { useRef, useEffect } from "react";

import {
  Avatar,
  getAvatarLetters,
  Paper,
  Stack,
  Text
} from "@certa/blocks/thanos";
import type { CommentMention } from "@certa/types";
import { format } from "date-fns";
import { useCommentMessageFormat } from "../../hooks/useCommentMessageFormat";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import { useNotificationsNewUI } from "@certa/common";
import {
  Typography,
  AvatarSizes,
  Avatar as CatalystAvatar,
  TypographyVariants,
  TypographyColors,
  AvatarColors
} from "@certa/catalyst";
import { AttachmentView } from "./AttachmentView";

type MessageProps = {
  postedBy: string;
  createdAt: string;
  attachment: string;
  message: string;
  postedById: number;
  messageId: number | null;
};

const Message: FC<
  MessageProps & { mentions: CommentMention[]; userId: number }
> = ({
  attachment,
  postedBy,
  createdAt,
  mentions,
  message,
  userId,
  postedById,
  messageId
}) => {
  const formattedMessage = useCommentMessageFormat(message, mentions, true);

  const postedAt = format(new Date(createdAt), "hh:mm a");

  if (postedById === userId) {
    return (
      <Stack
        id={String(messageId || "")}
        direction="vertical"
        gap="s2"
        align="flex-end"
        style={{ transition: "all 0.5s" }}
      >
        <Stack gap="s2" align="center" justify="flex-end">
          <Avatar size="small" alt={postedBy}>
            {getAvatarLetters(postedBy)}
          </Avatar>
          <Text variant="p1-bold" color="black">
            {postedBy}
          </Text>
          <Text variant="p1-regular" color="neutral-70">
            {postedAt}
          </Text>
        </Stack>
        <Stack
          justify="flex-end"
          css={css`
            width: 92%;
          `}
        >
          <Text
            color="neutral-70"
            variant="p1-regular"
            dangerouslySetInnerHTML={{
              __html: formattedMessage
            }}
            css={{ width: "max-content", wordBreak: "break-word" }}
          />
        </Stack>
        <AttachmentView attachment={attachment} isCurrentUser={true} />
      </Stack>
    );
  }

  return (
    <Stack
      gap="s2"
      id={String(messageId || "")}
      style={{ transition: "background 0.5s" }}
    >
      <Avatar
        size="small"
        alt={postedBy}
        backgroundColor={"#EAEAEA"}
        color={"#00164E"}
      >
        {getAvatarLetters(postedBy)}
      </Avatar>
      <Stack
        gap="s2"
        direction="vertical"
        css={css`
          width: 92%;
        `}
      >
        <Stack gap="s2">
          <Text variant="p1-bold" color="black">
            {postedBy}
          </Text>
          <Text variant="p1-regular" color="neutral-70">
            {postedAt}
          </Text>
        </Stack>
        <Text
          variant="p1-regular"
          color="neutral-70"
          dangerouslySetInnerHTML={{
            __html: formattedMessage
          }}
        />
        <AttachmentView attachment={attachment} isCurrentUser={false} />
      </Stack>
    </Stack>
  );
};

const NewMessage: FC<
  MessageProps & { mentions: CommentMention[]; userId: number }
> = ({
  attachment,
  postedBy,
  createdAt,
  mentions,
  message,
  userId,
  postedById,
  messageId
}) => {
  const formattedMessage = useCommentMessageFormat(message, mentions, true);

  const postedAt = format(new Date(createdAt), "hh:mm a");

  return (
    <div
      css={css({
        textAlign: postedById === userId ? "right" : "left"
      })}
    >
      {/* Title */}
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          marginBottom: "var(--space-12)"
        })}
      >
        <div
          css={css({
            display: "flex",
            alignItems: "center",
            gap: "var(--space-8)",
            marginLeft: postedById === userId ? "auto" : "unset"
          })}
        >
          <div>
            <CatalystAvatar
              size={AvatarSizes.SMALL}
              color={AvatarColors.DARK}
              aria-label={postedBy}
            >
              {getAvatarLetters(postedBy)}
            </CatalystAvatar>
          </div>
          <Typography
            className={css({ marginTop: "var(--space-2)" }).styles}
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.LABEL_SM_BOLD}
          >
            {postedBy}
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
          >
            {postedAt}
          </Typography>
        </div>
      </div>
      <div
        css={css({
          textAlign: postedById === userId ? "right" : "left",
          marginLeft: postedById === userId ? "0" : "var(--space-4)",
          overflowWrap: "break-word"
        })}
      >
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_700}
        >
          <div dangerouslySetInnerHTML={{ __html: formattedMessage }}></div>
        </Typography>
        <AttachmentView attachment={attachment} isCurrentUser={true} />
      </div>
    </div>
  );
};

const MessageViewNew: FC<{
  groupedMessages: { [date: string]: MessageProps[] };
  mentions: CommentMention[];
}> = ({ groupedMessages, mentions }) => {
  const containerRef = useRef(null);
  const shouldShowNewUI = useNotificationsNewUI();
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );

  const scrollToBottom = (container: HTMLElement) => {
    container.scrollTo(0, container.scrollHeight);
  };

  useEffect(() => {
    if (containerRef?.current) {
      scrollToBottom(containerRef.current);
    }
  }, [groupedMessages]);

  return shouldShowNewUI ? (
    <div
      css={css({
        maxHeight: "100%",
        overflowY: "auto",
        paddingRight: "var(--space-12)",
        marginBottom: "auto",
        borderTop: "1px solid var(--colors-neutral-400)"
      })}
      ref={containerRef}
    >
      {Object.entries(groupedMessages).map(([date, messages]) => (
        <div key={date}>
          <div
            css={css`
              padding: var(--space-24) 0;
              top: 0;
              position: sticky;
              background-color: var(--neutral-0);
              text-align: center;
            `}
          >
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
            >
              {date}
            </Typography>
          </div>

          <div>
            {messages.map((message, index) => (
              <div key={`${message.messageId || index}`}>
                <NewMessage
                  {...message}
                  mentions={mentions}
                  // @ts-expect-error - use useAppSelector from common package instead of useSelector
                  userId={userId}
                />
                <div
                  css={{
                    marginBlock: "var(--space-16)"
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Paper
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
      `}
      direction="vertical"
      gutter="s00 s4 s4 s4"
      ref={containerRef}
    >
      {Object.entries(groupedMessages).map(([date, messages]) => (
        // eslint-disable-next-line react/jsx-key
        <Stack direction="vertical">
          <Stack
            justify="center"
            align="center"
            css={css`
              margin: var(--s6) 0;
              top: 0;
              position: sticky;
              background-color: var(--neutral-0);
            `}
            gutter="s1 s00"
          >
            <Text variant="p2-bold-upper" color="neutral-70">
              {date}
            </Text>
          </Stack>

          <Stack direction="vertical" gap="s6">
            {messages.map((message, index) => (
              <Message
                {...message}
                mentions={mentions}
                // @ts-expect-error - use useAppSelector from common package instead of useSelector
                userId={userId}
                key={`${message.messageId || index}`}
              />
            ))}
          </Stack>
        </Stack>
      ))}
    </Paper>
  );
};

export { MessageViewNew };
