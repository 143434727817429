import type { FC, ReactNode } from "react";
import { useContext, createContext, useCallback, useEffect } from "react";
import { get as lodashGet, isEqual } from "lodash-es";
import { Drawer, DrawerHeader } from "@certa/catalyst";
import { useCommentsQuery, workflowServices } from "@certa/queries";
import {
  FullHeightSpinner,
  useNotificationsNewUI,
  useScreenResolution
} from "@certa/common";
import type {
  AdjudicationStatus,
  DropdownStatusDefault,
  WorkflowFamily
} from "@certa/types";
import { CommentObjectTypes } from "@certa/types";
import { queryClient } from "@certa/queries/src/utils/utils";
import { CommentsNew } from "./components/commentsNew/CommentsNew";
import { useGetCommentsHeadertitle } from "./hooks/useGetCommentsHeadertitle";
import { useIntl } from "react-intl";
import { DrawerTitle } from "./components/DrawerTitle/DrawerTitle";

export type TUseComments = {
  objectId: number | undefined;
  messageId?: string;
  objectType: CommentObjectTypes;
  onStepClick?: (groupId: number, stepId: number, workflowId: number) => void;
  uid?: string;
  extra?: any;
  readOnly?: boolean;
  threadId?: number;
  header: React.ReactChild;
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
  workflowFamily?: WorkflowFamily;
  multiChannelWithTabs?: boolean;
  isWorkflowBeingMigrated?: boolean;
};

export enum DrawerViewStates {
  NOT_OPENED = "not-opened",
  OPENED = "opened",
  CLOSED = "closed"
}

export type TCommentsDrawerContext = {
  commentsDrawerStates: DrawerViewStates;
  showComments: (data: TUseComments) => void;
  onClose: () => void;
  isWorkflowBeingMigrated?: boolean;
  parentThreadObject?: TUseComments | undefined;
};

export const CommentsDrawerContext = createContext<
  TCommentsDrawerContext | undefined
>(undefined);

/**
 * CommentsDrawerContextProvider is used to open comments drawer from mentions as well as
 * from comments anywhere in the app. This mainly ensures the comments data is passed to the
 * comments drawer and the drawer is opened accordingly.
 */

export const CommentsDrawerContextProvider: FC<{
  children: ReactNode;
}> = props => {
  const intl = useIntl();
  const shouldShowNewCommentxUX = useNotificationsNewUI();
  const {
    isMultiChannel,
    multiChannelWithTabs: isMultiChannelWithTabs,
    activeChannel,
    channels,
    commentsData,
    show: shouldShow,
    drawerStates,
    isWorkflowBeingMigrated,
    setIsMultiChannel,
    setMultiChannelWithTabs,
    setActiveChannel,
    setChannels,
    setCommentsData,
    setShow,
    setDrawerStates,
    setIsWorkflowBeingMigrated,
    onClose
  } = useGetCommentsHeadertitle();

  const {
    objectId,
    objectType,
    uid,
    header = "",
    adjudicationRiskCodes,
    defaultAdjudicationCode,
    defaultStatus,
    extra,
    messageId,
    readOnly: isReadOnly,
    threadId,
    workflowFamily,
    onStepClick
  } = commentsData || {};

  const { isMobileResolution } = useScreenResolution();

  const oldDrawerTitle = intl.formatMessage({
    id: "workflowsInstances.commentsText",
    defaultMessage: "Comments"
  });

  const {
    data,
    status,
    refetch: fetchComments
  } = useCommentsQuery({
    objectId: objectId || 0,
    objectType: objectType || CommentObjectTypes.STEP,
    uid,
    config: {
      cacheTime: 0, // To avoid the previous comments data shown in split second
      enabled: shouldShow && !!objectId && !!objectType
    }
  });

  // Makes sure comments are updated in multi channel view
  useEffect(() => {
    if (channels?.length && activeChannel) {
      const existingChannelIndex = channels?.findIndex(
        channel => channel?.objectId === activeChannel?.objectId
      );
      // Only runs when there is a diff between existing channel and incoming channel data
      if (
        existingChannelIndex > -1 &&
        !isEqual(activeChannel, channels[existingChannelIndex])
      ) {
        setActiveChannel(channels[existingChannelIndex]);
      }
    }
  }, [activeChannel, channels, setActiveChannel]);

  useEffect(() => {
    if (data?.results) {
      setChannels(data?.results);
    }
  }, [data?.results, setChannels]);

  useEffect(() => {
    if (!shouldShow && drawerStates === DrawerViewStates.CLOSED) {
      setDrawerStates(DrawerViewStates.NOT_OPENED);
    }
  }, [shouldShow, drawerStates, setDrawerStates]);

  /**
   * To open comments drawer in single channel mode
   */

  const showComments = useCallback(
    async (commentsData: TUseComments) => {
      if (!commentsData.objectId || !commentsData.objectType) {
        console.error("objectID or objectType must be specified");
        return;
      }

      setCommentsData(commentsData);
      fetchComments().then(({ data }) => {
        const response = lodashGet(data, "results[0]", {});
        const { objectId: id, objectType: type } = response;

        if (id !== commentsData.objectId || commentsData.objectType !== type) {
          const key = `comments-${type}-${commentsData.objectId}`;

          queryClient.removeQueries(key);
          queryClient.setQueryData(key, () => data);
        }
      });
      setShow(true);
      setDrawerStates(DrawerViewStates.OPENED);
      setIsMultiChannel(
        commentsData.objectType === CommentObjectTypes.CONSOLIDATED
      );
      setMultiChannelWithTabs(!!commentsData.multiChannelWithTabs);
      // Checks if the workflows are being migrated or not.
      // P.S: The data is being cached so no extra API calls!
      const { isWorkflowBeingMigrated } =
        await workflowServices.lazyFetchProcessDetails(commentsData.objectId);

      if (
        isWorkflowBeingMigrated !== undefined ||
        commentsData?.isWorkflowBeingMigrated !== undefined
      ) {
        setIsWorkflowBeingMigrated(
          !!commentsData?.isWorkflowBeingMigrated || !!isWorkflowBeingMigrated
        );
      }
    },
    [
      fetchComments,
      setCommentsData,
      setDrawerStates,
      setIsMultiChannel,
      setIsWorkflowBeingMigrated,
      setMultiChannelWithTabs,
      setShow
    ]
  );

  const activeFieldName =
    activeChannel?.fieldName ||
    channels?.find(channel => channel.objectId === commentsData?.objectId)
      ?.fieldName;

  return (
    <CommentsDrawerContext.Provider
      value={{
        commentsDrawerStates: drawerStates,
        showComments,
        onClose,
        isWorkflowBeingMigrated,
        parentThreadObject: commentsData
      }}
    >
      {props.children}
      <Drawer
        width={isMobileResolution ? "100%" : "720px"}
        title={shouldShowNewCommentxUX ? "" : oldDrawerTitle}
        aria-label={"Comments drawer"}
        show={shouldShow}
        padding={shouldShowNewCommentxUX ? "0" : undefined}
        onClose={onClose}
        showCloseIcon={shouldShowNewCommentxUX ? false : true}
        onBack={
          !shouldShowNewCommentxUX && isMultiChannel && activeChannel
            ? () => setActiveChannel(null)
            : undefined
        }
      >
        {shouldShowNewCommentxUX && status !== "loading" && (
          <DrawerHeader>
            <DrawerTitle
              isMultiChannel={!!isMultiChannel}
              channels={channels}
              workflowFamily={workflowFamily}
              fieldName={activeFieldName}
              activeChannel={activeChannel}
              setActiveChannel={setActiveChannel}
              onClose={onClose}
              showBackButton={isMultiChannel}
            />
          </DrawerHeader>
        )}
        {/* This extra loading state is for better UX */}
        {status === "loading" || channels === undefined ? (
          <FullHeightSpinner />
        ) : (
          <CommentsNew
            channels={channels}
            status={status}
            onStepClick={onStepClick}
            readOnly={!!isReadOnly}
            visible={shouldShow}
            multiChannel={isMultiChannel}
            extra={extra}
            defaultThreadId={threadId}
            header={header}
            onClose={onClose}
            activeChannel={activeChannel}
            setActiveChannel={setActiveChannel}
            messageId={messageId}
            defaultStatus={defaultStatus}
            defaultAdjudicationCode={defaultAdjudicationCode}
            adjudicationRiskCodes={adjudicationRiskCodes}
            workflowFamily={workflowFamily}
            multiChannelWithTabs={isMultiChannelWithTabs}
          />
        )}
      </Drawer>
    </CommentsDrawerContext.Provider>
  );
};

export const useCommentsDrawerContext = () => {
  const contextValue = useContext(CommentsDrawerContext);
  if (typeof contextValue === "undefined") {
    throw new Error("No value defined for the comments drawer context");
  }

  return contextValue;
};

/**
 * useCommentsDrawerClose is used to update the data when the comments drawer is closed.
 *
 * This has been added to support backward compatibility of useOverlay -> useOverlayOnClose.😢
 *
 * @param onClose
 * @returns
 */
export const useCommentsDrawerClose = (onClose: () => void) => {
  const { commentsDrawerStates } = useCommentsDrawerContext();

  useEffect(() => {
    if (commentsDrawerStates === DrawerViewStates.CLOSED) {
      onClose();
    }
  }, [commentsDrawerStates, onClose]);
};
