import type { FC } from "react";
import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import type { CheckboxProps as AntCheckboxProps } from "antd/lib/checkbox";
import { Text } from "./Typography/Text";
import styled from "@emotion/styled";
import type { TypographyVariants } from "./Typography/types";
import { getVariantStyles } from "./Typography/utils";

type CustomCheckboxProps = {
  /** Makes label text color greyish if checkbox state is uncheck */
  textMuted?: boolean;
  /** Label text variant */
  variants?: TypographyVariants;
  /** Negative action */
  danger?: boolean;
} & AntCheckboxProps;
export const CustomCheckbox: FC<CustomCheckboxProps> = props => {
  const {
    children,
    variants = "p1-regular",
    textMuted,
    disabled,
    danger,
    ...checkboxProps
  } = props;

  const getTextColor = () => {
    if (danger && !disabled) return "red";
    if (textMuted || disabled) return "neutral-70";

    return "neutral-100";
  };

  return (
    <AntCheckbox {...checkboxProps} disabled={disabled}>
      <Text
        className="checkbox-text-label"
        variant={variants}
        color={getTextColor()}
      >
        {children}
      </Text>
    </AntCheckbox>
  );
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Checkbox } from '@certa/catalyst';
 */
const Checkbox = styled(CustomCheckbox)`
  ${props => getVariantStyles(props.variants || "p1-regular")};
  .ant-checkbox {
    font-size: inherit;
  }
  .ant-checkbox-inner {
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.33em;
    border-width: 2px;
    border-color: ${props =>
      props.danger ? "var(--red)" : "var(--neutral-70)"};
  }
  .ant-checkbox-inner::after {
    content: none; // removes ant checkbox mark
  }
  .ant-checkbox-checked {
    & + span .checkbox-text-label {
      color: ${props =>
        props.textMuted && !props.danger && "var(--neutral-100)"};
    }
    .ant-checkbox-inner,
    &.ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${props =>
        props.danger ? "var(--red)" : "var(--brand)"};
      border-color: ${props => (props.danger ? "var(--red)" : "var(--brand)")};
    }
    &:after {
      content: none;
    }
    .ant-checkbox-inner::before {
      // creates custom checkbox mark
      content: "";
      background-image: ${props =>
        props.indeterminate
          ? `url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.199997' width='8' height='1.6' rx='0.8' fill='white'/%3E%3C/svg%3E")`
          : `url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.65181 7.0864L0.106363 4.3528C-0.0354544 4.2024 -0.0354544 3.9576 0.106363 3.8056L0.620543 3.2592C0.76236 3.1088 0.992905 3.1088 1.13472 3.2592L2.90926 5.1728L6.86488 0.912799C7.0067 0.762399 7.23724 0.762399 7.37906 0.912799L7.89324 1.46C8.03505 1.6104 8.03505 1.856 7.89324 2.0056L3.16599 7.0864C3.02417 7.2368 2.79362 7.2368 2.65181 7.0864Z' fill='white'/%3E%3C/svg%3E")`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.66em 0.66em;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &:hover .ant-checkbox-input:not(:disabled) + .ant-checkbox-inner,
  .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
    box-shadow:
      0 0 0 1px #fff,
      0 0 0 3px var(--brand-15) !important;
  }
  &.ant-checkbox-wrapper:hover
    .ant-checkbox:not(.ant-checkbox-checked)
    .ant-checkbox-inner,
  .ant-checkbox-input:focus:not(:checked) + .ant-checkbox-inner {
    border-color: ${props =>
      (props.danger && "var(--red)") ||
      (!props.indeterminate && "var(--neutral-70)")};
  }

  .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: var(--neutral-10) !important;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--neutral-50) !important;
  }
  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0; // left margin reset
  }
  --label: CustomCheckbox;
`;
const CheckboxGroup = AntCheckbox.Group;
export { Checkbox, CheckboxGroup };
