import type {
  HashMap,
  StepGroup,
  Alert,
  LCData,
  FieldTypes,
  WorkflowDateFilterType,
  WorkflowRelativeDateRange,
  ActionUser,
  SidebarAlert,
  FilterORM,
  FilterPayloadORM
} from "@certa/types";
import type { WorkflowLevelPermissionType } from "./permissions.types";
import type {
  FieldHierarchyTree,
  FieldMap,
  SLAAndSGLAMapping,
  StepGroupLevelAttribute,
  JSONAnswer,
  StepLevelAttributes,
  StepLevelAttributesExpanded
} from "@certa/common";
import type { WorkflowPlus } from "./dynamicAPI.types";

export type CreateWorkflowServiceParams = {
  kind: string;
  name: string; // default name that is given to the child workflow.
  // newly created WF
  // This maps certain values within the newly created child workflow
  // from the parent workflow's field that we put here, and that has
  // some child_workflow_mapping defined in it.
  child_mapping_field_tag?: string | null;
  child_mapping_tag?: string | null;
  field_data?: HashMap;
  signal?: AbortSignal;
} & (
  | {
      parent: number | null; // when null, no workflow is parent for this
      package_id?: never;
    }
  | {
      parent?: never;
      package_id: number | null;
    }
);

export type SearchUserWorkflowServiceParams = {
  email: string; // email associated with user's account
  kind?: string; // workflow's kind tag
};

export type UpdateChildWorkflowServiceParams = {
  id: number;
  kind: string;
  parents: number[];
  child_mapping_tag?: string;
  child_mapping_field_tag?: string;
};

export type CSVDataType1 = {
  display: string;
  source: string;
  type: "workflow" | "field_tag" | "alert";
};

export type CSVDataForSLASource =
  | Exclude<StepLevelAttributes, "completed_by" | "updated_at">
  | "completion_date"
  | "completed_by_email"
  | "completed_by_name";

export type CSVDataForSLA = {
  display: string;
  source: CSVDataForSLASource;
  type: SLAOrderingColType;
  tag: string;
};

export type CSVData = CSVDataType1 | CSVDataForSLA;

export type ReportExportParams = {
  report_id: number;
  filters: {
    kind_tag?: string;
    search?: string;
    region?: string;
    business_unit?: string;
    status?: number;
    answer?: string;
  };
  csv_data?: CSVData[];
};

export type UseExportReportORMMutation = {
  reportId: number;
  filters?: FilterORM;
  allFieldsExport?: boolean;
};

export type ReportExportORMParams = {
  reportId: number;
  filters?: FilterPayloadORM;
  allFieldsExport?: boolean;
};

export type StepGroupsMetaData = {
  taggedSteps: string[];
  defaultStepTags: string[];
  defaultStepTagsForGroup: string[];
  progress: number;
  todoStepGroups: string[];
  todoSteps: string[];
  results: StepGroup[];
};

export type StepGroupsData = {
  taggedSteps: string[];
  defaultStepTags: string[];
  defaultStepTagsForGroup: string[];
  todoStepGroups: string[];
  todoSteps: string[];
  progress: number;
  results: StepGroup[];
  myStepGroups: StepGroup[];
  myTaggedSteps: StepGroup[];
  userStepGroups: StepGroup[];
};

export type StepRaw = {
  id: number;
  name: string;
  tag: string;
  completed_by: number;
  overdue: boolean;
  deadline: string;
  is_locked: boolean;
  is_enabled: boolean;
  step_type: string;
  available_user_tags: string[];
  def_extra: {
    is_wizard: boolean;
  };
  alerts: SidebarAlert[];
};

export type WorkflowLCData = {
  displayType: string;
  hideFromWorkflow: boolean;
} & LCData;

export type Status = {
  id: number;
  displayName: string;
  colorCode: string;
};
export type WorkflowFamily = {
  name: string;
  id: number;
  lcData?: LCData[];
};

export type WorkflowDefinition = {
  name: string;
  kind: number;
};

export type WorkflowItem = {
  id: number;
  name: string;
  createdAt: string;
  sortingPrimaryField: null | number;
  lcData: Array<LCData>;
  workflowFamily: Array<WorkflowFamily>;
  alerts: Array<Alert>;
  status: Status;
  progress: number;
  rank: null | number;
  logo: null | string;
  isWorkflowBeingMigrated: boolean;
  definition?: WorkflowDefinition;
};

export type WorkflowList = {
  count: number;
  results: WorkflowItem[];
  next: string | null;
  previous: string | null;
};

export type CreateWorkflowResponse = {
  id: number;
  name: string;
  definition?: {
    kind?: number;
  };
  parents?: number[];
  // TODO: ADD more
};

export type TaskFamily = {
  id: number;
  name: string;
};

export type RawWorkflowFamily = {
  id: number;
  name: string;
  lc_data: LCData[];
};

export type TaskDetails = {
  name: string;
  status: string;
  statusColorCode: string;
  lcData: LCData[];
  family: TaskFamily[];
  definition: {
    defaultStepTags: string[];
    name: string;
    dynamicGroupNamesWithPerm: Record<string, any>; //TODO: Add type here
    kindId: number;
  };
  businessUnit: number | null;
  region: number | null;
  uid: string;
  id: number;
  workflowFamily: {
    id: number;
    name: string;
    lcData: LCData[];
  }[];
  logo: string | null;
  root: any;
  nodeOrder: any;
  defaultStepTags: any;
  createdAt: string;
  isWorkflowBeingMigrated?: boolean;
};

export type MigrationStats = {
  isAtdMigrationOngoing: boolean;
  kindsBeingMigrated: string[];
  kindsBlocked: string[];
  estimatedTimeOfCompletion?: number;
  errorCode?: MigrationErrorCodes;
  message?: string;
};
export type FilterByGroup = "true" | "false";

export type WorkflowFiltersQuery = {
  business_unit?: string;
  region?: string;
  q?: string;
  filter_by_group?: FilterByGroup;
  // answer have two defferent fortmat,
  // when filter_by_group is true, answer is => OR,[,query1,AND,[,query2,query3,],]
  // otherwise answer is => query1|query2|query3. Note | is used for AND not OR.
  answer?: string;
  json_answer?: JSONAnswer;
  status?: number;
  status__in?: string;
  region__in?: string;
  business_unit__in?: string;
  kind_id?: number; // TODO: Can this be made non-optional
  self_created?: boolean;
  incomplete_step_tags?: boolean;
  created_range_after?: string;
  created_range_before?: string;
  alert_category?: number;
  alerts_tag?: string;
  id__in?: string;
  date_filter_type?: WorkflowDateFilterType;
  relative_date_range?: WorkflowRelativeDateRange;
  definition_id__in?: number;
  kind_id__in?: string;
};

export enum FilterTypes {
  REGION = "region__in",
  BUSINESS_UNIT = "business_unit__in",
  STATUS = "status__in",
  START_DATE = "created_range_after",
  END_DATE = "created_range_before",
  QUERY = "q",
  KIND_ID = "kind_id",
  ANSWER = "answer",
  RESET = "filter_reset"
}

export type ReportWorkflowStatus = {
  id: number;
  displayName: string;
  colorCode: string;
};

export type ReportWorkflowField = {
  answer: string;
  body: string;
  field_type: FieldTypes;
  files: string[];
  attachment: string | null;
  is_encrypted: boolean;
  response_id: number;
  response_extra_json: Record<string, unknown>;
};

export type ReportWorkflowFamily = { id: number; name: string };

export type ReportWorkflowResult = {
  id: number;
  name: string;
  createdAt: string;
  createdBy: ActionUser;
  logo: string | null;
  updatedAt: string;
  lastActivity: string;
  myTaskCount: number;
  progress: number;
  status: ReportWorkflowStatus;
  alerts: Alert[];
  lcData: LCData[]; // TODO: Add type
  fields: Record<string, ReportWorkflowField>;
  workflowFamily: Array<ReportWorkflowFamily>;

  ageing: number | null;
  cycleTime?: number | null;
  cycleStart?: string | null;
  cycleEnd?: string | null;
  isCycleRunning?: boolean | null;

  definitionId: number | null;

  steps: ReportWorkflowsSteps;
  stepGroups: ReportWorkflowsStepGroups;
  processType: string;
};

export type ReportWorkflowResultsORM = Record<string, any>[];

export type ReportWorkflowsORM = {
  count: number;
  results: ReportWorkflowResultsORM;
  extra_dynamic_data?: ReportWorkflowsExtraDataResponse;
};

export type ReportWorkflowsExtraDataResponse = {
  data: {
    "+workflows"?: WorkflowPlus[];
    workflows: { id: number; parents: number[] }[];
  };
};

export type MigrationErrorCodes =
  | "MIGRATION_ESTIMATION_IN_PROGRESS"
  | "INTERNAL_ERROR_WHILE_FETCHING_ESTIMATED_TIME"
  | "BENCHMARKS_NOT_AVAILABLE_OR_NO_TASKS_RUNNING";

export type ReportWorkflowResults = Array<ReportWorkflowResult>;

export type ReportWorkflows = {
  count: number;
  results: ReportWorkflowResults;
};

export type ReportWorkflowsSteps = Record<string, ReportWorkflowsStep>;
export type ReportWorkflowsStep = {
  id: number;
  definition: {
    id: number;
    name: string;
    tag: string;
  };
  isLocked: boolean | null;
  isEnabled?: boolean | null;
  initiatedAt?: string | null;
  updatedAt?: string | null;
  completedAt?: string;
  completedBy?: ActionUser;
  ageing: number | null;
  cycleTime?: number | null;
  cycleStart?: string | null;
  cycleEnd?: string | null;
  isCycleRunning?: boolean | null;
  overdue?: boolean | null;
  submitCount?: number | null;
};

export type ReportWorkflowsStepGroups = Record<
  string,
  ReportWorkflowsStepGroup
>;
export type ReportWorkflowsStepGroup = {
  id: number;
  definition: {
    name?: string;
    id?: number;
    tag?: string;
  };
  ageing: number | null;
  cycleTime?: number | null;
  cycleStart?: string | null;
  cycleEnd?: string | null;
  isCycleRunning?: boolean | null;
};

export type RawWorkflowItem = {
  id: number;
  name: string;
  created_at: string;
  logo: string | null;
  sorting_primary_field: string | null; // Need more info
  lc_data: LCData[];
  workflow_family: RawWorkflowFamily[];
  alerts: any[];
  status: {
    id: number;
    display_name: string;
    color_code: string;
    tag: string;
    label?: string;
    kind_display?: string;
  };
  progress: number;
  uid: string;
  root: null; // Need more info
  region: number;
  business_unit: number;
  definition: {
    id: number;
    name: string;
    kind: number;
    related_types: string[];
    dynamic_group_names_with_perm: any;
    node_order: string[][];
    default_step_tag: string;
    default_step_tags?: string[];
  };
  is_being_migrated?: boolean;
};

export type WorkflowItemDetailsResposne = {
  count: number;
  next: null;
  previous: null;
  results: RawWorkflowItem[];
};

export type MigrationStatsResponse = {
  is_atd_migration_ongoing: boolean;
  estimated_time_of_completion?: number;
  kinds_being_migrated?: string[];
  kinds_blocked?: string[];
  error_code: MigrationErrorCodes;
  message: string;
};

export type WorkflowItemDetails = {
  alerts: any[];
  businessUnit: number;
  createdAt: string;
  definition: RawWorkflowItem["definition"];
  id: number;
  logo: string | null;
  name: string;
  selected_flag: any;
  uid: string;
  workflowFamily: WorkflowFamily[];
};

export type ReportOrderingSortOrder = "asc" | "desc";
export type ReportOrderingFieldDataType = "date" | "datetime" | "float" | "str";

// SLA = STEP_LEVEL_ATTRIBUTES
// SLGA = STEP_GROUP_LEVEL_ATTRIBUTE

// User can not add "cycle_start"| "cycle_end"| "overdue" in column
// as Export for these fields are not supported.
export type SLAExport = Exclude<
  StepLevelAttributesExpanded,
  "cycle_start" | "cycle_end" | "overdue"
>;
export type SGLAExport = Exclude<
  StepGroupLevelAttribute,
  "cycle_start" | "cycle_end"
>;

export type SGLAGroupBy = "tag"; // it should be mention in STEP_GROUP_LEVEL_ATTRIBUTE

export type SLAOrderingColType = "step" | "stepgroup";
export type SLAOrderingCol = Exclude<
  StepLevelAttributes,
  "is_locked" | "is_enabled" | "ageing"
>;

export type ReportOrdering =
  | {
      col_type: "field";
      col: string;
      sort_order: ReportOrderingSortOrder;
      field_data_type: ReportOrderingFieldDataType;
    }
  | {
      col_type: "attr";
      col: "name" | "status" | "kind_name";
      sort_order: ReportOrderingSortOrder;
    }
  | {
      col_type: SLAOrderingColType;
      col: SLAOrderingCol;
      sort_order: ReportOrderingSortOrder;
      tag: string;
      field_data_type: ReportOrderingFieldDataType;
    };
export type WorkflowPermissionsType = Record<
  WorkflowLevelPermissionType,
  boolean
>;

export type ExternalProcessResponse = {
  id: number;
  uid: string;
  business_unit: string;
  created_at: string;
  created_by: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    kind: number;
  };
  definition: {
    id: number;
  };
  external_id: number;
  logo: string;
  name: string;
  region: string;
  status: {
    id: number;
    tag: string;
    label: string;
    kind: number;
  };
  updated_at: string;
  last_activity: string;
  lc_data: Record<string, string>;
};

export type ProcessCycleChartFiltersType = {
  kind_id?: number;
  stepgroups?: string[];
  steps?: string[];
  metric_type: string[];
};

export type RelatedKindType = {
  id: number;
  name: string;
  tag: string;
  latestDefinitionId: number;
};
export type WorkflowAccessTypes = "write" | "read";
export type WorkflowAccessQuery = {
  workflow_access_type: WorkflowAccessTypes;
};

export type ExternalUpsertWorkflow = {
  name?: string;
  kind: string;
  status?: string;
  duplicate_check: {
    fields: string[];
    values: string[];
    condition: string;
    fields_to_update: string[];
  };
  field_data?: HashMap;
  run_sync: boolean;
};
type GetAdvancedFilterDataReturnType =
  | {
      hierarchy: never[];
      mapping: {};
      stepGroupAndStepMapping?: undefined;
    }
  | {
      hierarchy: FieldHierarchyTree[] | undefined;
      mapping: FieldMap;
      stepGroupAndStepMapping: SLAAndSGLAMapping;
    };

export type GetAdvancedFilterDataORMType = {
  processTypeId?: number;
  childrenKindIds?: string[];
  grandChildrenKindIds?: string[];
  useORMReport: boolean;
};

type GetAdvancedFilterDataORMDataChild = {
  kindId: string;
  advancedFilterData: GetAdvancedFilterDataReturnType;
};
export type GetAdvancedFilterDataORMReturnType = {
  parentOption: GetAdvancedFilterDataReturnType;
  children: GetAdvancedFilterDataORMDataChild[];
  grandChildren: GetAdvancedFilterDataORMDataChild[];
  fieldTagMapping: FieldMap;
  stepGroupAndStepMapping: SLAAndSGLAMapping;
};

export type StepUserTagsWorkflowResponse = {
  count: number | undefined;
  next: string | undefined;
  previous: string | undefined;
  results: StepUserTagsWorkflowDataResponse[];
};

type StepUserTagsWorkflowDataResponse = {
  id: number;
  tag: string;
  step: number;
  user: number;
  user_full_name: string;
  user_email: string;
  user_static_groups: {
    id: number;
    name: string;
  }[];
  created_by: number;
};

export type StepUserTags = Record<string, StepUserTagsData>;

export type StepUserTagsData = {
  created_by: number;
  groups: { id: number; name: string }[];
  id: number;
  step: number;
  tag: string;
  user: number;
  user_email: string;
  user_full_name: string;
  user_static_groups: { id: number; name: string }[];
  users: StepUserData[];
};

type StepUserData = {
  id: number;
  user: number;
  user_full_name: string;
  user_email: string;
  groups: { id: number; name: string }[];
};
