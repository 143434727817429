import type { FC } from "react";
import { forwardRef, useMemo, useState, useEffect } from "react";
import {
  LineChart as ReLineChart,
  XAxis,
  YAxis,
  Line,
  LabelList,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid
} from "recharts";
import { TYPOGRAPHY_VARIANTS } from "@certa/blocks";
import {
  tooltipFormatter,
  xAxisFormatter,
  axisFormatter,
  getLabelsFromChartData,
  commonChartMargin,
  CustomYAxesLabel,
  CustomXAxesLabel,
  CustomLegendWrapper,
  useGetLegendDataCommon,
  Y_AXIS_WIDTH,
  MultiXAxisCustomTooltip,
  SHOW_ALL_TICKS
} from "@certa/chartreport";
import { ONE, type ChartComponentProps, INDEX_ZERO } from "@certa/common";
import { CustomAxesTick } from "../customAxesTick/CustomAxesTick";
import { css } from "emotion";
import { CustomDot } from "../customDot/CustomDot";
import { createUniqueXAxis } from "./utils";
import { UNIQUE_X_AXIS_IDENTIFIER } from "./constants";

const lineStyle = css({
  cursor: "pointer",
  "& .recharts-line:not(:hover) .recharts-label-list text": {
    display: "none"
  },
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});

const activeXLabel = css({
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});

export const LineChart: FC<ChartComponentProps> = forwardRef((props, ref) => {
  const {
    isAnimationActive,
    chartData,
    sorting,
    height = 500,
    onClick,
    xActiveIdentifier,
    xAxisDataLabels,
    otherConfig,
    handleSorterChange
  } = props;
  const { data, probableDataType, xAxisKey } = chartData;
  const [chartXLabels, setChartXLabels] = useState<Array<string>>([]);
  const [showValueFor, setShowValueFor] = useState<string | null>(null);
  const xAxisDataLabelsLength = xAxisDataLabels.length;
  const isMultiXAxis = xAxisDataLabelsLength > ONE;

  useEffect(() => {
    setChartXLabels(
      getLabelsFromChartData(data).filter(
        label => !xAxisDataLabels.includes(label)
      )
    );
  }, [data, xAxisDataLabels]);

  const {
    colors,
    shouldShowValueLabels,
    showValueOn,
    showLegend,
    xAxis: otherConfigXAxis,
    xAxisLabel,
    yAxisLabel,
    showXAxisLabel,
    showYAxisLabel
  } = otherConfig;

  useEffect(() => {
    setShowValueFor(null);
  }, [showValueOn]);

  const legendData = useGetLegendDataCommon({
    colors: colors,
    labels: chartXLabels,
    xAxisKey
  });

  const multiXAxisLabelFormatter = useMemo(
    () =>
      xAxisDataLabels.reduce((acc, label, index) => {
        Object.assign(acc, {
          [label]: xAxisFormatter(
            otherConfigXAxis?.dataTypes?.[index],
            otherConfigXAxis?.labelOutputTypes?.[index]
          )
        });
        return acc;
      }, {}),
    [
      otherConfigXAxis?.dataTypes,
      otherConfigXAxis?.labelOutputTypes,
      xAxisDataLabels
    ]
  );

  const DOT_RADIUS = 5;
  const ACTIVE_DOT_STROKE_WIDTH = 0;

  return (
    <CustomLegendWrapper
      data={legendData}
      showLegend={showLegend}
      sorting={sorting}
      handleSorterChange={handleSorterChange}
    >
      <ResponsiveContainer
        width="100%"
        height={height}
        className={showValueOn === "hover" ? lineStyle : activeXLabel}
      >
        <ReLineChart
          ref={ref}
          data={createUniqueXAxis(data)}
          // @ts-expect-error - The type of onClick is not compatible with the type of onClick in ReBarChart
          onClick={(data: ChartClickEvent) => {
            if (onClick && data) {
              onClick({
                ...data,
                activeXIdentifier:
                  data.activePayload?.[INDEX_ZERO]?.payload?.[
                    UNIQUE_X_AXIS_IDENTIFIER
                  ] ?? data.activeLabel
              });
            }
          }}
          style={onClick ? { cursor: "pointer" } : {}} // need better way
          margin={commonChartMargin}
        >
          <CartesianGrid strokeDasharray="4" stroke="#DFE2E7" />
          {xAxisDataLabels.map((label, index) => (
            <XAxis
              key={label}
              fontSize="12"
              xAxisId={index}
              dataKey={label}
              tickSize={15}
              tickLine={false}
              fontWeight={400}
              tickFormatter={xAxisFormatter(
                otherConfigXAxis?.dataTypes?.[index],
                otherConfigXAxis?.labelOutputTypes?.[index],
                label,
                data,
                xAxisDataLabels[xAxisDataLabels.length - 1]
              )}
              interval={isMultiXAxis ? SHOW_ALL_TICKS : "preserveStartEnd"}
              stroke="#DFE2E7"
              label={
                <CustomXAxesLabel
                  label={xAxisLabel}
                  showAxisLabel={
                    xAxisDataLabelsLength !== index + ONE
                      ? "none"
                      : showXAxisLabel
                  }
                />
              }
              tick={CustomAxesTick}
            />
          ))}
          <XAxis
            xAxisId={xAxisDataLabels.length}
            hide
            dataKey={UNIQUE_X_AXIS_IDENTIFIER}
          />
          <YAxis
            fontSize="12"
            tickLine={false}
            tickSize={15}
            tickFormatter={axisFormatter(probableDataType)}
            fontWeight={400}
            width={Y_AXIS_WIDTH}
            interval="preserveStartEnd"
            stroke="#DFE2E7"
            tick={{ fill: "var(--neutral-70)" }}
            label={
              <CustomYAxesLabel
                label={yAxisLabel}
                showAxisLabel={showYAxisLabel}
              />
            }
          />

          <Tooltip
            formatter={tooltipFormatter("LINE_CHART", probableDataType)}
            content={
              <MultiXAxisCustomTooltip
                multiXAxisLabelFormatter={multiXAxisLabelFormatter}
                xAxisDataLabels={xAxisDataLabels}
              />
            }
            cursor={{
              stroke: "var(--teal)",
              strokeDasharray: "4"
            }}
          />
          <ReferenceLine
            xAxisId={xAxisDataLabels.length}
            x={xActiveIdentifier}
            stroke="var(--brand)"
            label={{
              value: "",
              position: "insideLeft",
              angle: -90,
              offset: 10,
              style: TYPOGRAPHY_VARIANTS["p2-medium-upper"] as {}
            }}
          />
          {legendData.map(obj => (
            <Line
              key={obj.label}
              type="monotone"
              dataKey={obj.label}
              stroke={obj.background}
              strokeWidth={3}
              dot={props => {
                return (
                  <CustomDot
                    {...props}
                    r={DOT_RADIUS}
                    style={showValueOn === "click" ? { cursor: "pointer" } : {}}
                    onClick={() => setShowValueFor(obj.label)}
                  />
                );
              }}
              // @ts-expect-error - Parameter 'props' implicitly has an 'any' type.
              activeDot={props => {
                return (
                  <CustomDot
                    {...props}
                    r={DOT_RADIUS}
                    strokeWidth={ACTIVE_DOT_STROKE_WIDTH}
                    style={showValueOn === "click" ? { cursor: "pointer" } : {}}
                    onClick={() => setShowValueFor(obj.label)}
                  />
                );
              }}
              isAnimationActive={isAnimationActive}
              style={showValueOn === "click" ? { cursor: "pointer" } : {}}
              onClick={() => setShowValueFor(obj.label)}
            >
              {shouldShowValueLabels &&
              (showValueOn !== "click" || showValueFor === obj.label) ? (
                <LabelList
                  offset={10}
                  fill=""
                  position="top"
                  dataKey={obj.label}
                  formatter={tooltipFormatter("LINE_CHART", probableDataType)}
                />
              ) : null}
            </Line>
          ))}
        </ReLineChart>
      </ResponsiveContainer>
    </CustomLegendWrapper>
  );
});
