import {
  Ellipsis,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst";
import type { BreadcrumbCurrentProps } from "../Breadcrumb.types";
import styles from "./BreadcrumbCurrent.module.css";
import { forwardRef } from "react";
import { SEPARATOR_CHARACTER_COLON } from "../Breadcrumb.constants";

const BreadcrumbCurrent = forwardRef<HTMLLIElement, BreadcrumbCurrentProps>(
  ({ link }, ref) => {
    const { label, description = "", subDescription = "" } = link;
    return (
      <li className={styles.container} ref={ref}>
        <Typography
          color={TypographyColors.NEUTRAL_700}
          variant={TypographyVariants.LABEL_SM}
          as="span"
          className={styles.label}
        >
          <Ellipsis>{label}</Ellipsis>
        </Typography>

        <Typography
          color={TypographyColors.NEUTRAL_700}
          variant={TypographyVariants.LABEL_SM}
          className={styles.seperator}
          as="span"
        >
          {SEPARATOR_CHARACTER_COLON}
        </Typography>
        <Typography
          color={TypographyColors.NEUTRAL_800}
          variant={TypographyVariants.LABEL_SM_BOLD}
          className={styles.description}
          as="span"
        >
          <Ellipsis>{description}</Ellipsis>
        </Typography>

        {subDescription && (
          <Typography
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.LABEL_SM}
            as="span"
          >
            {subDescription}
          </Typography>
        )}
      </li>
    );
  }
);

export { BreadcrumbCurrent };
