import type {
  ControllerRenderProps,
  RegisterOptions,
  UseFormSetError,
  UseFormClearErrors
} from "../../";
import { useFormContext, Controller } from "../../";

import { TypographyVariants, Typography } from "../../../Typography";
import { AriaLive } from "../../..";

import { generateFieldId } from "../../../../utils";

import styles from "../Fields.module.css";

export type ControllerFormFieldRenderProps = ControllerRenderProps & {
  id: string;
  error?: string;
  setError: UseFormSetError<Record<string, any>>;
  clearErrors: UseFormClearErrors<Record<string, any>>;
};

type ControllerFormFieldProps = {
  label?: string;
  // TODO: When true, the field will be required
  // So add a required rule by default to the rules prop
  required?: boolean;
  name: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  render: (props: ControllerFormFieldRenderProps) => JSX.Element;
};

export const ControllerFormField = (props: ControllerFormFieldProps) => {
  const { label, name, required: isRequired, rules, render } = props;

  const fieldId = generateFieldId(name);

  const {
    control,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  return (
    <div className={styles.catalystFormField}>
      {label && (
        <Typography variant={TypographyVariants.LABEL_SM}>
          <label htmlFor={fieldId}>
            {label}
            {isRequired && <span>*</span>}
          </label>
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          render({
            ...field,
            setError,
            clearErrors,
            id: fieldId,
            error: errors[name]?.message?.toString()
          })
        }
      />
      {errors[name]?.type && (
        <>
          <div
            className={styles.catalystFormFieldError}
            id={fieldId + "-error"}
          >
            {errors[name]?.message}
          </div>
          {/* errors[name].message here is of type string only. */}
          <AriaLive message={errors[name]?.message as string} />
        </>
      )}
    </div>
  );
};
