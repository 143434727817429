import type { FC } from "react";
import { useMentionsContext } from "./MentionsContext";
import { CommentsNew } from "@certa/comments";
import { useCommentsQuery } from "@certa/queries";
import { Loader } from "@certa/blocks";
import { Stack } from "@certa/blocks/thanos";
import type { CommentObjectTypes } from "@certa/types";
import { useHistory } from "react-router-dom";
import { getProcessDetailRoute } from "@certa/common";

export const CommentsContainer: FC = () => {
  const {
    commentData: {
      objectId,
      objectType,
      threadId,
      uid,
      processName,
      workflowFamily
    },
    mode
  } = useMentionsContext();

  const history = useHistory();

  const { data, status } = useCommentsQuery({
    objectId: Number(objectId),
    objectType: objectType as CommentObjectTypes,
    uid: uid,
    config: { enabled: mode === "comments" }
  });

  const handleStepClick = (
    groupId: number,
    stepId: number,
    workflowId: number
  ) => {
    history.push(
      `${getProcessDetailRoute(workflowId)}?group=${groupId}&step=${stepId}`
    );
  };

  if (status === "idle" || status === "loading")
    return (
      <Stack justify="center">
        <Loader />
      </Stack>
    );

  return (
    <CommentsNew
      channels={data?.results || []}
      status={status}
      // doing nothing here, as the step would be already opened
      onStepClick={handleStepClick}
      readOnly={false}
      visible={true}
      multiChannel={false}
      defaultThreadId={threadId}
      header={processName || ""}
      onClose={() => {}}
      activeChannel={null}
      setActiveChannel={() => {}}
      workflowFamily={workflowFamily}
    />
  );
};
