import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AppThunk } from "../../js/_helpers/store";
import {
  login$$,
  checkAuth$$,
  loginOtp$$,
  logout$$,
  claimTokenForAutoLogin$$,
  mfaLogin$$
} from "./service";
import { permissionActions } from "../common/permissions/actions";
import { userUtilities } from "../../js/utils/user";
import to from "await-to-js";
import Godaam from "../../js/utils/storage";
import { updateSelectedLanguage } from "../header/services";
import {
  createCookie,
  EPHEMERAL_TOKEN_QUERY_PARAM,
  IFRAME_PARENT_EVENTS,
  sendMessageToParent
} from "@certa/common";
import type { CaptchaService } from "./types";
import type { AuthUserDetail } from "@certa/types";

type IInitialState = {
  loading: boolean;
  error: string | null;
  user: Partial<AuthUserDetail>;
};

const initialState: IInitialState = {
  loading: false,
  user: {},
  error: null
};

const loginFormSlice = createSlice({
  name: "loginForm",
  initialState,
  reducers: {
    getLoginRequest(state) {
      state.loading = true;
      state.user = { ...state.user, csrf: null };
      state.error = null;
    },
    getLoginSuccess(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.loading = false;
      state.user = user;
      state.error = null;
    },
    getLoginFailure(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.loading = false;
      state.user = { ...state.user, csrf: null };
      state.error = payload;
    },
    getLogoutRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getLogoutSuccess(state) {
      state.loading = false;
      state.user = { csrf: null };
      state.error = null;
    },
    getLogoutFailure(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.loading = false;
      state.error = payload;
    }
  }
});

export const {
  getLoginRequest,
  getLoginSuccess,
  getLoginFailure,
  getLogoutRequest,
  getLogoutFailure,
  getLogoutSuccess
} = loginFormSlice.actions;
// eslint-disable-next-line import/no-default-export
export default loginFormSlice.reducer;

/**
 * Returns the user object with updated preferred_language from Godaam
 * @param user IUserDetail, user from the login API, (OTP/email-password)
 * @returns user IUserDetail, user with updated preferred_language
 */
export const updateLanguage = async (user: AuthUserDetail) => {
  if (
    Godaam.preferredLanguage &&
    user?.prefered_language !== Godaam.preferredLanguage
  ) {
    const [error] = await to(updateSelectedLanguage(Godaam.preferredLanguage));
    if (!error) {
      return {
        ...user,
        prefered_language: Godaam.preferredLanguage
      };
    }
  }
  return user;
};

export const loginUser =
  (
    username: string,
    password: string,
    captchaToken: string | null,
    captchaService: CaptchaService
  ): AppThunk =>
  async dispatch => {
    dispatch(getLoginRequest());
    try {
      const user = await login$$(
        username,
        password,
        captchaToken,
        captchaService
      );

      if (!user?.mfa?.requires_mfa_verification) {
        const userWithUpdatedLanguage = await updateLanguage(user);
        dispatch(getLoginSuccess({ user: userWithUpdatedLanguage }));
        dispatch(permissionActions.getPermissions());
      } else {
        dispatch(getLoginFailure(null));
      }

      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(
          getLoginFailure(error.message ? error.message : "Failed to fetch")
        );
      }
      throw error;
    }
  };

export const submitOtpLogin =
  (
    username: string,
    password: string,
    captchaToken: string | null,
    captchaService: CaptchaService
  ): AppThunk =>
  async dispatch => {
    dispatch(getLoginRequest());
    try {
      const user = await loginOtp$$(
        username,
        password,
        captchaToken,
        captchaService
      );

      if (!user?.mfa?.requires_mfa_verification) {
        const userWithUpdatedLanguage = await updateLanguage(user);
        dispatch(getLoginSuccess({ user: userWithUpdatedLanguage }));
        dispatch(permissionActions.getPermissions());
      } else {
        dispatch(getLoginFailure(null));
      }

      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(
          getLoginFailure(error.message ? error.message : "Failed to fetch")
        );
      }
      throw error;
    }
  };

export const submitMfaPin =
  (pin: string): AppThunk =>
  async dispatch => {
    dispatch(getLoginRequest());
    const [error, user] = await to(mfaLogin$$(pin));
    if (error) {
      dispatch(
        getLoginFailure(error.message ? error.message : "Failed to fetch")
      );
      throw error;
    }
    const userWithUpdatedLanguage = await updateLanguage(user);
    dispatch(getLoginSuccess({ user: userWithUpdatedLanguage }));
    dispatch(permissionActions.getPermissions());
    return user;
  };

export const userLogout = (): AppThunk => async dispatch => {
  dispatch(getLogoutRequest());

  const [error, response] = await to(logout$$());
  if (error) {
    dispatch(
      getLogoutFailure(error.message ? error.message : "Failed to fetch")
    );
    return;
  }
  dispatch(getLogoutSuccess());
  userUtilities.postLogoutAction({
    redirectURL: response.redirect_url
  });
};

export const autoLoginUser =
  (ephemeralToken: string, shouldOnlyCallClaimTokenAPI = false): AppThunk =>
  async dispatch => {
    if (!shouldOnlyCallClaimTokenAPI) {
      dispatch(getLoginRequest());
    }
    const [error, user] = await to(claimTokenForAutoLogin$$(ephemeralToken));
    if (error) {
      dispatch(
        getLoginFailure(error.message ? error.message : "Failed to fetch")
      );
      sendMessageToParent(IFRAME_PARENT_EVENTS.TOKEN_EXPIRED_INVALID);
      throw error;
    } else if (shouldOnlyCallClaimTokenAPI) {
      createCookie(EPHEMERAL_TOKEN_QUERY_PARAM, ephemeralToken);
    }
    if (!shouldOnlyCallClaimTokenAPI) {
      // @ts-expect-error: suppressed type error
      const userWithUpdatedLanguage = await updateLanguage(user);
      dispatch(getLoginSuccess({ user: userWithUpdatedLanguage }));
      dispatch(permissionActions.getPermissions());
      return user;
    } else {
      return user;
    }
  };
export const onlyUpdateUserInfo = (): AppThunk => async dispatch => {
  // Here we are not upading loading status because it creates flickering effect.
  const [error, user] = await to(checkAuth$$());
  if (error) {
    dispatch(getLoginFailure(null));
    return;
  }
  dispatch(getLoginSuccess({ user }));
  dispatch(permissionActions.getPermissions(true));
  return user;
};
