import { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { css } from "emotion";
import { BadgeColors, Badge, Drawer } from "@certa/catalyst";
import { MentionsAndSubscriptionsContainer } from "./MentionsAndSubscriptionsContainer";
import { useMentionsContext } from "./MentionsContext";
import { CommentsContainer } from "./CommentsContainer";
import {
  ErrorBoundary,
  useNotificationsNewUI,
  useScreenResolution
} from "@certa/common";
import { useGetUnReadMentionCount } from "@certa/queries";
import { NotificationDrawer } from "./NotificationDrawer";
import { CommentsContainerRedesigned } from "./CommentsContainerRedesigned";

export const MentionsCommentsDrawer = () => {
  const { isMobileResolution } = useScreenResolution();

  const history = useHistory();

  const {
    show: shouldShow,
    onClose,
    mode,
    updateMode,
    updateCommentData
  } = useMentionsContext();

  const handleOnClose = useCallback(() => {
    updateCommentData({ processId: null });
    updateMode("mentions");
    onClose();
  }, [onClose, updateCommentData, updateMode]);

  useEffect(() => {
    handleOnClose();
  }, [history.location.pathname, handleOnClose]);
  const isNotificationsNewUIEnabled = useNotificationsNewUI();

  /**
   * If the new UI is enabled, we will show the new notification drawer
   * on click of notifications navbar item.
   */
  if (
    isNotificationsNewUIEnabled &&
    (mode === "mentions" || mode === "subscriptions")
  ) {
    return <NotificationDrawer handleOnClose={handleOnClose} />;
  }

  /**
   * If the new UI is enabled, we will show the new Comments drawer
   * when a specific notification is clicked from the notification drawer
   * or when comments are opened for a field/step/swimlane/dashboard/report
   */
  if (isNotificationsNewUIEnabled && mode === "comments") {
    return (
      <CommentsContainerRedesigned
        show={shouldShow}
        handleOnClose={handleOnClose}
      />
    );
  }

  return (
    <Drawer
      width={isMobileResolution ? "100%" : "720px"}
      title={
        mode === "mentions" || mode === "subscriptions" ? (
          <MentionsAndSubscriptionsTitle />
        ) : (
          "Comments"
        )
      }
      description={
        mode === "mentions" || mode === "subscriptions" ? "Comments" : undefined
      }
      aria-label={
        mode === "mentions" ? "My mentions drawer" : "Comments drawer"
      }
      show={shouldShow}
      padding={
        isNotificationsNewUIEnabled && mode === "comments" ? "0" : undefined
      }
      onClose={handleOnClose}
      showCloseIcon={true}
    >
      <ErrorBoundary>
        {mode === "mentions" || mode === "subscriptions" ? (
          <MentionsAndSubscriptionsContainer />
        ) : (
          <CommentsContainer />
        )}
      </ErrorBoundary>
    </Drawer>
  );
};

const MentionsAndSubscriptionsTitle = () => {
  const intl = useIntl();

  const { data: userMentionsCount } = useGetUnReadMentionCount();

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        column-gap: 8px;
      `}
    >
      {intl.formatMessage({
        id: "mentions.myMentions",
        defaultMessage: "Mentions"
      })}
      {!!userMentionsCount && (
        <Badge
          aria-label={`${userMentionsCount} Mentions`}
          label={userMentionsCount}
          color={BadgeColors.RED}
          role="status"
        />
      )}
    </div>
  );
};
