import type { FC } from "react";
import { forwardRef, useCallback } from "react";
import { tooltipFormatter } from "@certa/chartreport";
import type { OnClickCellPayload } from "./HeatMap";
import { HeatMap } from "./HeatMap";
import { Stack } from "@certa/blocks/thanos";
import { NoDataInReports } from "../noDataInReports/NoDataInReports";
import type {
  ChartComponentProps,
  OtherChartConfigColors,
  ChartClickEvent
} from "@certa/common";
import { cloneDeep } from "lodash-es";
import { INDEX_ZERO } from "@certa/common";

export const HeatMapChart: FC<ChartComponentProps> = forwardRef(
  (props, ref) => {
    const {
      groupByORM,
      chartData,
      height = 500,
      otherConfig,
      onClick,
      xActiveIdentifier
    } = props;
    const {
      data,
      probableDataType,
      xAxisKey: xAxisKeyForNormalReports
    } = chartData;

    const isORMReport = !!groupByORM;

    const labels = Object.keys(data[INDEX_ZERO]);

    // valueKey represent cell value, which is always last value in the data array;
    // because it represent Operation in  payload which is always after groupBy.
    const valueKey = labels[labels.length - 1] ?? "";
    const yAxisKeyForNormalReports = labels[1] ?? "";
    const { colors, showXAxisLabel, showYAxisLabel, xAxisLabel, yAxisLabel } =
      otherConfig;
    const cellBaseColors = getBaseColorForHeatMap(valueKey, colors);

    const {
      xAxisKeys: xAxisKeysForORMReports,
      yAxisKeys: yAxisKeysForORMReports
    } = getAxisKeysORM(groupByORM);

    const onClickHandler = useCallback(
      (payload: OnClickCellPayload) => {
        if (onClick) {
          let filtersObj;
          const cellFiltersData =
            payload.cellFiltersData && cloneDeep(payload.cellFiltersData);
          if (isORMReport) {
            filtersObj = cellFiltersData;
            if (filtersObj && filtersObj.hasOwnProperty(valueKey)) {
              delete filtersObj[valueKey];
            }
          } else {
            filtersObj = {
              [yAxisKeyForNormalReports]:
                payload.rowRecord[yAxisKeyForNormalReports],
              [xAxisLabel || ""]: cellFiltersData?.[xAxisKeyForNormalReports]
            };
          }
          const _data: ChartClickEvent = {
            activeLabel: payload.columnHeaderKey,
            activePayload: [
              {
                payload: {
                  ...filtersObj
                }
              }
            ],
            // #GET_ROW_AND_COL_INDEX_FOR_HIGHLIGHT
            // search the above string to find references of where
            // we are using this activeXIdentifier for row and column index
            activeXIdentifier: `${payload.rowIndex}---${payload.columnHeaderKey}`
          };
          onClick(_data);
        }
      },
      [
        isORMReport,
        onClick,
        valueKey,
        xAxisKeyForNormalReports,
        xAxisLabel,
        yAxisKeyForNormalReports
      ]
    );

    if (labels.length < 3 || labels.includes("Minimum Cycle Time"))
      return <NoDataInReports />;

    return (
      <Stack ref={ref} direction="vertical" align="stretch" justify="center">
        <HeatMap
          dataCellFormatter={tooltipFormatter(
            "HEAT_MAP_CHART",
            probableDataType
          )}
          cellBaseColors={cellBaseColors}
          data={data}
          xAxisKeys={
            isORMReport ? xAxisKeysForORMReports : [xAxisKeyForNormalReports]
          }
          yAxisKeys={
            isORMReport ? yAxisKeysForORMReports : [yAxisKeyForNormalReports]
          }
          valueKey={valueKey}
          shouldShowColumnTypeName={showXAxisLabel !== "none"}
          shouldShowRowTypeName={showYAxisLabel !== "none"}
          columnTypeHeading={xAxisLabel}
          rowTypeHeading={yAxisLabel}
          height={height - 108}
          onClick={onClickHandler}
          activeColumnIdentifier={xActiveIdentifier}
        />
      </Stack>
    );
  }
);

const getBaseColorForHeatMap = (
  valueKey: string,
  colors?: OtherChartConfigColors
) => {
  if (colors?.activeColorType === "spectrum" && colors?.spectrum?.[valueKey]) {
    return colors.spectrum?.[valueKey];
  } else if (
    colors?.activeColorType === "singleTone" &&
    colors.singleTone?.[valueKey]
  ) {
    return [colors.singleTone?.[valueKey]];
  }
  return [`rgb(12, 160, 44, 1)`];
};

const getAxisKeysORM = (groupByORM: ChartComponentProps["groupByORM"]) => {
  const xAxisKeys = (groupByORM ?? [])
    .filter(group => !group.extraJSON?.isForSecondaryXAxis)
    .map(group => group.extraJSON?.label);
  const yAxisKeys = (groupByORM ?? [])
    .filter(group => group.extraJSON?.isForSecondaryXAxis)
    .map(group => group.extraJSON?.label);
  return { xAxisKeys, yAxisKeys };
};
