import Godaam from "./storage";
import { showNotification } from "../../modules/common/notification";
import { disconnectPrasaran } from "@certa/prasaran";
import { history } from "../_helpers/history";
import { basename } from "../../config";
import { isLoadingInsideIframe } from "@certa/common";

const removeCookies = () => {
  const expireCookie = key => {
    document.cookie = key + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  };

  const cookies = document.cookie;
  const splitCookies = cookies.split(";");
  for (let i = 0; i < splitCookies.length; i++) {
    const key = splitCookies[i].split("=");
    expireCookie(key[0]);
  }
};

const postLogoutAction = ({ addNextURL = false, redirectURL = "" } = {}) => {
  const preferredLanguage = Godaam.preferredLanguage;
  const recentlyViewed = Godaam.recentlyViewed;

  // TODO: Should be taken from a constant
  // Not able to import now because of circular dependency
  Godaam.clear(["taskFilters", "recentSearches"]);
  Godaam.preferredLanguage = preferredLanguage;
  Godaam.recentlyViewed = recentlyViewed;
  removeCookies();
  disconnectPrasaran();
  if (isLoadingInsideIframe()) {
    return;
  }
  if (redirectURL) {
    // In case of SSO, we get a redirect URL, that needs to be hit to ensure
    // user is logged out from the IdP as well.
    showNotification({
      type: "warning",
      message: "notificationInstances.loggedOutSSO",
      key: "ForcedLogoutSSO",
      placement: "bottomRight"
    });
    window.location.href = redirectURL;
  } else {
    showNotification({
      type: "warning",
      message: "notificationInstances.loggedOut",
      key: "ForcedLogout",
      placement: "bottomRight"
    });
    const nextUrl = history.location.search
      ? `/login/?next=${encodeURIComponent(
          history.location.pathname + history.location.search
        )}`
      : `/login/?next=${history.location.pathname}`;
    const newURL = addNextURL ? nextUrl : `/login/`;
    setTimeout(() => {
      window.location.href = `${basename}${newURL}`;
    }, 1000);
  }
};

export const userUtilities = {
  postLogoutAction
};
