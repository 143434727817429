import type { FC, ReactElement, ReactNode } from "react";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from "react";
import { usePrevious } from "@certa/common";
import type { DrawerProps } from "../componentsTh/Drawer";
import { Drawer } from "../componentsTh/Drawer";
import type { ModalProps } from "../componentsTh/Modal/Modal";
import { Modal } from "../componentsTh/Modal/Modal";
import { v4 as uuidv4 } from "uuid";

export enum OverlayType {
  MODAL = "modal",
  DRAWER = "drawer"
}

type OverlayProps = (ModalProps | DrawerProps) & {
  type?: OverlayType;
  onHide?: () => void;
};

export type ShowOverlay = (props?: any) => void;
export type HideOverlay = () => void;
type GetOperations = (
  identifier: string,
  element:
    | ReactElement
    | ((props: {
        hideOverlay: HideOverlay;
        showOverlay: ShowOverlay;
        [key: string]: any;
      }) => ReactElement),
  overlayProps: OverlayProps
) => {
  show: ShowOverlay;
  hide: HideOverlay;
};

type OverlayContextProps = {
  getOperations: GetOperations;
  visible: Set<string>;
};

type OpenOverlays = {
  [identifier: string]: {
    element: ReactElement;
    overlayProps: OverlayProps;
  };
};
const noop = (): void => {};
const OverlayContext = createContext<OverlayContextProps>({
  getOperations: () => ({
    show: noop,
    hide: noop
  }),
  visible: new Set()
});

/**
 * Manages Modals and Drawers
 */
const OverlayManager: FC = props => {
  const [openOverlays, updateOpenOverlays] = useState<OpenOverlays>({});
  const [visible, setVisible] = useState<Set<string>>(new Set());
  const getOperations: GetOperations = (identifier, element, overlayProps) => {
    const hide = () => {
      updateOpenOverlays(openOverlays => {
        const overlay = openOverlays[identifier];
        if (!overlay) {
          console.warn("Warning: Calling hide before show has no effects!");
          return openOverlays;
        } else {
          overlayProps.onHide && overlayProps.onHide();
          return {
            ...openOverlays,
            [identifier]: {
              element: overlay.element,
              overlayProps: {
                ...overlay.overlayProps,
                visible: false
              }
            }
          };
        }
      });

      const updatedVisibleSet = new Set(visible);
      if (updatedVisibleSet.delete(identifier)) {
        setVisible(updatedVisibleSet);
      }
    };

    const show = (props = {}) => {
      updateOpenOverlays(openOverlays => ({
        ...openOverlays,
        [identifier]: {
          element:
            typeof element === "function"
              ? element({ ...props, showOverlay: show, hideOverlay: hide })
              : React.cloneElement(element, {
                  ...props,
                  showOverlay: show,
                  hideOverlay: hide
                }),
          overlayProps: {
            // User can override this
            // in case he doesn't want this to close the modal
            // using it just for a default behavior
            onCancel: hide, // Applicable for Modal
            onClose: hide, // Applicable for Drawer
            ...overlayProps,
            ...props,

            // Since the name of the function is show
            // user should not be able to override it
            visible: true
          }
        }
      }));

      setVisible(visible.add(identifier));
    };

    return {
      show,
      hide
    };
  };
  return (
    <OverlayContext.Provider value={{ getOperations, visible }}>
      <>
        {Object.entries(openOverlays).map(([identifier, overlay]) => {
          if (overlay.overlayProps.type === OverlayType.DRAWER) {
            return (
              <Drawer
                {...(overlay.overlayProps as DrawerProps)}
                key={`${identifier}`}
              >
                {overlay.element}
              </Drawer>
            );
          } else {
            return (
              <Modal
                {...(overlay.overlayProps as ModalProps)}
                key={`${identifier}`}
              >
                {overlay.element}
              </Modal>
            );
          }
        })}
        {props.children}
        {/* 
          PROBLEM: 
          When using a portal there can be cases when a DOM container 
          rendering the portal children is torn off and 
          a new similar(not same ref) is made but 
          the virtual DOM remains the same
          This generally happens when the react tree containing the portal children
          is way above the tree containing the portal container in the react hierarchy
          SEE: https://codesandbox.io/s/react-portal-rendering-issue-ifgeb
          This leads to synchronization issues between virtual DOM and real DOM
          causing an incorrect DOM being rendered.

          SOLUTION:
          Create a separate container for the portal at same level 
          as that of the portal children
        */}
      </>
    </OverlayContext.Provider>
  );
};

const useOverlay = (
  identifier: string,
  element:
    | ReactElement
    | ((props: {
        hideOverlay: HideOverlay;
        showOverlay: ShowOverlay;
        [key: string]: any;
      }) => ReactElement),
  overlayProps: OverlayProps = {}
): [ShowOverlay, HideOverlay] => {
  const { getOperations } = useContext(OverlayContext);
  const { show, hide } = getOperations(identifier, element, overlayProps);

  // Commented out because it is causing overlay to hide at unexpected times
  // useEffect(() => {
  //   return () => {
  //     // Hide overlay when unmounted
  //     if (visible.has(identifier)) hide();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Sending it an array,
  // so that the caller can add context to the name
  return [show, hide];
};

/**
 * useOverlayOnClose is a hook that can invoke a certain callback whenever
 * an overlay of a particular identified is closed.
 * Example: Comments being closed and some component refetching its data
 * @param identifier
 * @param callback
 */
const useOverlayOnClose = (identifier: string, callback: Function) => {
  const { visible } = useContext(OverlayContext);
  const lastVisible = usePrevious(visible) as unknown as Set<string>;

  useEffect(
    () => {
      if (lastVisible?.has(identifier) && !visible.has(identifier)) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible, callback]
  );
};

/**
 * Returns a function 'confirm' that when called will show modal.
 * Modal will automatically hide when action is taken.
 */
const useOverlayConfirm = () => {
  const [show, hide] = useOverlay(uuidv4(), <div />, {});

  const confirm = useCallback(
    (props: Omit<ModalProps, "children"> & { content: ReactNode }) => {
      const { onOk, onCancel, closable, maskClosable, content, ...restProps } =
        props;

      show({
        onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          onOk?.(e);
          hide();
        },
        onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          onCancel?.(e);
          hide();
        },
        closable: closable || false,
        maskClosable: maskClosable || false,
        children: content,
        ...restProps
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { confirm };
};

export {
  OverlayManager,
  OverlayContext,
  useOverlay,
  useOverlayOnClose,
  useOverlayConfirm
};
