import { HorizontalTab, HorizontalTabs } from "@certa/catalyst";
import { CommentObjectTypes } from "@certa/types";
import type { CommentChannel } from "@certa/types";
import { useIntl } from "react-intl";
import { useState, useMemo } from "react";
import { css } from "emotion";
import { Stack } from "@certa/blocks/thanos";
import { ChannelContext } from "../../comments.context";
import type { AllChannelsProps } from "./MultiChannelView";
import { NoComments } from "./NoComments";
import { ChannelListItem } from "./ChannelListItem";

/**
 * Render Tabs
 * 1. Alerts Tab
 * 2. Events Tab
 *
 * Show data as per selected tab
 * @returns
 */

// set selected mode in URL params
const setTabValueInURL = (value: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("tab", value);
  window.history.replaceState(null, "", `?${urlParams.toString()}`);
};

// set selected mode in URL params
export const resetTabValueInURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete("tab");
  window.history.replaceState(null, "", `?${urlParams.toString()}`);
};

// get selected mode from URL params
const getSelectedTabValueFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("tab");
  return mode === "events" ? "events" : "alerts";
};

export const MultiChannelWithTabs = ({
  filteredChannels,
  handleChannelSelect
}: {
  filteredChannels: CommentChannel[];
} & Pick<AllChannelsProps, "handleChannelSelect">) => {
  const intl = useIntl();
  const [tab, setTab] = useState(getSelectedTabValueFromURL());
  const onChangeTab = (value: string) => {
    setTab(value);
    setTabValueInURL(value);
  };
  const channelsAsPerSelectedTab = useMemo(() => {
    if (tab === "alerts") {
      return filteredChannels.filter(
        channel => channel.objectType !== CommentObjectTypes.INTEGRATION_RESULT
      );
    } else {
      return filteredChannels.filter(
        channel => channel.objectType === CommentObjectTypes.INTEGRATION_RESULT
      );
    }
  }, [tab, filteredChannels]);
  return (
    <>
      <Stack
        direction="vertical"
        className={css`
          margin-top: 10px !important;
        `}
      >
        <Stack
          direction="vertical"
          className={css`
            height: 28px;
            margin-top: 5px 0;
          `}
        >
          <HorizontalTabs
            aria-label="Mentions and Subscriptions"
            activeTab={tab}
            onChange={onChangeTab}
          >
            <HorizontalTab aria-label="Alerts" value="alerts">
              {intl.formatMessage({
                id: "adjudications.alerts",
                defaultMessage: "Alerts"
              })}
            </HorizontalTab>
            <HorizontalTab aria-label="Events" value="events">
              {intl.formatMessage({
                id: "adjudications.events",
                defaultMessage: "Events"
              })}
            </HorizontalTab>
          </HorizontalTabs>
        </Stack>
      </Stack>
      <Stack
        gap="s4"
        direction="vertical"
        className={css`
          padding-bottom: var(--s4) !important;
          overflow-y: auto !important;
          margin-top: var(--s2) !important;
        `}
      >
        {channelsAsPerSelectedTab.length === 0 ? (
          <Stack
            className={css`
              height: 100dvh;
              width: 100%;
              display: block !important;
            `}
          >
            <NoComments
              heading={intl.formatMessage({
                id: "comments.noCommentsGenericWithTabs",
                defaultMessage: "No Comments to show"
              })}
            />
          </Stack>
        ) : (
          channelsAsPerSelectedTab.map(channel => (
            <ChannelContext.Provider
              value={channel}
              key={`${channel.objectId}`}
            >
              <ChannelListItem handleChannelSelect={handleChannelSelect} />
            </ChannelContext.Provider>
          ))
        )}
      </Stack>
    </>
  );
};
