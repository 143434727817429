import { ClassNames } from "@emotion/core";
import type { ReactEventHandler } from "react";
import { useMemo, useCallback, useRef, useEffect, forwardRef } from "react";
import type {
  JsonEditor,
  JsonEditorProps as JsonEditorComponentProps
} from "jsoneditor-react";
import { useJSONEditorResizeObserver } from "@certa/common";
import { FallbackLoader } from "@certa/common/src/components/FallbackLoader";
import loadable from "@loadable/component";
import Ajv from "ajv";

const ajv = new Ajv({ allErrors: true, verbose: true });

const Editor = loadable(
  () =>
    import("ace-builds/src-noconflict/ace")
      .then(() =>
        Promise.all([
          import("jsoneditor-react"),
          import("brace"),
          import("jsoneditor-react/es/editor.min.css"),
          import("brace/mode/json")
        ])
      )
      .then(([{ JsonEditor }, { default: ace }]) => {
        return forwardRef<JsonEditor, JsonEditorComponentProps>(
          (props, ref) => (
            <JsonEditor ace={ace} ajv={ajv} ref={ref} {...props} />
          )
        );
      }),
  {
    fallback: <FallbackLoader />
  }
);

export type JSONEditorProps = JsonEditorComponentProps & {
  disabled?: boolean;
  height?: number;
  minHeight?: string;
  /**
   * This flag determines whether the editor should automatically resize when its parent element is resized.
   *
   * Typically, resizing occurs when the user releases the mouse after dragging the editor, triggering the 'mouseup' event.
   * However, a challenge arises when the user drags the editor and the mouse cursor moves out of the editor's boundaries,
   * such as going under a fixed footer or out of the visible view. In such cases, the 'mouseup' event is not captured by
   * the editor's container, preventing the resizing operation from triggering as expected.
   *
   * To address this issue, we employ a ResizeObserver. This observer monitors changes in the dimensions of the editor's parent
   * element. When a resize event is detected on the parent, the editor is automatically resized, ensuring that its dimensions
   * remain in sync with the parent container. By using a ResizeObserver, we provide a reliable solution that guarantees
   * consistent resizing behavior, regardless of the user's dragging actions or mouse cursor position.
   */
  autoResizeOnParentResize?: boolean;
};

export const JSONEditor = (props: JSONEditorProps) => {
  const ref = useRef<JsonEditor>(null); // to hold reference of JSONEditor-React component
  const {
    value,
    onBlur,
    disabled,
    height,
    minHeight,
    autoResizeOnParentResize,
    ...restProps
  } = props;
  const { handleParentResizeCallbackRef } = useJSONEditorResizeObserver(ref);

  // Prepare JSON from value
  const validatedValue = useMemo(() => {
    let parsedJSON;
    try {
      parsedJSON = JSON.parse(value);
    } catch (e) {
      parsedJSON = {};
    }

    return parsedJSON;
  }, [value]);

  // Created a reference due to limitation of the editor
  // library and it doesn't work well with changing references.
  const handleOnBlur = useRef<ReactEventHandler | null>(null);

  handleOnBlur.current = useCallback(
    (_: any) => {
      const updatedValue = JSON.stringify(validatedValue);
      // To prevent saving of errors when the JSON parsing fails
      // and it falls back to `{}`. If we remove this, then in case
      // of error int he field, it actually clears it
      if (value?.length > 2 && updatedValue === "{}") return;
      onBlur?.({
        target: {
          value: updatedValue === "{}" ? "" : updatedValue
        }
      });
    },
    [validatedValue, onBlur, value]
  );

  const handleResize = useCallback(event => {
    /**
     * This condition is to avoid any unnecessary triggers of `resize`
     * whenever onMouseUp event bubbles from the DOM Tree.
     * We allow the .jsoneditor-statusbar element because it happens
     * that while dragging the click is registered on it, and if we
     * don't include it, then resize doesn't get triggered.
     */
    if (
      event.currentTarget === event.target ||
      // I personally feel bad for writing the condition below //
      event.target?.className.indexOf("jsoneditor-statusbar") >= 0
    ) {
      // Again, terrible-terrible hack but this is how it works
      // this is to avoid the editor not adjusting automatically to the new
      // height. This is because internally the editor waits for
      // window's resize event to trigger it's resize. Other way is to
      // invoke it manually

      ref.current?.jsonEditor?.resize();
    }
  }, []);

  useEffect(() => {
    const editor = ref && ref.current && ref.current.jsonEditor;
    if (editor && validatedValue) {
      editor.update(validatedValue);
    }
  }, [ref, validatedValue]);

  return (
    <ClassNames>
      {({ css }) => (
        <div
          style={{
            overflow: "auto",
            resize: "vertical",
            height: height ? `${height}px` : "auto",
            minHeight
          }}
          onMouseUp={handleResize}
          ref={
            autoResizeOnParentResize ? handleParentResizeCallbackRef : undefined
          }
        >
          <Editor
            /**
             * Yet another hack to force re-mount whenever
             * the state gets changed from disabled to enabled. Otherwise
             * it gets stuck using the props it gets initially and never bothers
             * to update them, until the component is remounted.
             */
            key={disabled ? "disabled" : "enabled"}
            mode={disabled ? "view" : "code"}
            // undefined in allowedModes hides the mode change dropdown
            allowedModes={disabled ? undefined : ["code", "tree", "view"]}
            ref={ref}
            // Weird hack but apparently the onBlur on Editor captures the
            // first reference and just keeps using that. Even passing the
            // handleOnBlur.current directly doesn't work, so we have to call
            // it like that.
            onBlur={(e: any) => handleOnBlur.current?.(e)}
            htmlElementProps={{
              style: {
                height: "100%"
              },
              class: css`
                & .jsoneditor .ace_editor * {
                  font-family: "dejavu sans mono", "droid sans mono", consolas,
                    monaco, "lucida console", "courier new", courier, monospace,
                    san-serif, "system-ui" !important;
                }
              `
            }}
            value={validatedValue}
            {...restProps}
          />
        </div>
      )}
    </ClassNames>
  );
};
