/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { ReactElement, FC } from "react";
import { useMemo, useState, useCallback, useEffect } from "react";
import type {
  Edge,
  Node,
  ReactFlowState,
  OnSelectionChangeParams
} from "react-flow-renderer";
import ReactFlow, {
  ReactFlowProvider,
  Position,
  Controls,
  useReactFlow,
  useNodes,
  useStore,
  applyNodeChanges,
  applyEdgeChanges
} from "react-flow-renderer";
import dagre from "dagre";
import { FlowNode } from "./FlowNode";
import type { StepGroupsData } from "@certa/queries";
import type { StepDependencies, StepGroup } from "@certa/types";
import CustomEdge from "./FlowEdge";
import type { TaskChangeArgs, NodeState } from "../../../types";
import { HoverAbleSVG } from "@certa/blocks/thanos";
import {
  AccordionCollapse,
  AccordionExpand,
  Minus,
  Plus,
  Tasks,
  ZoomToSelection
} from "@certa/icons";
import { NoGroupResults } from "../NoGroupsResults";
import { useIntl } from "react-intl";
import { getFlowEdges } from "../../utils";
import CustomFlowBackground from "@certa/common/src/components/CustomFlowBackground";
import { TaskCategoryTypes } from "@certa/tasks/src/types";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

const edgeTypes = {
  customEdge: CustomEdge // creating custom Edge (connection)
};

type Props = {
  onTaskClick: (payload: TaskChangeArgs) => void;
  taskLanesData: StepGroupsData;
  groupsList?: StepGroup[];
  stepDependencies?: StepDependencies;
  showHiddenSteps: boolean;
  taskCategoryFilter: string;
  taggedStepGroups: number[];
  height?: string;
};

type FlowElementsType = {
  flowNodes: Node[];
  flowEdges: Edge[];
};

const position = { x: 0, y: 0 };
const CANVAS_HEIGHT = 425;

const MAX_ZOOM_SCALE = 1.5;

// layout direction from left to right
const layoutDirection = "LR";
// creating flow graph object
const dagreGraph = new dagre.graphlib.Graph();
// setting default value to empty object, since we are only using dagre to layout nodes not edges
dagreGraph.setDefaultEdgeLabel(() => ({}));

dagreGraph.setGraph({
  rankdir: layoutDirection,
  ranksep: 50, // space between each node horizontally
  nodesep: 50 // space between each node vertically
});

export function FlowDiagramInner({
  taskCategoryFilter,
  taskLanesData,
  groupsList,
  stepDependencies,
  onTaskClick,
  showHiddenSteps,
  taggedStepGroups,
  height
}: Props): ReactElement {
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const [isFlowLoaded, setIsFlowLoaded] = useState(false);
  const [expandCollapseAllSteps, setExpandCollapseAllSteps] =
    useState<NodeState>("collapsed");

  const intl = useIntl();
  //After nodes are rendered, we get rendered node data from useNodes hook
  const renderedNodes = useNodes();

  const addSelectedNodes = useStore(
    (state: ReactFlowState) => state.addSelectedNodes
  );
  const resetSelectedElements = useStore(
    (state: ReactFlowState) => state.resetSelectedElements
  );

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const onNodesChange = useCallback(
    changes => setNodes(nds => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    changes => setEdges(eds => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const handleExpandCollapseAllSteps = useCallback(expand => {
    setExpandCollapseAllSteps(expand);
  }, []);

  // Elements as per react flow format and are combination of nodes and edges
  const { flowNodes, flowEdges } = useMemo<FlowElementsType>(() => {
    const flowNodes = taskLanesData.results.map(group => ({
      id: String(group.definitionTag),
      data: {
        label: (
          <FlowNode
            taggedStepGroups={taggedStepGroups}
            taskCategoryFilter={taskCategoryFilter}
            group={group}
            onTaskClick={onTaskClick}
            expandCollapseAll={expandCollapseAllSteps}
            showHiddenSteps={showHiddenSteps}
            handleExpandCollapseAllSteps={handleExpandCollapseAllSteps}
          />
        )
      },
      style: {
        // applying all node styles in FlowNode
        width: "auto",
        borderColor: "#fff",
        padding: 0,
        cursor: "default",
        boxShadow: "none"
      },
      // setting position to 0,0 until dagre calculates layout
      position
    }));
    const flowEdges = getFlowEdges(groupsList, stepDependencies);
    return { flowNodes, flowEdges };
  }, [
    groupsList,
    stepDependencies,
    onTaskClick,
    showHiddenSteps,
    taskLanesData.results,
    taskCategoryFilter,
    taggedStepGroups,
    expandCollapseAllSteps,
    handleExpandCollapseAllSteps
  ]);

  // calculate blur lines with definition tag

  const blurredFLowEdges: string[] = useMemo(() => {
    if (taskCategoryFilter !== TaskCategoryTypes.ALL_TASKS) {
      const data: StepGroup[] =
        taskCategoryFilter === TaskCategoryTypes.ME
          ? taskLanesData?.myStepGroups || []
          : taskCategoryFilter === TaskCategoryTypes.MY_GROUPS
            ? taskLanesData?.userStepGroups || []
            : [];

      return data?.map(stepGroup => stepGroup.definitionTag);
    }
    return [];
  }, [taskCategoryFilter, taskLanesData]);

  // if any node width or height changes, we update dagre about dimensions
  //converting to primitive type, to re-run useMemo if value of width or height changes
  const { widthListStr, heightListStr, hasDimensions } = useNodeDimensions();
  // These are elements with proper coordinates of node for reactflow
  useEffect(() => {
    if (hasDimensions) {
      const renderedNodesMap: Record<
        string,
        {
          width: number;
          height: number;
          selected?: boolean;
        }
      > = renderedNodes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: { width: cur.width, height: cur.height }
        }),
        {}
      );

      flowNodes.forEach((el: Node) => {
        const item = renderedNodesMap[el.id];
        //passing dagre width and height on node
        dagreGraph.setNode(el.id, {
          width: item?.width || 0,
          height: item?.height || 0
        });
      });

      // remove nodes which are not in flowNodes, this was creating the issue of extra space between the swim lanes
      dagreGraph.nodes().forEach(node => {
        if (!flowNodes.find(el => el.id === node)) {
          dagreGraph.removeNode(node);
        }
      });

      flowEdges.forEach((el: Edge) => {
        //passing dagre node connection
        dagreGraph.setEdge(el.source, el.target);
      });

      // based on node width & height and connection dagre creates a layout
      dagre.layout(dagreGraph);
      //after layout is created, layout coordinates are passed to reactflow as x and y
      const nodes = flowNodes
        .map((el: Node) => {
          const nodeWithPosition = dagreGraph.node(el.id);
          const item = renderedNodesMap[el.id];
          if (nodeWithPosition) {
            return {
              ...el,
              selected: item?.selected || false,
              // layout is left to right, nodes connections will be left and right
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              position: {
                x: nodeWithPosition.x,
                y: nodeWithPosition.y - (item?.height || 0) / 2
              }
            };
          }
          return null;
        })
        .filter(node => node) as Node[];

      const [source, target] = nodes.filter(node => node.selected);

      const edges = flowEdges.map((el: Edge) => ({
        ...el,
        type: "customEdge",
        data: {
          resetSelectedElements
        },
        style: {
          opacity: blurredFLowEdges?.includes(el.source) ? 0.5 : 1,
          strokeWidth: 1.5,
          stroke:
            el.source === source?.id && el.target === target?.id
              ? "var(--brand)"
              : "var(--neutral-70)"
        }
      }));
      setNodes(nodes);
      setEdges(edges);
      return;
    }
    setNodes(flowNodes);
    setEdges(flowEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasDimensions,
    widthListStr,
    heightListStr,
    flowNodes,
    flowEdges,
    setNodes,
    setEdges
  ]); // makes sure each time node is expanded, it redraws layout

  const highlightPath = ({ nodes }: OnSelectionChangeParams) => {
    if (nodes.length === 2) {
      const [source, target] = nodes;
      setEdges((prevEdges: Edge[]) =>
        prevEdges.map((prevEdge: Edge) => {
          prevEdge.style = {
            ...prevEdge.style,
            stroke:
              prevEdge.source === source.id && prevEdge.target === target.id
                ? "var(--brand)"
                : "var(--neutral-70)"
          };
          return prevEdge;
        })
      );
    } else {
      setNodes((prevNodes: Node[]) =>
        prevNodes.map((prevNode: Node) => {
          prevNode.selected = false;
          return prevNode;
        })
      );
      setEdges((prevEdges: Edge[]) =>
        prevEdges.map((prevEdge: Edge) => {
          prevEdge.style = {
            ...prevEdge.style,
            stroke: "var(--neutral-70)"
          };
          return prevEdge;
        })
      );
    }
  };

  /**
   * Fit all swim lanes inside the view
   */
  const fitToView = useCallback(() => {
    // to give smooth experience until flow is fit into view
    setTimeout(() => {
      fitView();
    }, 50);
  }, [fitView]);

  /**
   * Fit all swim lanes inside the view when expand/collapse all is toggled
   */
  useEffect(() => {
    if (
      expandCollapseAllSteps === "collapsed" ||
      expandCollapseAllSteps === "expanded"
    ) {
      fitToView();
    }
  }, [expandCollapseAllSteps, isFlowLoaded, fitToView]);

  /**
   * Fit all swim lanes inside the view when show hidden steps is toggled
   */
  useEffect(() => {
    fitToView();
  }, [showHiddenSteps, fitToView]);

  const mixPanelHandler = {
    zoomIn: () =>
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_ZOOM,
        { zoom: "in" }
      ),
    zoomOut: () =>
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_ZOOM,
        { zoom: "out" }
      ),
    default: () =>
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_ZOOM,
        { zoom: "default" }
      ),
    expandAll: () =>
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_EXPAND_ALL_TOGGLE,
        { expand: true }
      ),
    collapseAll: () =>
      MixPanelActions.track(
        MixPanelEvents.tasksPageEvents.PROGRESS_MAP_CLICK_EXPAND_ALL_TOGGLE,
        { expand: false }
      )
  };

  return (
    <div
      style={{
        minHeight: CANVAS_HEIGHT,
        height: height || "65vh",
        // to give smooth experience until flow is fit into view
        visibility: isFlowLoaded ? "visible" : "hidden"
      }}
    >
      {taskLanesData.results && taskLanesData.results.length === 0 && (
        <NoGroupResults
          IconComp={Tasks as FC}
          heading={intl.formatMessage({
            id: "tasklaneProgress.noSteps",
            defaultMessage: "No available steps"
          })}
        />
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={false}
        elementsSelectable
        nodesConnectable={false}
        edgeTypes={edgeTypes}
        zoomOnScroll
        preventScrolling={false}
        onEdgeClick={(_, { source, target }) =>
          addSelectedNodes([source, target])
        }
        onEdgeMouseEnter={(_, { source, target }) =>
          addSelectedNodes([source, target])
        }
        onEdgeMouseLeave={resetSelectedElements}
        maxZoom={MAX_ZOOM_SCALE}
        // works async
        onInit={() =>
          setTimeout(() => {
            fitView();
            setIsFlowLoaded(true);
          }, 50)
        }
        onSelectionChange={highlightPath}
        css={css`
          .react-flow__handle {
            background: none;
            border-color: transparent;
          }

          .custom-node:not(.disabled-node) {
            cursor: pointer;
          }
          .custom-node.disabled-node {
            background-color: var(--neutral-20);
            border-color: var(--neutral-50) !important;
            cursor: not-allowed;
            .group-header-title,
            .group-header-icon path {
              color: var(--neutral-70);
            }
          }

          // expanded node container
          .react-flow__node[aria-expanded="true"] {
            z-index: 10 !important;
          }

          .selected {
            border: 2px solid var(--brand) !important;
            margin-top: -3px;
            margin-left: -3px;
            padding: 2px !important;
            border-radius: var(--big-border-radius);
          }

          .react-flow__pane {
            cursor: grab;
          }
          .react-flow__edge {
            opacity: ${blurredFLowEdges?.length ? 0.5 : 1};
          }
        `}
      >
        <CustomFlowBackground />
        <Controls
          showInteractive={false}
          showFitView={false}
          showZoom={false}
          css={css`
            display: flex;
            align-items: center;
            flex-direction: column;
            left: auto;
            right: 22px;
            bottom: 22px;
            box-shadow: 0px 1px 3px 0px #00164e26;
            border-radius: var(--s1);
            background-color: var(--neutral-0);

            .ant-btn.button-is-icon {
              padding: var(--s2);
              border-radius: 0px !important;
              border: 1px solid var(--neutral-20);

              &:first-child {
                border-radius: var(--s1) var(--s1) 0px 0px !important;
                border-bottom: 0;
              }
              &:last-child {
                border-radius: 0px 0px var(--s1) var(--s1) !important;
                border-top: 0;
              }
            }
          `}
        >
          <HoverAbleSVG
            icon={Plus}
            onClick={() => {
              zoomIn({ duration: 100 });
              mixPanelHandler.zoomIn();
            }}
          />
          <HoverAbleSVG
            icon={Minus}
            onClick={() => {
              zoomOut({ duration: 100 });
              mixPanelHandler.zoomOut();
            }}
          />
          <HoverAbleSVG
            icon={ZoomToSelection}
            onClick={() => {
              fitView();
              mixPanelHandler.default();
            }}
          />
        </Controls>
        <Controls
          showInteractive={false}
          showFitView={false}
          showZoom={false}
          css={css`
            display: flex;
            align-items: center;
            flex-direction: row;
            left: auto;
            right: 80px;
            bottom: 22px;
            box-shadow: 0px 1px 3px 0px #00164e26;
            border-radius: var(--s1);
            background-color: var(--neutral-0);

            .ant-btn.button-is-icon {
              padding: var(--s2);
              border-radius: 0px !important;
              border: 1px solid var(--neutral-20);

              &:first-child {
                border-radius: var(--s1) var(--s1) 0px 0px !important;
                border-bottom: 0;
              }
              &:last-child {
                border-radius: 0px 0px var(--s1) var(--s1) !important;
                border-top: 0;
              }
            }
          `}
        >
          <HoverAbleSVG
            icon={AccordionExpand}
            onClick={() => {
              handleExpandCollapseAllSteps("expanded");
              mixPanelHandler.expandAll();
            }}
          />
          <HoverAbleSVG
            icon={() => <AccordionCollapse size={10} color="neutral-70" />}
            onClick={() => {
              handleExpandCollapseAllSteps("collapsed");
              mixPanelHandler.collapseAll();
            }}
          />
        </Controls>
      </ReactFlow>
    </div>
  );
}

export function FlowDiagram(props: Props): ReactElement {
  return (
    <ReactFlowProvider>
      <FlowDiagramInner {...props} />
    </ReactFlowProvider>
  );
}

function useNodeDimensions() {
  const nodes = useNodes();
  // if any node width or height changes, we update dagre about dimensions
  //converting to primitive type, to re-run useMemo if value of width or height changes
  const widthList = nodes.map(item => item?.width);
  const heightList = nodes.map(item => item?.height);
  const widthListStr = JSON.stringify(widthList);
  const heightListStr = JSON.stringify(heightList);
  return {
    widthListStr,
    heightListStr,
    hasDimensions: !!(widthList[0] && heightList[0])
  };
}
